'use client';

import type { Banner } from '@royalaholddelhaize/ah-web-core';
import { ChevronLeft16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import {
    LinkButton,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import type { FC, PropsWithChildren } from 'react';
import { createTranslate } from '../i18n/translation';
import { NavBarSlot } from './components/nav-bar-slot/nav-bar-slot';
import { NavLogo } from './components/nav-logo/nav-logo';
import { headerTranslations } from './i18n/translation';
import type { HeaderSupportedLocales } from './interfaces/supported-locales';

import css from './header-focused-base.module.scss';

export type HeaderFocusedBaseProps = PropsWithChildren & {
    /**
     * Given boolean value if the NavigationBasket component needs to be shown in the header.
     */
    banner: Banner;
    locale: HeaderSupportedLocales;
};

export const HeaderFocusedBase: FC<HeaderFocusedBaseProps> = ({
    banner,
    locale,
    children,
}) => {
    const t = createTranslate(locale, headerTranslations);

    const handleOnBack = () => {
        const aholdDomain = /(ah|ahold)\.[a-z]{2}/g;
        const referer = document.referrer.toString();
        if (referer.match(aholdDomain)) {
            window.history.back();
        } else {
            window.location.href = '/';
        }
    };

    return (
        <nav className={css.container}>
            <NavBarSlot>
                <LinkButton onClick={handleOnBack} className={css.button}>
                    <ChevronLeft16Icon size={16} />
                    <LinkText level="secondary">
                        {t('navigation-header-focused.back')}
                    </LinkText>
                </LinkButton>
            </NavBarSlot>
            <NavBarSlot align="Center">
                <NavLogo banner={banner} locale={locale} />
            </NavBarSlot>
            {children}
        </nav>
    );
};
