import { AllerhandeRoute } from '@enums/route';
import type { NavigationLink } from '@interfaces/navigation';

export const links: NavigationLink[] = [
    {
        href: AllerhandeRoute.Home,
        label: 'navigation:link.recipes',
        paths: [
            AllerhandeRoute.Home,
            AllerhandeRoute.RecipeSearch,
            AllerhandeRoute.RecipeDetails,
        ],
    },
    {
        href: AllerhandeRoute.VideoSearch,
        label: 'navigation:link.videos',
        paths: [AllerhandeRoute.VideoSearch, AllerhandeRoute.VideoDetails],
    },
    {
        href: AllerhandeRoute.RecipeThemeOverview,
        label: 'navigation:link.themes',
        paths: [AllerhandeRoute.RecipeThemeOverview],
    },
    {
        href: AllerhandeRoute.Magazines,
        label: 'navigation:link.magazines',
        paths: [AllerhandeRoute.Magazines, AllerhandeRoute.Magazine],
    },
    {
        href: AllerhandeRoute.MyRecipes,
        label: 'navigation:link.favorites',
        paths: [
            AllerhandeRoute.MyRecipes,
            AllerhandeRoute.MyRecipesDetail,
            AllerhandeRoute.MyShoppedRecipes,
        ],
    },
    {
        href: AllerhandeRoute.AllerhandeKids,
        label: 'navigation:link.kids',
        paths: [AllerhandeRoute.AllerhandeKids],
    },
];
