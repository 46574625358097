import { createMenuAnchor } from '../../helper/menu-anchor.helper';
import type { TranslationFunction } from '../../i18n/translation';
import type {
    ProfileTranslationFile,
    ProfileTranslationKey,
} from '../i18n/translation';
import type { MenuItem } from '../interfaces/profile-menu';

const findSubItemUrlMatch = (
    t: TranslationFunction<ProfileTranslationFile>,
    menu: MenuItem[],
    targetURL: string,
): boolean => {
    let isFound = false;
    menu.forEach(item => {
        if (
            (item.url && targetURL?.includes(item.url)) ||
            createMenuAnchor(
                t(`menu.${item.title}` as ProfileTranslationKey),
            ).includes(targetURL)
        ) {
            isFound = true;
        }
        if (item.items && item.items.length > 0) {
            if (findSubItemUrlMatch(t, item.items || [], targetURL)) {
                isFound = true;
            }
        }
    });
    return isFound;
};
export const findActiveMenuItem = (
    t: TranslationFunction<ProfileTranslationFile>,
    menu: MenuItem[],
    targetURL: string,
): MenuItem | null => {
    let selectedMenuItem = null;
    menu.forEach(menuItem => {
        if (
            (menuItem.url && targetURL?.includes(menuItem.url)) ||
            createMenuAnchor(
                t(`menu.${menuItem.title}` as ProfileTranslationKey),
            ).includes(targetURL) ||
            findSubItemUrlMatch(t, menuItem.items || [], targetURL)
        ) {
            selectedMenuItem = menuItem;
        }
    });

    return selectedMenuItem;
};
