'use client';

import { type FC, type PropsWithChildren, useContext } from 'react';
import { FeatureFlagsContext } from './context';

interface FeatureFlaComponentProps extends PropsWithChildren {
    flag: string;
    variant?: string;
}

export const FeatureFlagComponent: FC<FeatureFlaComponentProps> = ({
    flag,
    variant,
    children,
}) => {
    const { featureFlags } = useContext(FeatureFlagsContext);
    const featureFlag = featureFlags.find(flags => flags.name === flag);

    return featureFlag?.enabled &&
        (variant
            ? featureFlag.variant.name === variant &&
              featureFlag.variant.enabled
            : true)
        ? children
        : null;
};
