import {
    type ApolloClient,
    type ApolloError,
    type DocumentNode,
    type MaybeMasked,
    type OperationVariables,
    useQuery,
} from '@apollo/client';
import type { ReactNode } from 'react';

interface ApolloProps<Q, V extends OperationVariables> {
    apollo: {
        initialised: boolean;
        ssrClient?: ApolloClient<unknown>;
        query: DocumentNode;
        variables?: V;
        skip?: boolean;
        headers?: Record<string, string>;
    };
    children: (result: {
        data?: Q | MaybeMasked<Q>;
        error?: ApolloError;
        loading: boolean;
    }) => ReactNode;
}

export async function ServerSideApollo<Q, V extends OperationVariables>({
    apollo: { ssrClient, query, variables, headers },
    children,
}: ApolloProps<Q, V>) {
    if (!ssrClient) return children({ loading: false });

    const { data, error, loading } = await ssrClient.query<Q, V>({
        query,
        variables,
        context: {
            headers,
        },
    });

    return children({ data, error, loading });
}

export function ClientSideApollo<Q, V extends OperationVariables>({
    apollo: { initialised, query, variables, skip, headers },
    children,
}: ApolloProps<Q, V>) {
    const { data, error, loading } = useQuery(query, {
        variables,
        ssr: false,
        skip: !initialised || skip,
        context: {
            headers,
        },
    });

    return children({ data, error, loading });
}
