'use client';

import {
    emitPageView,
    initDataLayer,
    startEventTracking,
} from '@royalaholddelhaize/ah-analytics';
import { usePathname, useSearchParams } from 'next/navigation';
import { type FC, useEffect, useRef } from 'react';

export const Analytics: FC = () => {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const urlRef = useRef<string | null>(null);

    const url = `${pathname}?${searchParams.toString()}`;

    useEffect(() => {
        initDataLayer({
            debug: false,
        });
        startEventTracking();
    }, []);

    useEffect(() => {
        if (urlRef.current === url) {
            return;
        }

        emitPageView();
        urlRef.current = url;
    }, [url]);

    return null;
};
