import {
    SESSION_STORAGE_KEY,
    SearchAction,
    initializeSearchEvent,
} from '@royalaholddelhaize/ah-analytics';
import {
    AH_UI_NAVIGATION_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import {
    ChevronLeft16Icon,
    ClearInput24Icon,
    Search24Icon,
} from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import { LinkButton } from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import classNames from 'clsx';
import type React from 'react';
import { useEffect, useRef } from 'react';
import { useSearch } from '../../../graphql/search/search.hook';
import { createTranslate } from '../../../i18n/translation';
import { headerTranslations } from '../../i18n/translation';
import type { HeaderApolloOptions } from '../../interfaces/apollo-options';
import type { HeaderFeatureOptions } from '../../interfaces/feature-options';
import type { HeaderSupportedLocales } from '../../interfaces/supported-locales';
import { KeydownHandler } from './elements/keydown-handler/keydown-handler';
import type { RenderedSuggestion } from './elements/suggestions/elements/suggestion/suggestion.interface';
import { Suggestions } from './elements/suggestions/suggestions';
import { emitSearchBarClickEvent } from './helpers/emit-search-bar-click-event';
import { getSearchAction } from './helpers/get-search-action';
import css from './search.module.scss';

export interface SearchProps {
    /**
     * Given function to close component
     */
    onClose?: () => void;
    apollo: HeaderApolloOptions;
    featureOptions: HeaderFeatureOptions;
    locale: HeaderSupportedLocales;
}

export const Search = ({
    apollo,
    featureOptions,
    locale,
    onClose,
}: SearchProps) => {
    const t = createTranslate(locale, headerTranslations);
    const placeholder = t('search.placeholder');
    const formRef = useRef<HTMLFormElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const {
        query,
        suggestions,
        selectedSuggestion,
        isOpen,
        handleSubmit,
        handleClear: handleClearHooked,
        handleChange: handleChangeHooked,
        handleFocus,
        handleExitSearch,
        handleSelectionWithArrowsKeys,
    } = useSearch(apollo, featureOptions, formRef, searchInputRef, locale);

    const inBrowser = typeof window !== 'undefined';

    // Hook into `handleClear()` to clean up the analytics data in session storage
    const handleClear = () => {
        handleClearHooked();
        inBrowser && window.sessionStorage.removeItem(SESSION_STORAGE_KEY);
    };

    // Hook into `handleChange()` to clean up the analytics data in session storage
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeHooked(e);
        if (inBrowser && e?.target?.value?.length < 1) {
            window.sessionStorage.removeItem(SESSION_STORAGE_KEY);
        }
    };

    const isQuery = query?.length > 0;

    const shouldInitiateSearch = isQuery && isOpen;

    /** Handles a click on an auto-suggested item for analytics purposes */
    const analyticsSearchHandler = (suggestion: RenderedSuggestion): void => {
        const searchAction = getSearchAction(suggestion);
        const isRegularSearch = searchAction === SearchAction.SEARCH;
        const localQuery = isRegularSearch ? query : suggestion.renderedLabel;

        initializeSearchEvent({
            query: localQuery,
            suggestions,
            selectedSuggestion: suggestion.renderedLabel,
            searchAction,
        });
    };

    // another case of analytics initialization
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        shouldInitiateSearch &&
            initializeSearchEvent({
                query,
                suggestions,
                selectedSuggestion: selectedSuggestion,
                searchAction: SearchAction.SEARCH,
            });
    }, [query, suggestions, selectedSuggestion]);

    // Emit search bar click event when the search bar is opened
    useEffect(() => {
        if (isOpen) {
            emitSearchBarClickEvent(placeholder);
        }
    }, [isOpen, placeholder]);

    return (
        <>
            <form
                className={css.root}
                ref={formRef}
                onSubmit={handleSubmit}
                method="post"
            >
                <div
                    className={css.compact}
                    {...createTestHook(
                        AH_UI_NAVIGATION_TESTHOOKS.HEADER.search,
                    )}
                >
                    <KeydownHandler
                        handler={handleExitSearch}
                        keyCodes={['esc', 'tab']}
                    >
                        <input
                            id="navigation-search-input"
                            ref={searchInputRef}
                            type="search"
                            autoComplete="off"
                            className={classNames(
                                css.input,
                                isOpen && css.open,
                            )}
                            onFocus={handleFocus}
                            onChange={handleChange}
                            value={selectedSuggestion ?? query}
                            placeholder={placeholder}
                            aria-activedescendant={
                                isOpen ? 'aria-activedescendant' : undefined
                            }
                            aria-owns={isOpen ? 'aria-owns' : undefined}
                            aria-controls={isOpen ? 'aria-owns' : undefined}
                            aria-label={placeholder}
                        />
                    </KeydownHandler>
                    <LinkButton
                        onClick={onClose}
                        className={css.closeButton}
                        aria-label={t('search.close')}
                    >
                        <ChevronLeft16Icon size={16} />
                    </LinkButton>
                    <LinkButton
                        type="submit"
                        className={css.iconButton}
                        aria-label={t('search-bar.label')}
                    >
                        <Search24Icon size={18} />
                    </LinkButton>
                    {query.length ? (
                        <LinkButton
                            className={css.clearButton}
                            aria-label={t('search.clearButton')}
                            onClick={handleClear}
                        >
                            <ClearInput24Icon size={24} />
                        </LinkButton>
                    ) : undefined}
                    <LinkButton
                        type="submit"
                        className={css.submitButton}
                        aria-label={t('search.submitButton')}
                    >
                        <Search24Icon size={24} />
                    </LinkButton>
                </div>
                <Suggestions
                    locale={locale}
                    isOpen={isOpen}
                    suggestions={suggestions}
                    query={query}
                    onSelectionWithArrowKeys={handleSelectionWithArrowsKeys}
                    analyticsSearchHandler={analyticsSearchHandler}
                />
            </form>
            <div className={classNames(css.backdrop, isOpen && css.open)} />
        </>
    );
};
