import { emitClickComponent } from '@royalaholddelhaize/ah-analytics';
import { PATH } from '../../../constants/path';

export const emitSearchBarClickEvent = (placeholder: string) => {
    const isCustomerCarePage =
        typeof window !== 'undefined' &&
        window.location.pathname.startsWith(PATH.customerService);

    if (isCustomerCarePage) {
        emitClickComponent({
            componentType: 'Search field',
            componentSubType: 'Input field',
            componentInnerText: placeholder,
            componentSection: 'Self Service - CS Global Search Bar',
        });
    }
};
