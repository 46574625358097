import { Accessibility24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import { BasketOutlined24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/basket-outlined-24';
import { BonusOutlined24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/bonus-outlined-24';
import { Filter24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/filter-24';
import { Invoice24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/invoice-24';
import { Key24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/key-24';
import { List24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/list-24';
import { LoyaltyCard24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/loyalty-card-24';
import { Notification24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/notification-24';
import { OrderOutlined24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/order-outlined-24';
import { PreviouslyBought24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/previously-bought-24';
import { ProductsOutlined24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/products-outlined-24';
import { Profile24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/profile-24';
import { Settlement24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/settlement-24';
import { Store24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/store-24';
import type { ProfileMenuType } from '../../interfaces/profile-menu';

export const MenuBEL: ProfileMenuType = {
    menu: [
        {
            title: 'details-&-preferences',
            icon: Profile24Icon,
            items: [
                {
                    title: 'my-info.title',
                    subTitle: 'my-info.subTitle',
                    icon: Profile24Icon,
                    url: '/mijn/persoonlijke-gegevens',
                },
                {
                    title: 'credentials.title',
                    subTitle: 'credentials.subTitle',
                    icon: Key24Icon,
                    url: '/account/inloggegevens',
                },
                {
                    title: 'customer-cards.title',
                    subTitle: 'customer-cards.subTitle',
                    icon: LoyaltyCard24Icon,
                    url: '/klantenkaarten',
                },
                {
                    title: 'my-ah.title',
                    subTitle: 'my-ah.subTitle',
                    icon: BonusOutlined24Icon,
                    url: '/mijn/geactiveerde-bonuskaart',
                },
                {
                    title: 'preferences.title',
                    subTitle: 'preferences.subTitle',
                    icon: Filter24Icon,
                    items: [
                        {
                            title: 'eating-preferences.title',
                            subTitle: 'eating-preferences.subTitle',
                            icon: ProductsOutlined24Icon,
                            url: '/allerhande/voorkeuren',
                        },
                        {
                            title: 'my-store.title',
                            subTitle: 'my-store.subTitle',
                            icon: Store24Icon,
                            url: '/winkel/favoriet',
                        },
                        {
                            title: 'notifications.title',
                            subTitle: 'notifications.subTitle',
                            icon: Notification24Icon,
                            url: '/mijn/berichten',
                        },
                    ],
                },
                {
                    title: 'accessibility.title',
                    subTitle: 'accessibility.subTitle',
                    icon: Accessibility24Icon,
                    url: '/mijn/toegankelijkheid',
                    featureFlag: 'ah-ui-theme',
                },
            ],
        },
        {
            title: 'purchases-&-orders',
            icon: BasketOutlined24Icon,
            items: [
                {
                    title: 'invoices.title',
                    subTitle: 'invoices.subTitle',
                    icon: Invoice24Icon,
                    url: '/mijn/facturen',
                },
                {
                    title: 'online-ordering.title',
                    subTitle: 'online-ordering.subTitle',
                    icon: List24Icon,
                    url: '/mijn/bestellen',
                },
                {
                    title: 'store-purchases.title',
                    subTitle: 'store-purchases.subTitle',
                    icon: PreviouslyBought24Icon,
                    url: '/mijn/winkelaankopen',
                },
            ],
        },
        {
            title: 'settlements',
            icon: Settlement24Icon,
            url: '/mijntegoed/transacties',
        },
        {
            title: 'delivery-bundle',
            icon: OrderOutlined24Icon,
            url: '/bezorgbundel/overzicht',
            domain: ['B2C'],
        },
    ],
    breadcrumbs: [
        {
            url: '/account/inloggegevens/wachtwoord',
            title: 'breadcrumbs.change-password',
            parent: '/account/inloggegevens',
        },
        {
            url: '/account/inloggegevens/email',
            title: 'breadcrumbs.change-email',
            parent: '/account/inloggegevens',
        },
        {
            url: '/account/inloggegevens/passkey',
            title: 'breadcrumbs.set-passkeys',
            parent: '/account/inloggegevens',
        },
        {
            url: '/account/inloggegevens/controlecode',
            title: 'breadcrumbs.account-protection',
            parent: '/account/inloggegevens',
        },
    ],
};
