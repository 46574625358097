import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import type { FC, PropsWithChildren, RefObject } from 'react';

import css from './navigation.module.scss';

export const Navigation: FC<
    PropsWithChildren<{ ref: RefObject<HTMLElement | null> }>
> = ({ children, ref }) => {
    return (
        <nav
            className={css.root}
            aria-label="Allerhande navigatie"
            data-brand="allerhande"
            ref={ref}
        >
            <Grid className={css.grid}>
                <GridItem xs={4} lg={10} xl={10} lgOffset={1} xlOffset={1}>
                    <div className={css.wrapper}>{children}</div>
                </GridItem>
            </Grid>
        </nav>
    );
};
