'use client';

import type { FC, PropsWithChildren } from 'react';
import { useMobileWebView } from '../helper/use-mobile-webview';

export const MobileContainer: FC<PropsWithChildren> = ({ children }) => {
    const isMobile = useMobileWebView();

    if (isMobile) {
        return null;
    }

    return children;
};
