'use client';

import type { NormalizedCacheObject } from '@apollo/client';
import { disableFragmentWarnings } from '@apollo/client';
import {
    type ApolloClient,
    ApolloNextAppProvider,
} from '@apollo/experimental-nextjs-app-support';
import {
    type Headers,
    createClient,
} from '@royalaholddelhaize/ah-next-graphql-client';
import type { FC, PropsWithChildren } from 'react';
import { apolloConfig } from './config';

disableFragmentWarnings();

const getGraphqlUrl = (origin: string) => {
    if (origin.includes('ah-tst')) {
        return 'https://tst.ah.nl/gql';
    }

    if (origin.includes('ah-acc')) {
        return 'https://acc.ah.nl/gql';
    }

    if (origin.includes('ah-prd')) {
        return 'https://www.ah.nl/gql';
    }

    return `${origin}/gql`;
};

const apolloClient = (headers: Record<string, string>) => () =>
    createClient({
        ...apolloConfig,
        uri:
            typeof window !== 'undefined'
                ? getGraphqlUrl(window.location.origin)
                : process.env.GQL_SSR_URL || '/gql',
        clientComponent: true,
        headers: headers as Headers,
    }) as ApolloClient<NormalizedCacheObject>;

type ApolloWrapperProps = {
    headers: Record<string, string>;
};

export const ApolloWrapper: FC<PropsWithChildren<ApolloWrapperProps>> = ({
    children,
    headers,
}) => (
    <ApolloNextAppProvider makeClient={apolloClient(headers)}>
        {children}
    </ApolloNextAppProvider>
);
