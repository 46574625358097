import {
    AllerhandeLogo,
    type AllerhandeLogoProps,
} from '@components/elements/allerhande-logo/allerhande-logo';
import type { FC } from 'react';

import css from './navigation-logo.module.scss';

export const NavigationLogo: FC<AllerhandeLogoProps> = ({ ...restProps }) => {
    return <AllerhandeLogo {...restProps} className={css.root} />;
};
