'use client';

import { type Banner, bannerMapper } from '@royalaholddelhaize/ah-web-core';
import {
    SocialFacebook24Icon,
    SocialInstagram24Icon,
    SocialPinterest24Icon,
    SocialX24Icon,
    SocialYoutube24Icon,
} from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import type { SvgIconProps } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/svg-icon.interfaces';
import { LinkAnchor } from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import type { FC } from 'react';
import type { ContentSocialItem } from '../../content/content.types';
import { SocialIconName } from '../../enums/social-icon-name';
import styles from './menu.module.scss';

const SocialIconMap: Record<SocialIconName, React.FC<SvgIconProps>> = {
    [SocialIconName.FACEBOOK]: SocialFacebook24Icon,
    [SocialIconName.INSTAGRAM]: SocialInstagram24Icon,
    [SocialIconName.PINTEREST]: SocialPinterest24Icon,
    [SocialIconName.TWITTER]: SocialX24Icon,
    [SocialIconName.YOUTUBE]: SocialYoutube24Icon,
};

interface MenuSocialsProps {
    banner: Banner;
    content: ContentSocialItem[];
}

export const MenuSocials: FC<MenuSocialsProps> = ({ banner, content }) => (
    <menu>
        {content.map(({ href, label }) => {
            const Icon = SocialIconMap[label];
            return (
                <li key={href}>
                    <LinkAnchor
                        className={styles.link}
                        aria-label={label}
                        href={href}
                    >
                        <Icon size={24} />
                    </LinkAnchor>
                </li>
            );
        })}
        <li className={styles.ahLogo}>
            <img
                src="https://static.ah.nl/ah-static/images/ah-ui-bridge-components/logo/logo-ah.svg"
                alt={`${bannerMapper[banner]} logo`}
                height="24"
                width="24"
            />
        </li>
    </menu>
);
