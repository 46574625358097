// src/graphql-client.ts
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { onError } from "@apollo/client/link/error";
import {
  ApolloClient as NextSSRApolloClient,
  InMemoryCache as NextSSRInMemoryCache,
  SSRMultipartLink
} from "@apollo/experimental-nextjs-app-support";

// src/cache/basket.ts
var basketTypePolicies = {
  Basket: {
    // Makes `Basket` singleton!
    keyFields: []
  },
  BasketSummary: {
    merge: true
  },
  BasketTotalPrice: {
    merge: true
  }
};

// src/cache/common.ts
var commonTypePolicies = {
  Money: {
    merge: true
  }
};

// src/cache/favorite-list.ts
var favoriteListTypePolicies = {
  FavoriteList: {
    keyFields: ["referenceId", "id"]
  }
};

// src/cache/member.ts
var memberTypePolicies = {
  MemberAnalytics: {
    merge: true
  },
  MemberAddress: {
    merge: true
  },
  MemberName: {
    merge: true
  },
  MemberCards: {
    merge: true
  },
  Member: {
    // This makes Member a singleton, i.e.
    // this merges ALL members into the same object.
    // If the schema is ever to return data for another Member
    // (with a different ID), this should be removed!
    keyFields: [],
    merge: true
  }
};

// src/cache/index.ts
var typePolicies = {
  ...commonTypePolicies,
  ...basketTypePolicies,
  ...memberTypePolicies,
  ...favoriteListTypePolicies,
  CiamPasskeySettings: {
    // Makes `CiamPasskeySettings` type a singleton!
    keyFields: []
  }
};

// src/graphql-client.ts
function createClient(options) {
  const isClientSide = typeof window !== "undefined";
  const headers = Object.keys(options.headers).reduce(
    (acc, headerKey) => {
      const value = options.headers[headerKey];
      if (!["content-length", "content-type", "accept"].includes(
        headerKey
      )) {
        acc[headerKey] = Array.isArray(value) ? value.join("; ") : value;
      }
      return acc;
    },
    {}
  );
  const batchHttpLink = new BatchHttpLink({
    uri: options.uri,
    credentials: "include",
    headers: {
      batch: "true",
      ...headers
    }
  });
  const httpLink = new HttpLink({
    uri: options.uri,
    credentials: "include",
    headers
  });
  let cache;
  if (options.clientComponent) {
    cache = new NextSSRInMemoryCache({
      possibleTypes: options.possibleTypes,
      typePolicies,
      ...options.cacheOptions
    });
  } else {
    cache = new InMemoryCache({
      possibleTypes: options.possibleTypes,
      typePolicies,
      ...options.cacheOptions
    });
    if (isClientSide && !options.clientComponent) {
      cache = cache.restore(
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        window[options.windowStateKey ?? "__APOLLO_STATE__"]
      );
    }
  }
  const shouldLogErrors = () => {
    if (typeof options.logErrors === "boolean") {
      return options.logErrors;
    }
    if (options.logErrors === "server" && !isClientSide) {
      return true;
    }
    return false;
  };
  const clientOptions = {
    cache,
    resolvers: {},
    link: ApolloLink.from([
      ...options.clientComponent && !isClientSide ? [
        new SSRMultipartLink({
          stripDefer: true
        })
      ] : [],
      onError(
        ({
          graphQLErrors,
          protocolErrors,
          networkError,
          response,
          operation,
          forward
        }) => {
          if (shouldLogErrors()) {
            if (graphQLErrors) {
              graphQLErrors.forEach(
                ({ message, locations, path, extensions }) => console?.warn(
                  `[GraphQL error]: Message: ${message}, Operation: ${operation.operationName}, Location: ${JSON.stringify(
                    locations
                  )}, Path: ${path}, Code: ${extensions && extensions.code}, Extensions: ${JSON.stringify(extensions)}`
                )
              );
            }
            if (protocolErrors) {
              protocolErrors.forEach(
                ({ message, locations, path, extensions }) => {
                  console?.warn(
                    `[Protocol error]: Message: ${message}, Operation: ${operation.operationName}, Location: ${JSON.stringify(
                      locations
                    )}, Path: ${path}, Extensions: ${JSON.stringify(extensions)}`
                  );
                }
              );
            }
            if (networkError) {
              console?.warn(
                `[Network error]: ${networkError}, Operation: ${operation.operationName}`
              );
            }
          }
          if (options.onErrorCallback) {
            options.onErrorCallback({
              cache,
              graphQLErrors,
              protocolErrors,
              networkError,
              response,
              operation,
              forward
            });
          }
        }
      ),
      split(
        (operation) => operation.getContext().important === true || Boolean(options.disableBatch),
        httpLink,
        // if the test is true -- debatch
        batchHttpLink
        // otherwise, batching is fine
      )
    ]),
    queryDeduplication: true,
    defaultOptions: {
      query: {
        errorPolicy: "all"
      },
      mutate: {
        errorPolicy: "all"
      },
      watchQuery: {
        errorPolicy: "all"
      }
    },
    ...options.apolloOptions
  };
  return options.clientComponent ? new NextSSRApolloClient({
    ...clientOptions,
    cache: clientOptions.cache
  }) : new ApolloClient(clientOptions);
}

// src/headers.ts
var normalizeHeadersKeys = (headers) => {
  const newHeaders = {};
  headers.forEach((value, key) => {
    const lowerCaseKey = key.toLowerCase();
    if (!["content-length", "content-type", "accept"].includes(lowerCaseKey)) {
      newHeaders[lowerCaseKey] = value;
    }
  });
  return newHeaders;
};
function generateRandomString(length) {
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    result += charset[randomIndex];
  }
  return result;
}
var getDefaultHttpHeaders = (headers) => {
  const normalizedHeaders = normalizeHeadersKeys(headers);
  return Object.assign(normalizedHeaders, {
    "x-correlation-id": normalizedHeaders["x-correlation-id"] ?? `ssr-gql-${generateRandomString(16)}`
  });
};
export {
  createClient,
  getDefaultHttpHeaders
};
