import {
    MemberLoginState,
    type MemberQuery,
} from '../_generated-operation-types';

export enum OptInIds {
    DataUsage = 'OFFSMUSE',
    DataSharing = 'OFFSMSHARE',
}

export interface UserConsent {
    code: string;
    granted: boolean;
}

export interface UserConsentItems {
    items: UserConsent[];
}

export interface Member {
    id: number | null;
    isMember: boolean;
    isLoggedIn: boolean;
    isPremium: boolean;
    displayName: string;
    isB2B: boolean;
    // The Analytics metadata object is for the legacy datalayer
    // should become obsolete when we only use the CID datalayer
    analyticsMeta: {
        idsas?: string;
    };
    consents: UserConsentItems | null;
    consentsToShow: string[] | null;
    memberLoginState: MemberLoginState;
    memberIsOnboarded: boolean;
}

export const normalizeMember = (data?: MemberQuery): Member => {
    if (!data) {
        return {
            id: null,
            isPremium: false,
            isMember: false,
            isLoggedIn: false,
            displayName: '',
            isB2B: false,
            analyticsMeta: {},
            consents: null,
            consentsToShow: null,
            memberLoginState: MemberLoginState.UNKNOWN,
            memberIsOnboarded: false,
        };
    }

    const { member, memberLoginState, memberIsOnboarded } = data;
    const isMember =
        memberLoginState !== MemberLoginState.UNKNOWN && !!member?.id;
    const isB2B = !!member?.isB2B;
    const isPremium = !!member?.memberships?.includes('PREMIUM');

    return {
        id: member?.id ?? null,
        isMember,
        isPremium,
        analyticsMeta: {
            idsas: member?.analytics.idsas || undefined,
        },
        isLoggedIn:
            memberLoginState !== MemberLoginState.UNKNOWN && memberIsOnboarded,
        displayName: member?.name?.first || member?.emailAddress || '',
        isB2B,
        consents: member?.consents
            ? { items: member?.consents.items || [] }
            : null,
        consentsToShow: member?.consentsToShow || null,
        memberLoginState,
        memberIsOnboarded,
    };
};
