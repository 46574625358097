import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { ChevronLeft16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-left-16';
import { LinkButton } from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import type React from 'react';
import css from './dialog-back-button.module.scss';

export interface DialogBackButtonProps {
    /**
     * Back Function for the dialog
     */
    onBack?: () => void;
}

export const DialogBackButton: React.FC<DialogBackButtonProps> = ({
    onBack,
}) => {
    return (
        <LinkButton
            onClick={onBack}
            className={css.root}
            {...createTestHook('dialog-content-back')}
        >
            <ChevronLeft16Icon size={16} />
        </LinkButton>
    );
};
