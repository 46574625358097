'use client';

// Adds a script for a popup when you have a feature enabled on test / development
// when no feature enabled the feature popup won't show anything.
import { useEffect } from 'react';

export const useScriptLoader = (enabled: boolean, src: string) => {
    useEffect(() => {
        if (!enabled) return;

        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [src, enabled]);
};
