import { emitClickComponent } from '@royalaholddelhaize/ah-analytics';
import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import type { MegaMenuLinkGroup } from '../../../../../../../graphql/content/mega-menu/mega-menu-links.hook';
import styles from './mega-menu-allerhande-content.module.scss';

interface MegaMenuAllerhandeContentProps {
    content: MegaMenuLinkGroup;
}

export const MegaMenuAllerhandeContent: FC<MegaMenuAllerhandeContentProps> = ({
    content,
}) => (
    <div className={styles.wrapper}>
        <div className={styles.linksWrapper}>
            {content?.linkGroups.map(({ links, subject }) => (
                <div key={subject} className={styles.linksGroup}>
                    <Typography
                        variant="body-strong"
                        className={styles.linksGroupHeader}
                    >
                        {subject}
                    </Typography>
                    {links?.map(({ link, title }) => (
                        <LinkAnchor
                            key={title}
                            title={title}
                            className={styles.link}
                            href={link}
                            onClick={() => {
                                emitClickComponent({
                                    componentType: 'Navigation header',
                                    componentSubType: 'link',
                                    componentInnerText: title,
                                    componentTitle: 'Allerhande Mega-menu',
                                    componentTargetUrl: link,
                                    componentSection: 'Allerhande',
                                    componentAdditional: `Recepten - ${subject}`,
                                });
                            }}
                        >
                            <LinkText level="secondary">{title}</LinkText>
                        </LinkAnchor>
                    ))}
                </div>
            ))}
        </div>
    </div>
);
