'use client';

import {
    AH_UI_NAVIGATION_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import type { FC, KeyboardEvent, PropsWithChildren } from 'react';
import styles from './nav-skip.module.scss';

export interface NavSkipProps {
    /**
     * Given link to be passed down to the anchor element
     */
    href: string;
}

export const NavSkip: FC<PropsWithChildren<NavSkipProps>> = ({
    children,
    href,
}) => {
    const handleOnKeyDown = (event: KeyboardEvent) => {
        if (event.code === 'Space') {
            window.location.href = href;
        }
    };

    return (
        <LinkAnchor
            href={href}
            className={styles.navSkip}
            onKeyDown={event => handleOnKeyDown(event)}
            {...createTestHook(AH_UI_NAVIGATION_TESTHOOKS.HEADER.navSkip)}
        >
            <LinkText>{children}</LinkText>
        </LinkAnchor>
    );
};
