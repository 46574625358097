import classNames from 'clsx';
import type React from 'react';
import {
    type HtmlHTMLAttributes,
    useCallback,
    useEffect,
    useState,
} from 'react';
import type { HeaderFeatureOptions } from '../../../../interfaces/feature-options';
import type {
    StackPaneCreateOptions,
    StackPaneOptions,
} from './interfaces/stack';
import css from './stack.module.scss';

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export interface StackProps<P = any>
    extends HtmlHTMLAttributes<HTMLDivElement> {
    featureOptions: HeaderFeatureOptions;
    initialPane: StackPaneCreateOptions<P>;
    onCloseStack?: (event: React.MouseEvent) => void;
    onOpenPane?: (
        event: React.MouseEvent,
        panes: StackPaneOptions[],
        pane: StackPaneOptions<P>,
    ) => void;
    onClosePane?: (
        event: React.MouseEvent,
        panes: StackPaneOptions[],
        pane?: StackPaneOptions<P>,
    ) => void;
    className?: string;
}

enum TransitionDirection {
    NEXT = 0,
    PREV = 1,
    NONE = 2,
}

export const Stack: React.FC<StackProps> = ({
    featureOptions,
    initialPane,
    onCloseStack,
    onOpenPane,
    onClosePane,
    className,
    ...restProps
}) => {
    const [direction, setDirection] = useState(TransitionDirection.NONE);
    const [panes, setPanes] = useState<StackPaneOptions[]>([]);

    function createPane<T>(paneOptions: StackPaneCreateOptions<T>) {
        return {
            ...paneOptions,
            props: {
                ...paneOptions.props,
                closeStack: (event: React.MouseEvent) => {
                    setPanes(state => {
                        return [state[0]];
                    });
                    if (onCloseStack) {
                        onCloseStack(event);
                    }
                },
                openPane: (
                    event: React.MouseEvent,
                    paneOptions: StackPaneCreateOptions<T>,
                ) => {
                    setPanes(state => {
                        const pane = createPane(paneOptions);
                        const newState = [...state, pane];
                        if (onOpenPane) {
                            onOpenPane(event, newState, pane);
                        }
                        return newState;
                    });
                    setDirection(TransitionDirection.NEXT);
                },
                closePane: (event: React.MouseEvent) => {
                    setPanes(state => {
                        const last = state[state.length - 1];
                        const newState = [...state.slice(0, -1)];
                        if (onClosePane) {
                            onClosePane(event, newState, last);
                        }
                        return newState;
                    });
                    setDirection(TransitionDirection.PREV);
                },
            },
        };
    }

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const onCreatePane = useCallback(createPane, [
        createPane,
        onClosePane,
        onCloseStack,
        onOpenPane,
    ]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setPanes(state => [onCreatePane(initialPane), ...state.slice(1)]);
    }, [initialPane]);

    if (panes.length === 0) {
        return null;
    }

    const transitionClasses =
        direction === TransitionDirection.NEXT ? css.next : css.prev;
    const activeIndex = panes.length - 1;
    const {
        component: Pane,
        wrapperClassName,
        props: paneProps,
    } = panes[activeIndex];

    return (
        <div
            className={classNames(css.pane, className, wrapperClassName, {
                [transitionClasses]: direction !== TransitionDirection.NONE,
            })}
            key={activeIndex}
            {...restProps}
        >
            <Pane featureOptions={featureOptions} {...paneProps} />
        </div>
    );
};
