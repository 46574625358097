import { Locale } from '@royalaholddelhaize/ah-web-core';
import type { NestedKeyOf } from '../../i18n/translation';
import type { ProfileSupportedLocales } from '../interfaces/supported-locales';
import enUS from './en-US/translation.json';
import nlBE from './nl-BE/translation.json';
import nlNL from './nl-NL/translation.json';

export type ProfileTranslationFile = typeof nlNL;
export type ProfileTranslationKey = NestedKeyOf<ProfileTranslationFile>;

export const profileTranslations: Record<
    ProfileSupportedLocales,
    ProfileTranslationFile
> = {
    [Locale.nl_NL]: nlNL,
    [Locale.nl_BE]: nlBE,
    [Locale.en_US]: enUS,
};
