export const LoggedOut24Icon = ({
    size = 24,
    className,
}: {
    size?: number | string;
    className?: string;
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={size}
        width={size}
        className={className}
        fill="currentColor"
        viewBox="0 0 30 29"
    >
        <title>Logged out User</title>
        <path
            fill="#303030"
            fillRule="evenodd"
            d="M9 6c0-1.65685 1.3431-3 3-3s3 1.34315 3 3-1.3431 3-3 3-3-1.34315-3-3Zm3-5C9.23858 1 7 3.23858 7 6s2.23858 5 5 5c2.7614 0 5-2.23858 5-5s-2.2386-5-5-5ZM4 20c0-1.8173.94196-3.369 2.45-4.5 1.51795-1.1385 3.5549-1.8 5.55-1.8 1.9951 0 4.032.6615 5.55 1.8C19.058 16.631 20 18.1827 20 20v1H4v-1Zm8-8.3c-2.40491 0-4.86795.7885-6.75 2.2C3.35804 15.319 2 17.4173 2 20v1c0 .5273.18011 1.0443.56789 1.4321C2.95567 22.8199 3.47275 23 4 23h16c.5272 0 1.0443-.1801 1.4321-.5679C21.8199 22.0443 22 21.5273 22 21v-1c0-2.5827-1.358-4.681-3.25-6.1-1.882-1.4115-4.3451-2.2-6.75-2.2Z"
            clipRule="evenodd"
        />
        <circle
            cx="21"
            cy="20"
            r="8"
            fill="#FF1F1F"
            stroke="#fff"
            strokeWidth="2"
        />
        <path fill="#FF1F1F" d="M16.625 15.625h8.75v8.75h-8.75z" />
        <path
            fill="#fff"
            d="M18.6523 16.8789c-.2135-.2135-.5598-.2135-.7734 0-.2135.2136-.2135.5599 0 .7734L20.2266 20l-2.3477 2.3477c-.2135.2135-.2135.5598 0 .7734.2136.2135.5599.2135.7734 0L21 20.7734l2.3477 2.3477c.2135.2135.5598.2135.7734 0 .2135-.2136.2135-.5599 0-.7734L21.7734 20l2.3477-2.3477c.2135-.2135.2135-.5598 0-.7734-.2136-.2135-.5599-.2135-.7734 0L21 19.2266l-2.3477-2.3477Z"
        />
    </svg>
);
