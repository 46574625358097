'use client';

import React, { createContext, useEffect, useState } from 'react';
import type { PropsWithChildren } from 'react';
import { useMemberQuery } from '../../graphql/_generated-hooks';
import { type Member, normalizeMember } from '../../graphql/member/member.hook';
import { useCidAnalytics } from '../hooks/use-cid-analytics.hook';

interface MemberContextState {
    member: Member;
    refetch: () => void;
    isLoading: boolean;
}

const MemberContext = createContext<MemberContextState>({
    isLoading: true,
    member: normalizeMember(undefined),
    refetch: () => {},
});

const MemberProvider: React.FC<
    PropsWithChildren<{
        initialMember: Member | null;
        initialised: boolean;
    }>
> = ({ children, initialMember, initialised }) => {
    const [member, setMember] = useState<Member | null>(initialMember);
    const { loading, refetch, error } = useMemberQuery({
        skip: true,
        ssr: false,
        onCompleted: data => {
            setMember(normalizeMember(data));
        },
    });

    useCidAnalytics(initialised, refetch, member, loading, error);

    useEffect(() => {
        if (initialMember) {
            setMember(initialMember);
        }
    }, [initialMember]);

    return (
        <MemberContext.Provider
            value={{
                member: member || normalizeMember(undefined),
                isLoading: loading,
                refetch,
            }}
        >
            {children}
        </MemberContext.Provider>
    );
};

const useMember = () => {
    const context = React.useContext(MemberContext);
    if (context === undefined) {
        throw new Error('useMember must be used within a MemberProvider');
    }
    return context;
};

export { MemberProvider, useMember };
