import { Locale } from '@royalaholddelhaize/ah-web-core';
import type { NestedKeyOf } from '../../i18n/translation';
import type { HeaderSupportedLocales } from '../interfaces/supported-locales';
import enUS from './en-US/translation.json';
import nlBE from './nl-BE/translation.json';
import nlNL from './nl-NL/translation.json';

export type HeaderTranslationFile = typeof nlNL;
export type HeaderTranslationKey = NestedKeyOf<HeaderTranslationFile>;

export const headerTranslations: Record<
    HeaderSupportedLocales,
    HeaderTranslationFile
> = {
    [Locale.nl_NL]: nlNL,
    [Locale.nl_BE]: nlBE,
    [Locale.en_US]: enUS,
};
