import type { FC, PropsWithChildren } from 'react';

import css from './navigation-menu.module.scss';

export const NavigationMenu: FC<PropsWithChildren> = ({ children }) => {
    return (
        <menu className={css.anchors} role="menu">
            {children}
        </menu>
    );
};
