import { Search24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/search-24';
import {
    RegularButton,
    RegularButtonText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import classnames from 'clsx';
import { createTranslate } from '../../../../../i18n/translation';
import { useHeaderInteraction } from '../../../../hooks/use-header-interaction';
import { ScrollDirection } from '../../../../hooks/use-scroll';
import { headerTranslations } from '../../../../i18n/translation';
import type { HeaderSupportedLocales } from '../../../../interfaces/supported-locales';
import css from './search-bar.module.scss';

export interface SearchBarProps {
    /**
     * Function passed down to the onClick of the child button component
     */
    onOpen: () => void;
    locale: HeaderSupportedLocales;
}

export const SearchBar = ({ locale, onOpen }: SearchBarProps) => {
    const { isVisible, direction } = useHeaderInteraction(65);
    const t = createTranslate(locale, headerTranslations);
    return (
        <div
            className={classnames(css.root, {
                [css.show]: isVisible || direction === ScrollDirection.INIT,
            })}
        >
            <RegularButton
                aria-label={t('search-bar.label')}
                className={css.button}
                onClick={onOpen}
            >
                <Search24Icon className={css.icon} size={24} />
                <RegularButtonText>{t('search.placeholder')}</RegularButtonText>
            </RegularButton>
        </div>
    );
};
