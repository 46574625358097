import classNames from 'clsx';
import type { FC, PropsWithChildren } from 'react';
import css from './nav-bar-slot.module.scss';

export interface NavBarSlotProps {
    /**
     * Sets alignment styling for the component
     */
    align?: 'Right' | 'Center';
    /**
     * Sets disabled styling for the component
     */
    disableShrink?: boolean;
}

export const NavBarSlot: FC<PropsWithChildren<NavBarSlotProps>> = ({
    align,
    disableShrink = false,
    children,
}) => (
    <div
        className={classNames(
            css.root,
            align && css[`align${align}`],
            disableShrink && css.disabled,
        )}
    >
        {children}
    </div>
);
