export const PATH = {
    none: '#',
    terms: '/algemene-voorwaarden',
    businessTerms: '/zakelijke-voorwaarden',
    bonusTerms: '/algemene-voorwaarden/bonus',
    privacy: '/privacy',
    cookies: '/cookiebeleid',
    about: '/over-ah',
    vulnerability: '/kwetsbaarheid-melden',
    nix18: '/nix-18',
};
