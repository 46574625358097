import { useEffect, useState } from 'react';
import { OrderMode } from '../../header/enums/order-mode';
import { useBasketQuery } from '../_generated-hooks';
import {
    type BasketFragment,
    BasketShoppingType,
} from '../_generated-operation-types';

interface Basket {
    totalPrice: number;
    deliveryDate: string | null;
    discountPrice?: number;
    numberItems: number;
    totalItems: number;
    orderMode: OrderMode | null;
    products: Array<{ id: number }> | null;
}

export interface UseBasket extends Basket {
    refetch: () => void;
}

const ShoppingTypeMap = {
    [BasketShoppingType.UNKNOWN]: OrderMode.DELIVERY,
    [BasketShoppingType.DELIVERY]: OrderMode.DELIVERY,
    [BasketShoppingType.DELIVERY_POINT]: OrderMode.DELIVERY,
    [BasketShoppingType.PHYSICAL_STORE]: OrderMode.STORE,
    [BasketShoppingType.SPECIALS_ONLY]: OrderMode.STORE,
    [BasketShoppingType.IN_STORE_PICK]: OrderMode.PICK_UP_POINT,
    [BasketShoppingType.PICKUP]: OrderMode.PICK_UP_POINT,
    [BasketShoppingType.PICKUP_DELIVERY]: OrderMode.PICK_UP_POINT,
};

export const useBasket = (initialised: boolean): UseBasket => {
    const [basket, setBasket] = useState<Basket>(normalizeBasket(undefined));
    const { refetch, data } = useBasketQuery({
        skip: !initialised,
        ssr: false,
    });

    useEffect(() => {
        if (document) {
            document.addEventListener('UPDATE_BASKET', () => {
                refetch();
            });
        }
        return () => document.removeEventListener('UPDATE_BASKET', () => {});
    }, [refetch]);

    useEffect(() => {
        setBasket(normalizeBasket(data?.basket));
    }, [data]);

    return {
        ...basket,
        refetch,
    };
};

const normalizeBasket = (data?: BasketFragment | null): Basket => {
    if (!data) {
        return {
            totalPrice: 0,
            deliveryDate: null,
            discountPrice: undefined,
            numberItems: 0,
            totalItems: 0,
            orderMode: null,
            products: null,
        };
    }

    const { summary, itemsInOrder, products, notes } = data;

    return {
        totalPrice: summary?.price?.priceAfterDiscount.amount || 0,
        deliveryDate: summary?.deliveryDate || null,
        discountPrice: summary?.price?.discount.amount,
        numberItems: summary?.quantity,
        totalItems:
            (summary?.shoppingType
                ? itemsInOrder?.length || 0
                : products?.length || 0) + (notes?.length || 0),
        orderMode: summary?.shoppingType
            ? ShoppingTypeMap[summary.shoppingType]
            : null,
        products: products.map(({ id }) => ({ id })),
    };
};
