import classNames from 'clsx';
import type React from 'react';
import type { PropsWithChildren } from 'react';
import css from './panel.module.scss';

export interface PanelProps {
    className?: string;
}

export const Panel: React.FC<PropsWithChildren<PanelProps>> = ({
    className,
    children,
}) => <div className={classNames(css.panel, className)}>{children}</div>;
