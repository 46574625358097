'use client';

import {
    AH_UI_NAVIGATION_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import type { Banner } from '@royalaholddelhaize/ah-web-core';
import { LinkAnchor } from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import classNames from 'clsx';
import { themeHelper } from '../../../helper/theme.helper';
import { createTranslate } from '../../../i18n/translation';
import { PATH } from '../../constants/path';
import { headerTranslations } from '../../i18n/translation';
import type { HeaderSupportedLocales } from '../../interfaces/supported-locales';
import styles from './nav-logo.module.scss';

export interface NavLogoProps {
    /**
     * Optional classname for styling
     */
    className?: string;
    banner: Banner;
    locale: HeaderSupportedLocales;
}

export const NavLogo = ({ className, banner, locale }: NavLogoProps) => {
    const theme = themeHelper(banner);

    const t = createTranslate(locale, headerTranslations);

    return (
        <LinkAnchor
            className={classNames(className, styles.navLogo)}
            aria-label={t('nav-logo.alt')}
            title={t('nav-logo.alt')}
            href={PATH.root}
            {...createTestHook(AH_UI_NAVIGATION_TESTHOOKS.HEADER.navLogo)}
        >
            <img
                src={`https://static.ah.nl/ah-static/images/ah-ui-bridge-components/logo/logo-${theme}.svg`}
                alt={t('nav-logo.alt')}
                className={styles.logo}
                height="24"
                width="24"
            />
        </LinkAnchor>
    );
};
