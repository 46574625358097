export const PremiumUser24Icon = ({
    size = 24,
    className,
}: {
    size?: number | string;
    className?: string;
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={size}
        width={size}
        className={className}
        fill="currentColor"
        viewBox="0 0 32 29"
    >
        <title>Premium User</title>
        <path
            fill="#303030"
            fillRule="evenodd"
            d="M9.5 6c0-1.65685 1.3431-3 3-3s3 1.34315 3 3-1.3431 3-3 3-3-1.34315-3-3Zm3-5c-2.76142 0-5 2.23858-5 5s2.23858 5 5 5c2.7614 0 5-2.23858 5-5s-2.2386-5-5-5Zm-8 19c0-1.8173.94196-3.369 2.45-4.5 1.51795-1.1385 3.5549-1.8 5.55-1.8 1.9951 0 4.032.6615 5.55 1.8 1.508 1.131 2.45 2.6827 2.45 4.5v1h-16v-1Zm8-8.3c-2.4049 0-4.86795.7885-6.75 2.2C3.85804 15.319 2.5 17.4173 2.5 20v1c0 .5273.18011 1.0443.56789 1.4321C3.45567 22.8199 3.97275 23 4.5 23h16c.5272 0 1.0443-.1801 1.4321-.5679.3878-.3878.5679-.9048.5679-1.4321v-1c0-2.5827-1.358-4.681-3.25-6.1-1.882-1.4115-4.3451-2.2-6.75-2.2Z"
            clipRule="evenodd"
        />
        <path
            fill="#CA8F2D"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M14.5 18.1767V25.75c0 .8284.6716 1.5 1.5 1.5h13c.8284 0 1.5-.6716 1.5-1.5v-7.5733c0-1.3232-1.5887-1.9982-2.5412-1.0797l-2.2661 2.1852-2.0383-2.4569c-.5998-.723-1.709-.723-2.3088 0l-2.0383 2.4569-2.2661-2.1852c-.9525-.9185-2.5412-.2435-2.5412 1.0797Z"
        />
    </svg>
);
