import type { Dispatch, SetStateAction } from 'react';
import type { useAhMemberConsentsUpdateMutation } from '../../../graphql/_generated-hooks';
import { OptInIds } from '../../../graphql/member/member.hook';

type SetOptInType = Dispatch<SetStateAction<{ [key: string]: boolean }>>;

type OptInCheckedType = {
    [key: string]: boolean;
};

export const handleSendData = (
    data: {
        code: string;
        granted: boolean;
        version: number;
    }[],
    closeModal: () => void,
    updateConsents: ReturnType<typeof useAhMemberConsentsUpdateMutation>['0'],
) => {
    closeModal();
    return updateConsents({
        variables: {
            consents: {
                add: data,
                remove: [],
            },
        },
    });
};

export const handleAcceptClick = async (
    userHasSelectedOpts: boolean,
    optInsChecked: OptInCheckedType,
    setOptInsChecked: SetOptInType,
    closeModal: () => void,
    updateConsents: ReturnType<typeof useAhMemberConsentsUpdateMutation>['0'],
) => {
    const choices = Object.keys(optInsChecked).map(optInCode => ({
        code: optInCode,
        granted: userHasSelectedOpts ? optInsChecked[optInCode] : true,
        version: 1,
    }));
    try {
        await handleSendData(choices, closeModal, updateConsents);
    } catch (err) {
        console.log(err);
    } finally {
        if (!userHasSelectedOpts) {
            setOptInsChecked({
                [OptInIds.DataUsage]: true,
                [OptInIds.DataSharing]: true,
            });
        }
    }
};
export const handleRejectClick = async (
    optInsChecked: OptInCheckedType,
    setOptInsChecked: SetOptInType,
    closeModal: () => void,
    updateConsents: ReturnType<typeof useAhMemberConsentsUpdateMutation>['0'],
) => {
    const choices = Object.keys(optInsChecked).map(optInCode => ({
        code: optInCode,
        granted: false,
        version: 1,
    }));
    try {
        await handleSendData(choices, closeModal, updateConsents);
    } catch (err) {
        console.log(err);
    } finally {
        setOptInsChecked({
            [OptInIds.DataUsage]: false,
            [OptInIds.DataSharing]: false,
        });
    }
};
