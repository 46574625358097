import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import classNames from 'clsx';
import { type PropsWithChildren, forwardRef } from 'react';
import css from './dialog.module.scss';

export interface DialogProp {
    /**
     * Optional element id
     */
    id?: string;
    /**
     * Optional classname
     */
    className?: string;

    /**
     * data-testhook
     */
    testHook?: string;

    /**
     * Dialog open
     */
    isOpen?: boolean;
}

export const Dialog = forwardRef<HTMLDivElement, PropsWithChildren<DialogProp>>(
    ({ className, children, testHook = 'dialog-root', id, isOpen }, ref) => {
        if (!isOpen) {
            return null;
        }

        return (
            <div className={css.dialogOverlay}>
                <div
                    id={id}
                    ref={ref}
                    className={classNames(css.root, className)}
                    {...createTestHook(testHook)}
                    // biome-ignore lint/a11y/useSemanticElements: <explanation>
                    role="dialog"
                    aria-modal={true}
                >
                    {children}
                </div>
            </div>
        );
    },
);

Dialog.displayName = 'Dialog';
