import type { ApolloClient } from '@apollo/client';
import { useOutsideClick } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-outside-click/use-outside-click';
import type React from 'react';
import { type RefObject, useEffect, useState } from 'react';
import type {
    HeaderFeatureOptions,
    HeaderSupportedLocales,
} from '../../header';
import { SearchSections } from '../../header/components/search/constants';
import type { Suggestion } from '../../header/components/search/elements/suggestions/elements/suggestion/suggestion.interface';
import {
    getPopularCustomerServiceSuggestions,
    getPopularSuggestions,
} from '../../header/components/search/elements/suggestions/helpers/get-populair-suggestions';
import {
    addLocalSuggestion,
    getPrevSearchSuggestions,
} from '../../header/components/search/elements/suggestions/helpers/local-suggestions.helper';
import { normalizeSuggestions } from '../../header/components/search/normalizers/suggestion.normalizer';
import { SearchSection } from '../../header/enums/search';
import { headerTranslations } from '../../header/i18n/translation';
import { createTranslate } from '../../i18n/translation';
import {
    useCustomerServiceSearchFaqItemsQuery,
    useSearchQuery,
} from '../_generated-hooks';
import { useDebounce } from './helper/use-debounce';

const SEARCH_LIMIT = 6;
const DEBOUNCE_VALUE_MS = 300;

interface SearchHook {
    handleSubmit: (event: React.FormEvent) => void;
    handleFocus: (event: React.FocusEvent) => void;
    handleExitSearch: (event: KeyboardEvent) => void;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleClear: () => void;
    handleSelectionWithArrowsKeys: (suggestion: Suggestion) => void;
    isOpen: boolean;
    query: string;
    selectedSuggestion: string | null;
    suggestions: Suggestion[];
}

export const useSearch = (
    apollo: {
        initialised: boolean;
        ssrClient?: ApolloClient<unknown>;
    },
    featureOptions: HeaderFeatureOptions,
    formRef: RefObject<HTMLFormElement | null>,
    searchInputRef: RefObject<HTMLInputElement | null>,
    locale: HeaderSupportedLocales,
): SearchHook => {
    const { SearchDefaultSection: defaultSection } = featureOptions;
    const t = createTranslate(locale, headerTranslations);

    const [isOpen, setOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [selectedSuggestion, setSelectedSuggestion] = useState<string | null>(
        null,
    );
    const [selectedSection, setSelectedSection] = useState<SearchSection>(
        defaultSection as SearchSection,
    );

    // @TODO Does this actually work???
    const debouncedQuery = useDebounce(query, DEBOUNCE_VALUE_MS);
    const { data, previousData } = useSearchQuery({
        skip: !query || !apollo.initialised,
        variables: {
            query: debouncedQuery,
            limit: SEARCH_LIMIT,
        },
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const query = searchParams.get(
            defaultSection
                ? SearchSections[defaultSection as SearchSection].param
                : SearchSections.PRODUCTS.param,
        );
        if (query) {
            setQuery(query);
        }
    }, [defaultSection]);

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : '';
    }, [isOpen]);

    useOutsideClick({
        ref: formRef,
        callback: () => setOpen(false),
    });

    const handleFocus = () => {
        setOpen(true);
    };

    const handleExitSearch = () => {
        setOpen(false);
        searchInputRef.current?.blur();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedSuggestion(null);
        setQuery(event.target.value);
    };

    const handleClear = () => {
        setQuery('');
    };

    const handleSelectionWithArrowsKeys = (suggestion: Suggestion) => {
        setSelectedSuggestion(suggestion.value);
        setSelectedSection(suggestion.section);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const section = SearchSections[selectedSection];

        const searchString = selectedSuggestion ?? query;
        addLocalSuggestion(searchString);
        searchString.length &&
            window.location.assign(
                `${section.path}?${section.param}=${encodeURIComponent(
                    searchString,
                )}`,
            );
    };

    const { data: popularCustomerServiceSuggestions } =
        useCustomerServiceSearchFaqItemsQuery({
            ssr: true,
        });

    const defaultSuggestions =
        defaultSection === SearchSection.CUSTOMER_CARE
            ? getPopularCustomerServiceSuggestions(
                  t,
                  popularCustomerServiceSuggestions?.customerServiceSearchFaqItems,
              )
            : getPopularSuggestions(t);

    let suggestions = [...defaultSuggestions, ...getPrevSearchSuggestions()];

    if (query) {
        suggestions = normalizeSuggestions(
            data?.search ?? previousData?.search ?? [],
            defaultSection as SearchSection,
        );
    }

    return {
        handleSubmit,
        handleFocus,
        handleExitSearch,
        handleChange,
        handleClear,
        handleSelectionWithArrowsKeys,
        suggestions,
        selectedSuggestion,
        query,
        isOpen,
    };
};
