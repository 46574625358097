import {
    AH_UI_NAVIGATION_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import {
    BasketOutlined24Icon,
    OrderOutlined24Icon,
    PickupPoint24Icon,
    Store24Icon,
} from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classnames from 'clsx';
import type React from 'react';
import { useBasket } from '../../../graphql/basket/basket.hook';
import { createTranslate } from '../../../i18n/translation';
import { PATH } from '../../constants/path';
import { OrderMode } from '../../enums/order-mode';
import { headerTranslations } from '../../i18n/translation';
import type { HeaderApolloOptions } from '../../interfaces/apollo-options';
import type { HeaderSupportedLocales } from '../../interfaces/supported-locales';
import { IconButton, IconButtonVariant } from '../icon-button/icon-button';
import { numberToDoubleDecimals } from './price-helper';

import css from './nav-basket.module.scss';

const getNavBasketIcon = (orderMode: OrderMode | null) => {
    const icons = {
        [OrderMode.NONE]: BasketOutlined24Icon,
        [OrderMode.DELIVERY]: OrderOutlined24Icon,
        [OrderMode.PICK_UP_POINT]: PickupPoint24Icon,
        [OrderMode.STORE]: Store24Icon,
    };

    return icons[orderMode || OrderMode.NONE];
};

type NavBasketProps = {
    apollo: HeaderApolloOptions;
    locale: HeaderSupportedLocales;
};

export const NavBasket = ({ apollo, locale }: NavBasketProps) => {
    const { discountPrice, orderMode, totalPrice } = useBasket(
        apollo.initialised,
    );

    const t = createTranslate(locale, headerTranslations);

    const handleClick = (event: React.SyntheticEvent) => {
        event.preventDefault();
        window.location.assign(PATH.list);
    };

    return (
        <div className={classnames(css.root)}>
            <IconButton
                Icon={getNavBasketIcon(orderMode)}
                href={PATH.list}
                onClick={handleClick}
                className={css.button}
                variant={IconButtonVariant.OUTLINE}
                aria-label={t('nav-basket.icon-label')}
                {...createTestHook(
                    AH_UI_NAVIGATION_TESTHOOKS.HEADER.shoppingList,
                )}
            >
                <div className={css.priceWrapper} key={totalPrice}>
                    {discountPrice && discountPrice > 0 ? (
                        <Typography
                            variant="subtext-strong"
                            className={css.discountPrice}
                        >
                            -{numberToDoubleDecimals(discountPrice)}
                        </Typography>
                    ) : null}
                    <Typography variant="subtext-strong" className={css.price}>
                        {numberToDoubleDecimals(totalPrice)}
                    </Typography>
                </div>
            </IconButton>
            <div className={css.srOnly} aria-live="polite">
                <Typography>
                    {t('nav-basket.total') + numberToDoubleDecimals(totalPrice)}
                </Typography>
            </div>
        </div>
    );
};
