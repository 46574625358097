import type { FC } from 'react';
import { useMember } from '../../../../contexts/member';
import { handleLogout } from '../../../../helpers/logout';
import { goToLogin, isPublicMenuItem } from '../../../../helpers/menu.helper';
import { useNavigationHeader } from '../../../../hooks/navigation-header.hook';
import type { HeaderFeatureOptions } from '../../../../interfaces/feature-options';
import type { HeaderSupportedDomains } from '../../../../interfaces/supported-domains';
import type { HeaderSupportedLocales } from '../../../../interfaces/supported-locales';
import { MenuItemComponent } from '../../../menu-item-component/menu-item-component';
import css from '../nav-panel-pane.module.scss';

type NavPanelProfileProps = {
    featureOptions: HeaderFeatureOptions;
    domain: HeaderSupportedDomains;
    locale: HeaderSupportedLocales;
};

export const NavPanelProfile: FC<NavPanelProfileProps> = ({
    featureOptions,
    domain,
    locale,
}) => {
    const { menuItems, links } = useNavigationHeader(domain);
    const {
        member: { isLoggedIn, memberLoginState },
    } = useMember();

    return (
        <div className={css.pane}>
            <menu className={css.menuList}>
                {menuItems.userMenu.map(item => (
                    <MenuItemComponent
                        featureOptions={featureOptions}
                        key={item.id}
                        item={item}
                        className="compactMenuItem"
                        greyedOut={!isPublicMenuItem(item, memberLoginState)}
                        foldoutVariant
                        locale={locale}
                    />
                ))}
                {isLoggedIn && (
                    <MenuItemComponent
                        featureOptions={featureOptions}
                        item={{ ...links.logout, href: undefined }}
                        className="compactMenuItem"
                        onClick={handleLogout}
                        foldoutVariant
                        locale={locale}
                    />
                )}
                {!isLoggedIn && (
                    <MenuItemComponent
                        featureOptions={featureOptions}
                        item={{ ...links.login, href: undefined }}
                        className="compactMenuItem"
                        onClick={goToLogin}
                        foldoutVariant
                        locale={locale}
                    />
                )}
            </menu>
        </div>
    );
};
