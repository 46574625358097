import type { ApolloError, ApolloQueryResult } from '@apollo/client';
import { getDataLayer, initDataLayer } from '@royalaholddelhaize/ah-analytics';
import { useEffect } from 'react';
import {
    useActiveOrderQuery,
    useOrderFulfillmentsQuery,
} from '../../graphql/_generated-hooks';
import type { MemberQuery } from '../../graphql/_generated-operation-types';
import { useBasket } from '../../graphql/basket/basket.hook';
import type { Member } from '../../graphql/member/member.hook';

export const useCidAnalytics = (
    initialised: boolean,
    refetch: () => Promise<ApolloQueryResult<MemberQuery>>,
    member?: Member | null,
    loading?: boolean,
    error?: ApolloError,
) => {
    const { data: activeOrderData } = useActiveOrderQuery({
        skip: !member || !initialised,
        ssr: false,
    });

    const { data: orderData } = useOrderFulfillmentsQuery({
        skip: !member || !initialised,
        ssr: false,
    });

    const basket = useBasket(initialised);

    /**
     * If there is a change in the basket, refetch
     * member if there was no member if before, because it
     * should receive a temporary id after the first add to basket
     */
    useEffect(() => {
        if (basket?.numberItems > 0 && !member?.id) {
            refetch();
        }
    }, [basket?.numberItems, member?.id, refetch]);

    useEffect(() => {
        const dataLayer = getDataLayer();

        if (!loading && !error && member) {
            const cidData = {
                user: {
                    ...(member?.id && {
                        userId: member?.id,
                    }),
                    ...(member?.memberLoginState && {
                        userLoginStatus: member?.memberLoginState,
                        userIsRegistered: member?.memberIsOnboarded,
                    }),
                },
                ...(activeOrderData?.order?.id && {
                    orderId: activeOrderData.order.id,
                }),
            };

            const userStatus = orderData?.orderFulfillments?.result?.length
                ? 'existing'
                : 'new';
            const salesOfGoods =
                activeOrderData?.order?.price?.priceBeforeDiscount?.amount;
            const orderMethod = activeOrderData?.order?.shoppingType;
            const isFirstAddToBasket = !basket?.numberItems;

            /**
             * Temp object is needed to store values used by marketing tracking
             * that were previously on the ahDataLayer. Should be a temporary solution
             * while the analytics team works on moving the tracking server side
             */
            const temp = {
                ...(member?.analyticsMeta?.idsas && {
                    userIdSas: member.analyticsMeta.idsas,
                    userType: member?.isB2B ? 'B2B' : 'B2C',
                }),
                ...(!!orderData?.orderFulfillments && {
                    userStatus,
                }),
                ...(salesOfGoods && { salesOfGoods }),
                ...(orderMethod && { orderMethod }),
                isFirstAddToBasket,
            };

            if (!dataLayer) {
                initDataLayer({ ...cidData, temp });
            } else {
                dataLayer.updateData(cidData);
                if (dataLayer.updateTempData) {
                    dataLayer.updateTempData(temp);
                }
            }
        }
    }, [member, activeOrderData, loading, error, orderData, basket]);
};
