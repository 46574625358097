import type { CreateClientOptions } from '@royalaholddelhaize/ah-next-graphql-client';
import { recipeCollectionCategoryTypePolicy } from '@royalaholddelhaize/ah-ui-recipe-card/src/graphql/recipe-collections/recipe-collection-cache-options';
import { recipeProductSuggestionTypePolicy } from '@royalaholddelhaize/ah-ui-recipe-card/src/graphql/recipe-product-suggestions/recipe-product-suggestions-cache-options';
import fragmentMatcher from '../_generated-fragment-matcher';

export const apolloConfig: CreateClientOptions = {
    uri: process.env.GQL_SSR_URL || 'http://webauth-tst.digitaldev.nl/gql',
    headers: {
        'x-client-name': process.env.NEXT_PUBLIC_CLIENT || 'unknown',
        'x-client-version': process.env.NEXT_PUBLIC_VERSION || '0.0.0',
    },
    possibleTypes: fragmentMatcher.possibleTypes,
    cacheOptions: {
        typePolicies: {
            ...recipeCollectionCategoryTypePolicy,
            ...recipeProductSuggestionTypePolicy,
        },
    },
    logErrors: process.env.NODE_ENV === 'production' ? 'server' : true,
    disableBatch: true,
};
