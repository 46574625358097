import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import type React from 'react';
import { DialogBackButton } from '../dialog-back-button/dialog-back-button';
import { DialogCloseButton } from '../dialog-close-button/dialog-close-button';
import css from './dialog-header.module.scss';

export interface DialogHeaderProps
    extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Content of dialog
     */
    showCloseButton?: boolean;

    /**
     * Title of dialog
     */
    title: string;

    /**
     * Close Function for the dialog
     */
    onClose?: () => void;

    /**
     * Close Function for the dialog
     */
    onBack?: () => void;
    /**
     * Custom classname for the dialog header
     */
    className?: string;
    /**
     * Custom classname for the dialog title
     */
    titleClassName?: string;
}

export const DialogHeader: React.FC<DialogHeaderProps> = ({
    title,
    onClose,
    onBack,
    className,
    titleClassName,
    showCloseButton = true,
}) => {
    const hasTitle = title && title !== '';
    return (
        <header className={classNames(css.headerContainer, className)}>
            {onBack && <DialogBackButton onBack={onBack} />}
            {hasTitle && (
                <Typography
                    variant="heading-1"
                    className={classNames(css.title, titleClassName)}
                >
                    {title}
                </Typography>
            )}

            {showCloseButton && <DialogCloseButton onClose={onClose} />}
        </header>
    );
};
