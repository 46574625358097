'use client';

import { useContext } from 'react';
import type { FeatureExperimentsData } from '../types';
import { FeatureExperimentsContext } from './context';

export const useFeatureExperiments = (): FeatureExperimentsData => {
    const context = useContext(FeatureExperimentsContext);

    if (context === null) {
        throw new Error(
            'useFeatureExperiments must be used within a FeatureExperimentsProvider',
        );
    }

    return context;
};
