export const LoggedIn24Icon = ({
    size = 24,
    className,
}: {
    size?: number | string;
    className?: string;
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={size}
        width={size}
        className={className}
        fill="currentColor"
        viewBox="0 0 31 30"
    >
        <title>Logged in User</title>
        <path
            fill="#303030"
            fillRule="evenodd"
            d="M9 6c0-1.65685 1.3431-3 3-3s3 1.34315 3 3-1.3431 3-3 3-3-1.34315-3-3Zm3-5C9.23858 1 7 3.23858 7 6s2.23858 5 5 5c2.7614 0 5-2.23858 5-5s-2.2386-5-5-5ZM4 20c0-1.8173.94196-3.369 2.45-4.5 1.51795-1.1385 3.5549-1.8 5.55-1.8 1.9951 0 4.032.6615 5.55 1.8C19.058 16.631 20 18.1827 20 20v1H4v-1Zm8-8.3c-2.40491 0-4.86795.7885-6.75 2.2C3.35804 15.319 2 17.4173 2 20v1c0 .5273.18011 1.0443.56789 1.4321C2.95567 22.8199 3.47275 23 4 23h16c.5272 0 1.0443-.1801 1.4321-.5679C21.8199 22.0443 22 21.5273 22 21v-1c0-2.5827-1.358-4.681-3.25-6.1-1.882-1.4115-4.3451-2.2-6.75-2.2Z"
            clipRule="evenodd"
        />
        <circle
            cx="21.1406"
            cy="20.2085"
            r="8"
            fill="#14D055"
            stroke="#fff"
            strokeWidth="2"
        />
        <path fill="#14D055" d="M18 17h6.2085v6.2085H18z" />
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M20.9865 23.2602c-.2062.3043-.6354.3213-.8635.0344l-2.7145-3.4152c-.1906-.2399-.1575-.5944.0739-.7919.2315-.1975.5737-.1632.7643.0766l2.261 2.8447 3.429-5.0605c.1723-.2542.5109-.3156.7563-.1371.2454.1785.3047.5294.1324.7836l-3.8389 5.6654Z"
            clipRule="evenodd"
        />
    </svg>
);
