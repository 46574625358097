import type { Locale } from '@royalaholddelhaize/ah-web-core';
import { SignOut24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/sign-out-24';
import type { FC } from 'react';
import { handleLogout } from '../../../header/helpers/logout';
import type { MenuItem as MenuItemType } from '../../interfaces/profile-menu';
import { MenuItem } from './elements/menu-item/menu-item';
import css from './menu.module.scss';

export interface ProfileMenuProps {
    locale: Locale;
    memberType: 'B2B' | 'B2C';
    featureFlags: Record<string, string>;
    setSubMenu?: (subItems: MenuItemType) => void;
}

interface MenuProps extends ProfileMenuProps {
    menuConfig: MenuItemType[];
    currentMenuItem: MenuItemType | null;
    setCurrentMenuItem: (currentMenuItem: MenuItemType) => void;
}

export const Menu: FC<MenuProps> = ({
    locale,
    menuConfig,
    memberType,
    featureFlags,
    currentMenuItem,
    setCurrentMenuItem,
}) => (
    <menu className={css.root}>
        {menuConfig.map((menuItem, index) => (
            <MenuItem
                key={index.toString()}
                locale={locale}
                menuItem={menuItem}
                memberType={memberType}
                featureFlags={featureFlags}
                isSelected={
                    currentMenuItem !== null &&
                    menuItem.title === currentMenuItem.title
                }
                onClick={() => setCurrentMenuItem(menuItem)}
            />
        ))}

        <MenuItem
            key="/account/uitloggen"
            locale={locale}
            menuItem={{
                title: 'log-out',
                icon: SignOut24Icon,
                url: '/account/uitloggen',
            }}
            memberType={memberType}
            featureFlags={featureFlags}
            onClick={handleLogout}
        />
    </menu>
);
