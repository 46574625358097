import type { Locale } from '@royalaholddelhaize/ah-web-core';

export type NestedKeyOf<ObjectType extends object> = {
    [Key in keyof ObjectType &
        (string | number)]: ObjectType[Key] extends object
        ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
        : `${Key}`;
}[keyof ObjectType & (string | number)];

type LabelValueMap = Record<string, string>;
type NestedLabels = Record<string, LabelValueMap | string>;

const returnLabel = (obj: NestedLabels, labels: Array<string>): string => {
    const current = labels.shift() || '';
    if (!obj?.[current]) {
        return '';
    }
    if (typeof obj[current] === 'string') {
        return obj[current] as string;
    }
    return returnLabel(obj[current] as NestedLabels, labels);
};

const interpolate = (
    label: string,
    interpolation: Record<string, string>,
): string => {
    return Object.keys(interpolation).reduce(
        (res, next) => res.replace(`{{${next}}}`, interpolation[next]),
        label,
    );
};

export type TranslationFunction<LanguageFileType extends object> = (
    key: NestedKeyOf<LanguageFileType>,
    interpolation?: Record<string, string>,
) => string;

// TODO: Write unit tests!!!
export function createTranslate<
    L extends Locale,
    LanguageFileType extends object,
>(
    locale: L,
    languages: Record<L, LanguageFileType>,
): TranslationFunction<LanguageFileType> {
    const lang = languages[locale] as NestedLabels;
    return (key, interpolation) => {
        const keys = key.split('.');
        const value = returnLabel(lang, keys);
        return interpolate(value, interpolation || {}) || key;
    };
}
