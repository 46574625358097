export const PATH = {
    about: '/over-ah',
    bonus: '/bonus',
    business: '/zakelijk',
    campaigns: '/acties',
    customerService: '/klantenservice',
    deliveryBundle: '/bezorgbundel',
    deliveryBundleBusiness: '/bezorgbundel-zakelijk',
    favorites: '/favorieten',
    folder: '/bonus/folder',
    list: '/mijnlijst',
    login: '/login',
    logout: '/account/uitloggen',
    orderOnline: '/kies-een-moment',
    orders: '/mijnbestellingen',
    ordersBE: '/mijnreserveringen',
    premium: '/premium',
    previouslyBought: '/producten/eerder-gekocht',
    products: '/producten',
    profileDashboard: '/mijn/dashboard',
    promotions: '/acties',
    recipes: '/allerhande',
    recommendations: '/maak-compleet',
    root: '/',
    search: '/zoeken',
    shops: '/winkels',
    start: '#start-of-content',
    searchInput: '#navigation-search-input',
};
