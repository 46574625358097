'use client';

import { LinkOrAnchor } from '@components/elements/link-or-anchor/link-or-anchor';
import { AllerhandeRoute } from '@enums/route';
import { Holiday, currentHoliday } from '@helpers/current-holiday';
import { getNavigationLinks } from '@helpers/get-navigation-links';
import { stripContextPath } from '@helpers/strip-context-path';
import {
    EventName,
    createAnalyticsHook,
} from '@royalaholddelhaize/ah-analytics';
import { Viewport } from '@royalaholddelhaize/ah-next-core/src/enums/viewport';
import { useHost } from '@royalaholddelhaize/ah-next-core/src/host/client';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { useViewport } from '@royalaholddelhaize/ah-next-core/src/viewport/client';
import { usePathname } from 'next/navigation';
import { useRef } from 'react';
import { NavigationLogo } from './elements/navigation-logo/navigation-logo';
import { NavigationMenuAnchor } from './elements/navigation-menu-anchor/navigation-menu-anchor';
import { NavigationMenuMobile } from './elements/navigation-menu-mobile/navigation-menu-mobile';
import { NavigationMenu } from './elements/navigation-menu/navigation-menu';
import { Navigation as NavigationComp } from './elements/navigation/navigation';

// This function is overly complex due to the fact that the Allerhande website structure is not very well defined.
export const isLinkActive = (
    paths: AllerhandeRoute[],
    pathname: string,
): boolean => {
    let strippedPathname = stripContextPath(pathname);
    if (strippedPathname === '/root') {
        strippedPathname = '';
    }

    return paths.some(path => {
        const strippedPath = stripContextPath(path);

        if (strippedPath === '') {
            return strippedPathname === strippedPath;
        }

        if (strippedPathname.startsWith(strippedPath)) {
            const leftoverPath = strippedPathname.replace(strippedPath, '');

            return leftoverPath === '' ? true : leftoverPath.startsWith('/');
        }

        return false;
    });
};

export const Navigation = () => {
    const { t } = useTranslations();
    const { viewport } = useViewport();
    const { domain } = useHost();
    const isMobile = [Viewport.EXTRA_SMALL, Viewport.SMALL].includes(viewport);
    const pathname = usePathname();
    const navigationLinks = getNavigationLinks(domain);
    const ref = useRef<HTMLElement>(null);

    const links = navigationLinks.map(navigationLink => ({
        ...navigationLink,
        isActive: isLinkActive(navigationLink.paths, pathname),
    }));

    const renderLinks = () =>
        links.map(navigationLink => {
            return (
                <NavigationMenuAnchor
                    key={navigationLink.href}
                    label={t(navigationLink.label)}
                    href={navigationLink.href}
                    paths={navigationLink.paths}
                    isActive={navigationLink.isActive}
                />
            );
        });

    return (
        <NavigationComp ref={ref}>
            <LinkOrAnchor
                href={AllerhandeRoute.Home}
                aria-label={t('title')}
                title={t('title')}
                {...createAnalyticsHook(EventName.COMPONENT_INTERACTION, {
                    componentType: 'Navigation header',
                    componentTitle: 'Allerhande navigation',
                    componentSubType: 'link',
                    componentInnerText: 'Allerhande Logo',
                    componentTargetUrl: AllerhandeRoute.Home,
                    componentSection: 'Allerhande',
                })}
            >
                <NavigationLogo
                    aria-label={t('logo')}
                    variant={
                        currentHoliday() === Holiday.CHRISTMAS
                            ? 'christmas'
                            : 'white'
                    }
                />
            </LinkOrAnchor>
            {isMobile ? (
                <NavigationMenuMobile
                    label={t('navigation:toggle.label')}
                    ariaLabel={t('navigation:toggle.ariaLabel')}
                    ref={ref}
                >
                    {renderLinks()}
                </NavigationMenuMobile>
            ) : (
                <NavigationMenu>{renderLinks()}</NavigationMenu>
            )}
        </NavigationComp>
    );
};
