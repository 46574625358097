'use client';

import { type FC, type ReactNode, useContext } from 'react';
import type { XVariantHeader } from '../types';
import { FeatureExperimentsContext } from './context';

interface FeatureExperimentComponentProps {
    children: (value?: string) => ReactNode;
    variant: XVariantHeader;
}

export const FeatureExperimentComponent: FC<
    FeatureExperimentComponentProps
> = ({ variant, children }) => {
    const { featureExperiments } = useContext(FeatureExperimentsContext);

    return children(featureExperiments[variant]);
};
