import {
    CustomerService24Icon,
    Search24Icon,
} from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import {
    type CustomerServiceSuggestionFragment,
    SearchSuggestionType,
} from '../../../../../../graphql/_generated-operation-types';
import { PATH } from '../../../../../constants/path';
import { SearchSection } from '../../../../../enums/search';
import type { HeaderTranslationKey } from '../../../../../i18n/translation';
import { SearchSections } from '../../../constants';
import { POPULAR_SEARCH_TERMS, POPULAR_TERM_PREF } from '../constants';
import type { Suggestion } from '../elements/suggestion/suggestion.interface';
import { extractAnchorFromUrl } from './extract-anchor-from-url';

export const getPopularCustomerServiceSuggestions = (
    t: (key: HeaderTranslationKey) => string,
    data?: CustomerServiceSuggestionFragment[],
): Suggestion[] => {
    const suggestions = data?.map(({ title, url }) => ({
        id: `popular-customer-care-term-${extractAnchorFromUrl(url)}`,
        label: title,
        value: title,
        type: SearchSuggestionType.DEFAULT,
        href: url,
        icon: CustomerService24Icon,
        section: SearchSection.CUSTOMER_CARE,
    }));

    return [
        {
            id: 'suggestions-faq',
            label: 'suggestions.faq',
            value: t('suggestions.faq'),
            type: SearchSuggestionType.SECTION,
            href: SearchSections.CUSTOMER_CARE.path,
            section: SearchSection.CUSTOMER_CARE,
            suggestions,
        },
    ];
};

export const getPopularSuggestions = (
    t: (key: HeaderTranslationKey) => string,
): Suggestion[] => [
    {
        id: 'popular-suggestions',
        label: 'suggestions.popular',
        value: t('suggestions.popular'),
        type: SearchSuggestionType.SECTION,
        href: SearchSections.PRODUCTS.path,
        section: SearchSection.PRODUCTS,
        suggestions: POPULAR_SEARCH_TERMS.slice(0, 3).map(term => ({
            id: `${POPULAR_TERM_PREF}-${term}`,
            label: term,
            value: term,
            type: SearchSuggestionType.DEFAULT,
            href: `${PATH.search}?query=${term}`,
            icon: Search24Icon,
            section: SearchSection.PRODUCTS,
        })),
    },
];
