import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import classNames from 'clsx';
import type React from 'react';
import type { FC, PropsWithChildren } from 'react';
import { DialogCloseButton } from '../dialog-close-button/dialog-close-button';
import css from './dialog-content.module.scss';

export interface DialogContentProps
    extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Component class name
     */
    className?: string;

    /**
     * Add test-hook on container
     */
    testHook?: string;

    /**
     * Close Function for the dialog
     */
    onClose?: () => void;

    /**
     * Content of dialog
     */
    showCloseButton?: boolean;
}

export const DialogContent: FC<PropsWithChildren<DialogContentProps>> = ({
    className,
    children,
    testHook,
    onClose,
    showCloseButton = false,
    ...restProps
}) => (
    <article
        className={classNames(css.root, className)}
        {...restProps}
        {...createTestHook(testHook)}
    >
        <div
            className={classNames(css.contentContainer, {
                [css.showCloseButton]: showCloseButton,
            })}
        >
            <div className={css.content}>{children}</div>
            {showCloseButton && <DialogCloseButton onClose={onClose} />}
        </div>
    </article>
);
