'use client';

import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import type { Locale } from '@royalaholddelhaize/ah-web-core';
import { ChevronRight24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-right-24';
import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import { createMenuAnchor } from '../../../helper/menu-anchor.helper';
import { createTranslate } from '../../../i18n/translation';
import {
    breadcrumbBuilder,
    checkIfPageIsOutsideMenu,
} from '../../helpers/breadcrumb-builder';
import {
    type ProfileTranslationKey,
    profileTranslations,
} from '../../i18n/translation';
import type { ProfileMenuType } from '../../interfaces/profile-menu';

import css from './breadcrumb.module.scss';

interface BreadcrumbProps {
    locale: Locale;
    config: ProfileMenuType;
    path: string;
    anchor: string;
    displayName?: string | null;
}
export const Breadcrumb: FC<BreadcrumbProps> = ({
    locale,
    config,
    path,
    displayName,
    anchor,
}) => {
    const t = createTranslate(locale, profileTranslations);
    const pageOutsideMenu = checkIfPageIsOutsideMenu(config, path);
    const breadcrumbs = breadcrumbBuilder(
        t,
        config,
        pageOutsideMenu?.parent || path,
        anchor,
    );

    if (pageOutsideMenu) {
        // If this is true we need to add it as a new item
        breadcrumbs.push({
            title: pageOutsideMenu.title,
            subTitle: '',
            url: pageOutsideMenu.url,
        });
    }

    return (
        <div className={css.breadcrumbWrapper}>
            {displayName && <h3>Hoi {displayName}</h3>}
            {breadcrumbs.length > 1 && (
                <div className={css.breadcrumbs}>
                    {breadcrumbs.map((breadcrumb, index) => {
                        const title = breadcrumb.title.startsWith(
                            'breadcrumbs.',
                        )
                            ? t(breadcrumb.title as ProfileTranslationKey)
                            : t(
                                  `menu.${breadcrumb.title}` as ProfileTranslationKey,
                              );
                        if (index !== breadcrumbs.length - 1) {
                            return (
                                <div
                                    className={css.breadcrumb}
                                    key={title}
                                    {...createTestHook(
                                        `breadcrumb-${title.toLowerCase()}`,
                                    )}
                                >
                                    {breadcrumb.url ? (
                                        <LinkAnchor
                                            className={css.link}
                                            href={breadcrumb.url}
                                        >
                                            <LinkText>{title}</LinkText>
                                        </LinkAnchor>
                                    ) : (
                                        <LinkAnchor
                                            className={css.link}
                                            href={createMenuAnchor(title)}
                                        >
                                            <LinkText>{title}</LinkText>
                                        </LinkAnchor>
                                    )}
                                    <ChevronRight24Icon className={css.arrow} />
                                </div>
                            );
                        }
                    })}
                    <Typography variant="body-strong">
                        {breadcrumbs[breadcrumbs.length - 1].title.startsWith(
                            'breadcrumbs.',
                        )
                            ? t(
                                  breadcrumbs[breadcrumbs.length - 1]
                                      .title as ProfileTranslationKey,
                              )
                            : t(
                                  `menu.${breadcrumbs[breadcrumbs.length - 1].title}` as ProfileTranslationKey,
                              )}
                    </Typography>
                </div>
            )}
        </div>
    );
};
