'use client';

import type { Banner } from '@royalaholddelhaize/ah-web-core';
import {
    LinkButton,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import { createTranslate } from '../i18n/translation';
import { NavBarSlot } from './components/nav-bar-slot/nav-bar-slot';
import { NavigationBasket } from './components/navigation-basket/navigation-basket';
import { useMember } from './contexts/member';
import { HeaderFocusedBase } from './header-focused-base';
import { handleLogout } from './helpers/logout';
import { useNavigationHeader } from './hooks/navigation-header.hook';
import { headerTranslations } from './i18n/translation';
import type { HeaderApolloOptions } from './interfaces/apollo-options';
import type { HeaderFeatureOptions } from './interfaces/feature-options';
import type { HeaderSupportedDomains } from './interfaces/supported-domains';
import type { HeaderSupportedLocales } from './interfaces/supported-locales';

import css from './header-focused.module.scss';

export interface HeaderFocusedProps {
    /**
     * Given boolean value if the NavigationBasket component needs to be shown in the header.
     */
    showBasket?: boolean;

    apollo: HeaderApolloOptions;

    banner: Banner;
    domain: HeaderSupportedDomains;
    locale: HeaderSupportedLocales;

    featureOptions: HeaderFeatureOptions;
}

export const HeaderFocused = ({
    apollo,
    showBasket = false,
    banner,
    domain,
    locale,
}: HeaderFocusedProps) => {
    const { links } = useNavigationHeader(domain);
    const {
        member: { isLoggedIn },
    } = useMember();

    const t = createTranslate(locale, headerTranslations);

    return (
        <HeaderFocusedBase banner={banner} locale={locale}>
            {showBasket && (
                <NavBarSlot align="Right" disableShrink>
                    <NavigationBasket apollo={apollo} locale={locale} />
                </NavBarSlot>
            )}

            {!showBasket && isLoggedIn && (
                <NavBarSlot align="Right" disableShrink>
                    <LinkButton className={css.button} onClick={handleLogout}>
                        <LinkText level="secondary">
                            {t(links.logout.label)}
                        </LinkText>
                    </LinkButton>
                </NavBarSlot>
            )}
        </HeaderFocusedBase>
    );
};
