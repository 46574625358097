import type { TranslationFunction } from '../../i18n/translation';
import type {
    ProfileTranslationFile,
    ProfileTranslationKey,
} from '../i18n/translation';
import type { MenuItem } from '../interfaces/profile-menu';
export const findMenuItemByAnchor = (
    t: TranslationFunction<ProfileTranslationFile>,
    menu: MenuItem[],
    targetAnchor: string,
): MenuItem | null => {
    let foundItem: MenuItem | null = null;
    menu.forEach(item => {
        const itemAnchor = t(
            `menu.${item.title}` as ProfileTranslationKey,
        ).replace(/\s/g, '_');
        if (itemAnchor === targetAnchor && !foundItem) {
            foundItem = item;
        }
        if (item.items && !foundItem) {
            foundItem = findMenuItemByAnchor(t, item.items, targetAnchor);
        }
    });
    return foundItem;
};
