import classNames from 'clsx';
import type React from 'react';
import type { FC, PropsWithChildren } from 'react';
import css from './dialog-actions.module.scss';

export interface DialogActionsProps
    extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Custom classname for the dialog footer
     */
    className?: string;
}

export const DialogActions: FC<PropsWithChildren<DialogActionsProps>> = ({
    children,
    className,
}) => {
    return (
        <footer className={classNames(css.root, className)}>{children}</footer>
    );
};
