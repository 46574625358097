'use client';

import {
    AH_UI_NAVIGATION_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import type { FC } from 'react';
import { createTranslate } from '../../../i18n/translation';
import { PATH } from '../../constants/path';
import { footerTranslations } from '../../i18n/translation';
import type { FooterSupportedLocales } from '../../interfaces/supported-locales';
import styles from './notices-nl.module.scss';

export interface NoticesNlProps {
    locale: FooterSupportedLocales;
}

export const NoticesNl: FC<NoticesNlProps> = ({ locale }) => {
    const t = createTranslate(locale, footerTranslations);

    return (
        <section
            className={styles.root}
            {...createTestHook(AH_UI_NAVIGATION_TESTHOOKS.FOOTER.notices)}
        >
            <div className={styles.nix}>
                <img
                    src="https://static.ah.nl/ah-static/images/ah-ui-bridge-components/logo/nix18.svg"
                    alt={t('notices.nix-alt')}
                    height="16"
                    width="59"
                />
                <LinkAnchor className={styles.button} href={PATH.nix18}>
                    <div className={styles.text}>
                        <LinkText level="secondary" size="small">
                            &#60;
                        </LinkText>
                        <LinkText level="secondary" size="small">
                            {t('notices.nix25year')}
                        </LinkText>
                    </div>
                    <div className={styles.text}>
                        <LinkText level="secondary" size="small">
                            &#60;
                        </LinkText>
                        <LinkText level="secondary" size="small">
                            {t('notices.nix18year')}
                        </LinkText>
                    </div>
                </LinkAnchor>
            </div>
            <menu>
                <li className={styles.image}>
                    <img
                        src="https://static.ah.nl/ah-static/images/ah-ui-bridge-components/logo/ideal.svg"
                        alt={t('notices.ideal-alt')}
                        height="32"
                        width="36"
                    />
                </li>
                <li className={styles.image}>
                    <img
                        src="https://static.ah.nl/ah-static/images/ah-ui-bridge-components/logo/pin.svg"
                        alt={t('notices.pin-alt')}
                        height="32"
                        width="46"
                    />
                </li>
                <li className={styles.image}>
                    <LinkAnchor
                        aria-label={t('notices.drug-alt')}
                        href="https://www.aanbiedersmedicijnen.nl/aanbieders/aanbiederslijst/albert-heijn/index"
                        target="_blank"
                        rel="noreferrer noopener"
                        className={styles.background}
                    >
                        <img
                            src="https://static.ah.nl/ah-static/images/ah-ui-bridge-components/logo/drug-provider.svg"
                            alt={t('notices.drug-alt')}
                            height="30"
                            width="36"
                            className={styles.drug}
                        />
                    </LinkAnchor>
                </li>
                <li>
                    <LinkAnchor
                        aria-label={t('notices.shop-label')}
                        href="https://www.thuiswinkel.org/leden/ah.nl/certificaat"
                        target="_blank"
                        rel="noreferrer noopener"
                        className={styles.background}
                    >
                        <img
                            src="https://static.ah.nl/ah-static/images/ah-ui-bridge-components/logo/thuiswinkelwaarborg.svg"
                            alt={t('notices.shop-alt')}
                            height="32"
                            width="43"
                        />
                    </LinkAnchor>
                </li>
                <li>
                    <LinkAnchor
                        aria-label={t('notices.shop-label')}
                        href="https://www.thuiswinkel.org/leden-zakelijk/ah-nl/certificaat/"
                        target="_blank"
                        rel="noreferrer noopener"
                        className={styles.background}
                    >
                        <img
                            src="https://static.ah.nl/ah-static/images/ah-ui-bridge-components/logo/thuiswinkelwaarborg-business.svg"
                            alt={t('notices.shop-label')}
                            height="32"
                            width="37"
                        />
                    </LinkAnchor>
                </li>
            </menu>
        </section>
    );
};
