'use client';

import classNames from 'clsx';
import type { FC, PropsWithChildren } from 'react';
import { HeaderType } from './enums/header-type';
import { useHeaderInteraction } from './hooks/use-header-interaction';

import css from './header-layout-container.module.scss';

export interface HeaderLayoutProps extends PropsWithChildren {
    layout: HeaderType;
    hasDeliveryNotification: boolean;
}

export const HeaderLayoutContainer: FC<HeaderLayoutProps> = ({
    layout,
    children,
    hasDeliveryNotification,
}) => {
    const { isVisible } = useHeaderInteraction();

    return (
        <>
            <div
                className={classNames({
                    [css.placeholder]: true,
                    [css.placeholderLarge]: hasDeliveryNotification,
                    [css.focused]: layout !== HeaderType.DEFAULT,
                })}
            />
            <div
                className={classNames({
                    [css.root]: true,
                    [css.hide]: !isVisible,
                })}
            >
                {children}
            </div>
        </>
    );
};
