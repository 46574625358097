import { appCanUseRUM } from '../helpers/check-cookie';

const faroCollector =
    'https://faro-collector-prod-eu-west-2.grafana.net/collect';

const defaultUrls: Record<string, string> = {
    tst: `${faroCollector}/45508a70985bb5e627f9154770a05623`,
    acc: `${faroCollector}/e996c37b769b1b39e0e8c929eb6edb6f`,
    prd: `${faroCollector}/4a12df41aca8bb2d8515ded5c146b7c1`,
};

interface ObservabilityConfig {
    appName: string;
    appVersion: string;
}

export const initializeObservability = ({
    appName,
    appVersion,
}: ObservabilityConfig) => {
    if (typeof window === 'undefined' || !appCanUseRUM()) {
        return;
    }

    const hostname = window.location.hostname;

    if (
        hostname.includes('localhost') ||
        hostname.includes('127.0.0.1') ||
        hostname.includes('local.ah.')
    ) {
        console.warn('Observability is disabled in local environment');
        return;
    }

    // Determine environment-based URL
    const env = hostname.includes('tst')
        ? 'tst'
        : hostname.includes('acc')
          ? 'acc'
          : 'prd';

    const finalUrl = defaultUrls[env];

    if (!finalUrl) {
        console.error('No valid Faro URL found for environment:', env);
        return;
    }

    // Check if Faro is already available
    if (window.GrafanaFaroWebSdk) {
        console.log('Grafana Faro SDK already loaded, initializing...');
        window.GrafanaFaroWebSdk.initializeFaro({
            url: finalUrl,
            app: { name: appName, version: appVersion },
        });
        return;
    }

    // Check if the script is already in the document
    if (document.querySelector('script[src*="faro-web-sdk.iife.js"]')) {
        console.warn('Grafana Faro SDK is already being loaded.');
        return;
    }

    console.log('Loading Grafana Faro SDK from CDN...');
    const script = document.createElement('script');
    script.src = 'https://static.ah.nl/ah-static/assets/faro-web-sdk.iife.js';
    script.async = true;

    script.onload = () => {
        if (window.GrafanaFaroWebSdk) {
            console.log(
                'Grafana Faro SDK loaded successfully, initializing...',
            );
            window.GrafanaFaroWebSdk.initializeFaro({
                url: finalUrl,
                app: { name: appName, version: appVersion },
            });
        } else {
            console.error('Grafana Faro SDK did not load properly.');
        }
    };

    script.onerror = () => {
        console.error('Failed to load Grafana Faro SDK.');
    };

    document.head.appendChild(script);
};
