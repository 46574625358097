'use client';

import type { FC } from 'react';
import { useScriptLoader } from '../script-loader';

export const FeatureOverlay: FC = () => {
    const showFeatureOverlay =
        typeof window !== 'undefined' &&
        (window.location.href.includes('tst.ah.nl') ||
            window.location.href.includes('tst.ah.be'));

    useScriptLoader(showFeatureOverlay, '/__proxy/js/feature-overlay.js');

    return null;
};
