'use client';

import { emitComponentEvent } from '@royalaholddelhaize/ah-analytics';
import {
    AH_UI_NAVIGATION_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import {
    ChevronRight16Icon,
    OrderOutlined24Icon,
    PickupPoint24Icon,
    Store24Icon,
} from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import classNames from 'clsx';
import { useEffect } from 'react';
import {
    type DeliveryNotificationFragment,
    NotificationIcon,
} from '../../../graphql/_generated-operation-types';
import { useHeaderInteraction } from '../../hooks/use-header-interaction';
import css from './delivery-notification.module.scss';

export interface DeliveryNotificationProps {
    /**
     * Data returned from GraphQL, either a DeliveryNotification or nothing.
     */
    notification: DeliveryNotificationFragment | null;
}

export const DeliveryNotification = ({
    notification,
}: DeliveryNotificationProps) => {
    const { isVisible } = useHeaderInteraction();

    useEffect(() => {
        if (notification?.id) {
            emitComponentEvent({
                componentAction: 'view order communication block',
                component: {
                    componentType: 'navigation-header',
                    componentSection: 'Lists & Orders - Navigation',
                },
            });
        }
    }, [notification?.id]);

    if (!notification) {
        return null;
    }

    let Icon = OrderOutlined24Icon;
    if (notification.icon === NotificationIcon.PICKUP) {
        Icon = Store24Icon;
    } else if (notification.icon === NotificationIcon.PICKUP_HOME_DELIVERY) {
        Icon = PickupPoint24Icon;
    }

    return (
        <>
            <div
                className={classNames(css.root, {
                    [css.hide]: !isVisible,
                })}
            >
                <LinkAnchor
                    onClick={() => {
                        emitComponentEvent({
                            componentAction: 'click order communication block',
                            component: {
                                componentType: 'navigation-header',
                                componentSection: 'Lists & Orders - Navigation',
                            },
                        });
                    }}
                    href={notification?.link.href}
                    className={classNames(css.title, css.titleHasIcon)}
                    {...createTestHook(
                        AH_UI_NAVIGATION_TESTHOOKS.HEADER.notificationDelivery,
                    )}
                >
                    <Icon size={24} className={css.icon} />
                    <LinkText>{notification?.dateInfo}</LinkText>
                    <LinkText level="secondary">
                        {notification?.link.title}
                    </LinkText>
                    <ChevronRight16Icon size={16} className={css.chevron} />
                </LinkAnchor>
            </div>
        </>
    );
};
