import { NOTIFICATION_STORAGE } from '../notifications/notifications';

const MINIMUM_NUMBER_OF_ITEMS = 5;
export const NOTIFICATION_KEY = `${NOTIFICATION_STORAGE}-select-order-moment`;
export const WEEK_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;

export const shouldShowNotification = (
    totalItems: number,
    orderMode: boolean,
): boolean => {
    if (!totalItems) {
        return false;
    }
    const hasEnoughItems = totalItems >= MINIMUM_NUMBER_OF_ITEMS;
    return hasEnoughItems && !orderMode;
};

export const cleanup = () => {
    const item = localStorage.getItem(NOTIFICATION_KEY);
    if (item && Number(item) < Date.now()) {
        localStorage.removeItem(NOTIFICATION_KEY);
    }
};

export const getHasBeenClosedFromLocalStorage = (): boolean => {
    return !!localStorage.getItem(NOTIFICATION_KEY);
};

const setHiddenInLocalStorage = (): void => {
    try {
        const futureDate = Date.now() + WEEK_IN_MILLISECONDS;
        localStorage.setItem(NOTIFICATION_KEY, `${futureDate}`);
    } catch (_) {
        return;
    }
};

export const tooltipNotificationLocalStorage = () => ({
    cleanup,
    hasBeenClosed: getHasBeenClosedFromLocalStorage,
    setHidden: setHiddenInLocalStorage,
});
