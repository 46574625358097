import type { NavigationLink } from '@interfaces/navigation';
import { Country } from '@royalaholddelhaize/ah-web-core';

import { links as linksBE } from '../constants/navigation/links/nl-BE';
import { links as linksNL } from '../constants/navigation/links/nl-NL';

export const getNavigationLinks = (country: Country): NavigationLink[] => {
    if (country === Country.NLD) {
        return linksNL;
    }

    return linksBE;
};
