import type { TypePolicies } from '@apollo/client';

export const recipeCollectionCategoryTypePolicy: TypePolicies = {
    RecipeCollectionCategory: {
        fields: {
            list: {
                merge(_, incoming) {
                    return incoming;
                },
            },
            recipes: {
                merge(_, incoming) {
                    return incoming;
                },
            },
        },
    },
};
