import type { Locale } from '@royalaholddelhaize/ah-web-core';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import { createTranslate } from '../../../i18n/translation';
import {
    type ProfileTranslationKey,
    profileTranslations,
} from '../../i18n/translation';
import type { MenuItem as MenuItemType } from '../../interfaces/profile-menu';
import { SubMenuItem } from './elements/sub-menu-item/sub-menu-item';
import css from './sub-menu.module.scss';

export interface SubMenuProps {
    locale: Locale;
    subMenu: MenuItemType;
    featureFlags: Record<string, string>;
}

export const SubMenu: FC<SubMenuProps> = ({
    locale,
    subMenu,
    featureFlags,
}) => {
    const t = createTranslate(locale, profileTranslations);

    if (!subMenu?.items) {
        return;
    }

    return (
        <>
            <Typography variant="heading-2" className={css.title}>
                {t(`menu.${subMenu.title}` as ProfileTranslationKey)}
            </Typography>
            {subMenu.items.map(subItem => (
                <SubMenuItem
                    key={`${subItem.url}-menu`}
                    locale={locale}
                    subItem={subItem}
                    featureFlags={featureFlags}
                />
            ))}
        </>
    );
};
