import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import type { Locale } from '@royalaholddelhaize/ah-web-core';
import { ChevronRight16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-right-16';
import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import type { FC } from 'react';
import { createMenuAnchor } from '../../../../../helper/menu-anchor.helper';
import { createTranslate } from '../../../../../i18n/translation';
import {
    type ProfileTranslationKey,
    profileTranslations,
} from '../../../../i18n/translation';
import type { MenuItem as MenuItemType } from '../../../../interfaces/profile-menu';

import css from './sub-menu-item.module.scss';

interface SubMenuItemProps {
    locale: Locale;
    subItem: MenuItemType;
    featureFlags: Record<string, string>;
}

export const SubMenuItem: FC<SubMenuItemProps> = ({
    locale,
    subItem,
    featureFlags,
}) => {
    const t = createTranslate(locale, profileTranslations);
    const Icon = subItem.icon;
    const title = t(`menu.${subItem.title}` as ProfileTranslationKey);

    const showFlag = subItem.featureFlag
        ? !!featureFlags[`x-variant-${subItem.featureFlag}`]
        : true;

    if (!showFlag) {
        return null;
    }

    return (
        <LinkAnchor
            key={title}
            className={css.root}
            href={subItem.items ? createMenuAnchor(title) : subItem.url || ''}
            {...createTestHook(`sub-menu-item-${title.toLowerCase()}`)}
        >
            <div className={css.content}>
                {Icon && <Icon />}
                <div className={css.text}>
                    <LinkText>{title}</LinkText>
                    {subItem?.subTitle && (
                        <LinkText className={css.subTitle}>
                            {t(
                                `menu.${subItem.subTitle}` as ProfileTranslationKey,
                            )}
                        </LinkText>
                    )}
                </div>
            </div>
            <ChevronRight16Icon />
        </LinkAnchor>
    );
};
