export enum ConsentStatus {
    ACCEPTED = 'accepted',
    PARTIAL = 'partial',
    DENIED = 'denied',
}

export interface CookieConsent {
    consentDate: string;
    consentStatus: ConsentStatus;
    consentHost: string | undefined;
}

const getCookieValue = (name: string): string | null => {
    if (typeof document === 'undefined' || !document.cookie) {
        return null;
    }

    const cookieRegex = new RegExp(`(^| )${name}=([^;]+)`);
    const match = document.cookie.match(cookieRegex);

    if (match) {
        const consent = match[2];
        try {
            // Attempt to decode the base64 string
            return atob(consent);
        } catch (_e) {
            return match[2]; // Return the original value, in case it's not a base64 string
        }
    }

    return '';
};
export function getCookie(cookieName = 'consentBeta'): CookieConsent | null {
    const cookieValue = getCookieValue(cookieName);

    if (cookieValue !== null) {
        try {
            const decodedCookieValue = decodeURIComponent(cookieValue);
            return JSON.parse(decodedCookieValue);
        } catch (error) {
            console.error('Error decoding or parsing the string:', error);
        }
    }

    return null;
}

export function userHasAcceptedCookies(cookieName: string): boolean {
    const userAcceptedVersion: CookieConsent | null = getCookie(cookieName);

    return userAcceptedVersion?.consentStatus === ConsentStatus.ACCEPTED;
}

/**
 * We can enable monitoring for this user
 */
export const appCanUseRUM = (cookieName = 'consentBeta'): boolean =>
    userHasAcceptedCookies(cookieName);
