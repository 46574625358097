'use client';

import { faro } from '@grafana/faro-web-sdk';
import { initializeObservability } from '@royalaholddelhaize/ah-observability';
import type { FaroOptions } from '@royalaholddelhaize/ah-observability/src/faro/initialize';
import type { FC } from 'react';

export const FaroObservability: FC<{ options?: FaroOptions }> = ({
    options,
}) => {
    try {
        // Skip if faro has been initialized already
        if (!faro?.api) {
            initializeObservability({
                appName: process.env.NEXT_PUBLIC_CLIENT || 'unknown',
                appVersion: process.env.NEXT_PUBLIC_VERSION || 'unknown',
                options,
            });
        }
    } catch {}

    return null;
};
