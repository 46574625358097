import {
    Accordion,
    AccordionTitle,
} from '@royalaholddelhaize/design-system-pantry-web/components/accordion/accordion';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { PropsWithChildren } from 'react';
import css from './accordion-wrapper.module.scss';

export interface AccordionProps extends PropsWithChildren {
    title: string;
}

export const AccordionWrapper = ({ children, title }: AccordionProps) => {
    return (
        <>
            <Accordion className={css.accordion}>
                <AccordionTitle className={css.title}>{title}</AccordionTitle>
                <menu className={css.list}>{children}</menu>
            </Accordion>
            <div className={css.desktopList}>
                <Typography variant="heading-3">{title}</Typography>
                <menu className={css.list}>{children}</menu>
            </div>
        </>
    );
};
