import { useEffect } from 'react';
import {
    OptInIds,
    type UserConsentItems,
} from '../../../graphql/member/member.hook';

const optInBlackListPages = ['/cookiebeleid', '/privacy', '/mijn/dashboard'];

export const useJoiningForcesOptInEvent = (toggleModal: () => void) => {
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        window.addEventListener('toggle-optin-consent', toggleModal);

        return () => {
            window.removeEventListener('toggle-optin-consent', toggleModal);
        };
    }, []);
};

const isPageAllowed = () => {
    if (typeof window !== 'undefined') {
        const { pathname } = window.location;
        return !optInBlackListPages.some(page => pathname.includes(page));
    }
    return true;
};

export const useOpenModalOnPageLoad = (
    userHasSubmittedOptIn: boolean,
    openModal: () => void,
    shouldRenderModal: boolean,
) => {
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        let cookieConsentSubmitted = false;

        if (typeof document !== 'undefined') {
            cookieConsentSubmitted =
                document?.cookie?.includes('cookie-consent');
        }
        if (
            isPageAllowed() &&
            !userHasSubmittedOptIn &&
            cookieConsentSubmitted &&
            shouldRenderModal
        ) {
            openModal();
        }
    }, [userHasSubmittedOptIn, shouldRenderModal]);
};

export const useInitialOptInData = (
    consents: UserConsentItems | null,
    setOptInsChecked: React.Dispatch<
        React.SetStateAction<{ [key: string]: boolean }>
    >,
) => {
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const dataUsageConsent = !!consents?.items?.find(
            consent => consent.code === OptInIds.DataUsage,
        )?.granted;

        const dataSharingConsent = !!consents?.items?.find(
            consent => consent.code === OptInIds.DataSharing,
        )?.granted;

        setOptInsChecked({
            [OptInIds.DataUsage]: dataUsageConsent,
            [OptInIds.DataSharing]: dataSharingConsent,
        });
    }, [consents?.items?.length]);
};
