import { AllerhandeRoute } from '@enums/route';
import type { NavigationLink } from '@interfaces/navigation';
import { links as beLinks } from './nl-BE';

export const links: NavigationLink[] = [
    ...beLinks,
    {
        href: AllerhandeRoute.Kookschrift,
        label: 'navigation:link.kookschrift',
        paths: [AllerhandeRoute.Kookschrift],
    },
];
