import { Spinner } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/spinner/spinner';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import type React from 'react';
import type { ReactNode } from 'react';
import css from './panel-body.module.scss';

export interface PanelBodyProps {
    children?: ReactNode;
    className?: string;
    loading?: boolean;
    loadingText?: string;
    noResultsText?: string;
}

export const PanelBody: React.FC<PanelBodyProps> = ({
    className,
    loading = false,
    loadingText = 'Bezig met laden.',
    noResultsText = 'Geen resultaten gevonden.',
    children,
}) => (
    <div className={classNames(css.panelBody, className)}>
        {loading ? (
            <div className={css.spinnerWrapper}>
                <Spinner size={36} />
                <Typography
                    variant="body-regular"
                    align="center"
                    className={css.spinnerHelpText}
                >
                    {loadingText}
                </Typography>
            </div>
        ) : (
            children || (
                <Typography
                    variant="body-regular"
                    align="center"
                    className={css.noResults}
                >
                    {noResultsText}
                </Typography>
            )
        )}
    </div>
);
