'use client';

import { useContext } from 'react';
import type { FeatureFlagsData } from '../types';
import { FeatureFlagsContext } from './context';

export const useFeatureFlags = (): FeatureFlagsData => {
    const context = useContext(FeatureFlagsContext);

    if (context === null) {
        throw new Error(
            'useFeatureFlags must be used within a FeatureFlagsProvider',
        );
    }

    return context;
};
