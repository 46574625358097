import { useEffect, useState } from 'react';
import { ScrollDirection, useScroll } from './use-scroll';

export type HeaderInteractionHook = (offset?: number) => {
    isVisible: boolean;
    direction: ScrollDirection;
};

export const useHeaderInteraction: HeaderInteractionHook = (offset = 0) => {
    const { direction, yOffset, velocity } = useScroll();

    const HEADER_HEIGHT = 132;

    const [scrollOffset, setScrollOffset] = useState(offset);
    const [isVisible, setIsVisible] = useState(true);
    const [isHideSearch, setHideSearch] = useState(false);

    useEffect(() => {
        const handleHideSearch = ((event: CustomEvent) => {
            const value = !!event?.detail?.value;
            setHideSearch(value);
        }) as EventListener;

        window.addEventListener('hideSearch', handleHideSearch);

        return () => window.removeEventListener('hideSearch', handleHideSearch);
    }, []);

    useEffect(() => {
        if (isHideSearch) {
            setIsVisible(false);
        } else if (
            direction === ScrollDirection.DOWN &&
            yOffset > HEADER_HEIGHT &&
            yOffset - scrollOffset > 10 &&
            isVisible
        ) {
            setIsVisible(false);
        } else if (
            direction === ScrollDirection.UP &&
            (yOffset < HEADER_HEIGHT || velocity > 20) &&
            !isVisible
        ) {
            setIsVisible(true);
        }

        if (scrollOffset !== yOffset) {
            setScrollOffset(yOffset);
        }
    }, [scrollOffset, isVisible, direction, yOffset, velocity, isHideSearch]);

    return { isVisible, direction };
};
