import {
    ArrowIn24Icon,
    CustomerService24Icon,
    RecentSearch24Icon,
    Recipes24Icon,
    Search24Icon,
} from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import type { SvgIconProps } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/svg-icon.interfaces';
import type { JSXElementConstructor } from 'react';
import type {
    SuggestionFragment,
    TopSuggestionFragment,
} from '../../../../graphql/_generated-operation-types';
import { SearchSection } from '../../../enums/search';
import type { Suggestion } from '../elements/suggestions/elements/suggestion/suggestion.interface';

export const getSection = ({ label }: SuggestionFragment): SearchSection => {
    switch (label) {
        case 'Recepten':
            return SearchSection.RECIPES;
        case 'Klantenservice':
            return SearchSection.CUSTOMER_CARE;
        default:
            return SearchSection.PRODUCTS;
    }
};

export const getIcon = ({
    icon,
}: SuggestionFragment): JSXElementConstructor<SvgIconProps> => {
    switch (icon) {
        case 'icon-recepten':
            return Recipes24Icon;
        case 'icon-customerservice':
            return CustomerService24Icon;
        case 'redirect':
            return ArrowIn24Icon;
        case 'icon-eerdergezocht':
            return RecentSearch24Icon;
        default:
            return Search24Icon;
    }
};

export const normalizeSuggestion = (
    suggestion: TopSuggestionFragment,
    index: number,
): Suggestion => {
    const sectionPosition = index + 1;
    const section = getSection(suggestion);
    const { suggestions: rawSubSuggestions, ...rest } = suggestion;
    const suggestions = rawSubSuggestions?.map((subSuggestion, subIndex) => ({
        ...subSuggestion,
        icon: getIcon(subSuggestion),
        id: `${sectionPosition}-${subIndex + 1}`,
        suggestions: undefined,
        section,
    }));
    return {
        ...rest,
        icon: getIcon(suggestion),
        id: sectionPosition.toString(),
        suggestions: suggestions,
        section,
    };
};

export const prioritizeSuggestion = (
    suggestions: Suggestion[],
    prioritySection: SearchSection,
): Suggestion[] => {
    return suggestions.sort((a, b) => {
        if (a.section === prioritySection && b.section !== prioritySection) {
            return -1;
        }

        if (a.section !== prioritySection && b.section === prioritySection) {
            return 1;
        }

        return 0;
    });
};

export const normalizeSuggestions = (
    rawSuggestions: TopSuggestionFragment[],
    searchDefaultSection: SearchSection,
): Suggestion[] => {
    const suggestions = rawSuggestions.map((section, index) =>
        normalizeSuggestion(section, index),
    );
    if (typeof searchDefaultSection === 'string') {
        return prioritizeSuggestion(suggestions, searchDefaultSection);
    }

    return suggestions;
};
