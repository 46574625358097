import keyCode from 'keycode';
import type React from 'react';
import { type FC, type PropsWithChildren, useState } from 'react';
import { createTranslate } from '../../../i18n/translation';
import { useHeaderInteraction } from '../../hooks/use-header-interaction';
import { headerTranslations } from '../../i18n/translation';
import type { MenuItem } from '../../interfaces/menu';
import type { HeaderSupportedLocales } from '../../interfaces/supported-locales';
import { HeaderButton } from './elements/header-button/header-button';
import { MegaMenuOverlay } from './elements/mega-menu-overlay/mega-menu-overlay';

export interface MegaMenuProps extends PropsWithChildren {
    item: MenuItem;
    withDeliveryNotification: boolean;
    locale: HeaderSupportedLocales;
}

export const MegaMenu: FC<MegaMenuProps> = ({
    item,
    withDeliveryNotification,
    children,
    locale,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { isVisible } = useHeaderInteraction();

    const t = createTranslate(locale, headerTranslations);

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (keyCode(event as unknown as Event) === 'enter') {
            setIsOpen(state => !state);
        }
    };

    const onOpen = () => {
        setIsOpen(true);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <HeaderButton
            href={item.href}
            title={t(item.label)}
            testHook={item.testHook}
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            isOpen={isOpen && isVisible}
            handleOnKeyDown={handleKeyDown}
        >
            <MegaMenuOverlay
                isOpen={isOpen && isVisible}
                onClose={onClose}
                withDeliveryNotification={withDeliveryNotification}
            >
                {children}
            </MegaMenuOverlay>
        </HeaderButton>
    );
};
