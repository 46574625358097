export const POPULAR_SEARCH_TERMS = [
    'Melk',
    'Eieren',
    'Brood',
    'Kaas',
    'Kipfilet',
    'Paprika',
    'Komkommer',
    'Chips',
    'Tomaten',
    'Yoghurt',
];

/** Prefix to be used with 'popular term' suggestion IDs */
export const POPULAR_TERM_PREF = 'popular-term-suggestions';

/** Prefix to be used with 'popular customer care term' suggestion IDs */
export const POPULAR_CUSTOMER_CARE_TERM_PREF =
    'popular-customer-care-term-suggestions';

/** Prefix to be used with 'previously searched' suggestion IDs */
export const PREVIOUS_ID_PREF = 'suggestions-previous';
