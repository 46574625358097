import { AH_UI_NAVIGATION_TESTHOOKS } from '@royalaholddelhaize/ah-test-hooks';
import { OrderOutlined24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/order-outlined-24';
import type React from 'react';
import { useBasket } from '../../../../../graphql/basket/basket.hook';
import { useNavigationHeader } from '../../../../hooks/navigation-header.hook';
import { MenuItemComponent } from '../../../menu-item-component/menu-item-component';
import type { StackPaneProps } from '../../elements/stack/interfaces/stack';
import { NavPanelProfile } from '../profile/nav-panel-profile';

import css from '../nav-panel-pane.module.scss';

export const NavPanelRoot = ({
    openPane,
    apollo,
    featureOptions,
    domain,
    locale,
}: StackPaneProps<void>) => {
    const { menuItems, links } = useNavigationHeader(domain);
    const { orderMode } = useBasket(apollo.initialised);

    const onProfileClick = (event: React.SyntheticEvent<HTMLElement>) => {
        event.preventDefault();
        openPane(event as unknown as React.MouseEvent, {
            component: NavPanelProfile,
            props: {
                featureOptions,
                domain,
                locale,
            },
        });
    };

    return (
        <div className={css.pane}>
            <menu className={css.menuList}>
                {menuItems.mainMenu.map(item => (
                    <MenuItemComponent
                        featureOptions={featureOptions}
                        item={item}
                        key={item.id}
                        className="compactMenuItem"
                        foldoutVariant
                        locale={locale}
                    />
                ))}
            </menu>
            <menu className={css.menuList}>
                <MenuItemComponent
                    featureOptions={featureOptions}
                    item={{
                        ...links.profile,
                        hasSubNav: true,
                        testHook: AH_UI_NAVIGATION_TESTHOOKS.HEADER.profile,
                    }}
                    onClick={onProfileClick}
                    className="compactMenuItem"
                    foldoutVariant
                    locale={locale}
                />
            </menu>
            <menu className={css.menuList}>
                {!orderMode && (
                    <MenuItemComponent
                        featureOptions={featureOptions}
                        item={{
                            ...menuItems.currentOrderLink[0],
                            Icon: OrderOutlined24Icon,
                        }}
                        className="compactMenuItem"
                        foldoutVariant
                        locale={locale}
                    />
                )}
                {menuItems.topMenu.map(item => (
                    <MenuItemComponent
                        featureOptions={featureOptions}
                        item={item}
                        key={item.id}
                        className="compactMenuItem"
                        foldoutVariant
                        locale={locale}
                    />
                ))}
            </menu>
            <menu className={css.menuList}>
                {menuItems.secondaryMenu.map(item => (
                    <MenuItemComponent
                        featureOptions={featureOptions}
                        item={item}
                        key={item.id}
                        className="compactMenuItem"
                        foldoutVariant
                        locale={locale}
                    />
                ))}
            </menu>
        </div>
    );
};
