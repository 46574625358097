'use client';

import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import type { FC } from 'react';
import { createTranslate } from '../../../i18n/translation';
import type { ContentItem } from '../../content/content.types';
import { footerTranslations } from '../../i18n/translation';
import type { FooterSupportedLocales } from '../../interfaces/supported-locales';
import styles from './menu.module.scss';

interface MenuLegalProps {
    locale: FooterSupportedLocales;
    content: ContentItem[];
}

export const MenuLegal: FC<MenuLegalProps> = ({ locale, content }) => {
    const t = createTranslate(locale, footerTranslations);

    return (
        <menu>
            {content.map(({ href, label }) => (
                <li key={href}>
                    <LinkAnchor className={styles.link} href={href}>
                        <LinkText
                            level="secondary"
                            className={styles.alignCenter}
                        >
                            {t(label)}
                        </LinkText>
                    </LinkAnchor>
                </li>
            ))}
        </menu>
    );
};
