import type { Patterns } from '@grafana/faro-core/dist/types/config/types';
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-react';
import { appCanUseRUM } from '../helpers/check-cookie';

const faroCollector =
    'https://faro-collector-prod-eu-west-2.grafana.net/collect';
const defaultUrls: Record<string, string> = {
    tst: `${faroCollector}/45508a70985bb5e627f9154770a05623`,
    acc: `${faroCollector}/e996c37b769b1b39e0e8c929eb6edb6f`,
    prd: `${faroCollector}/4a12df41aca8bb2d8515ded5c146b7c1`,
};

export interface FaroOptions {
    ignoreErrors?: Patterns;
}

interface ObservabilityConfig {
    appName: string;
    appVersion: string;
    url?: string;
    options?: FaroOptions;
}

export const initializeObservability = ({
    appName,
    appVersion,
    url,
    options = {},
}: ObservabilityConfig) => {
    if (typeof window === 'undefined') {
        return;
    }

    const hostname = window.location.hostname;

    if (
        hostname.includes('localhost') ||
        hostname.includes('127.0.0.1') ||
        hostname.includes('local.ah.')
    ) {
        console.warn('Observability is disabled in local environment');
        return;
    }

    const env = hostname.includes('tst')
        ? 'tst'
        : hostname.includes('acc')
          ? 'acc'
          : 'prd';

    const finalUrl = url || defaultUrls[env];

    if (!finalUrl) {
        return;
    }

    try {
        if (appCanUseRUM()) {
            initializeFaro({
                app: {
                    name: appName,
                    version: appVersion,
                },
                url: finalUrl,
                instrumentations: [...getWebInstrumentations()],
                ...options,
            });
            console.log('Observability initialization was successful');
        }
    } catch (e) {
        console.error('Observability initialization failed', e);
    }
};
