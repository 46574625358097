import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import type { SvgIconProps } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/svg-icon.interfaces';
import {
    LinkAnchor,
    LinkButton,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import classnames from 'clsx';
import type React from 'react';
import type { FC, MouseEvent, PropsWithChildren } from 'react';
import css from './icon-button.module.scss';

export enum IconButtonVariant {
    /**
     * Default IconButton
     */
    DEFAULT = 'DEFAULT',
    /**
     * Outlined IconButton
     */
    OUTLINE = 'OUTLINE',
}

export interface IconButtonProps {
    /**
     * Icon to be displayed within the button
     */
    Icon: React.JSXElementConstructor<SvgIconProps>;
    /**
     * Optional value to determine the active state of the button
     */
    active?: boolean;
    /**
     * Optional string to be displayed as label of the button
     */
    label?: string;
    /**
     * Optional classname for styling
     */
    className?: string;
    /**
     * Optional value to determine the variant of the button
     */
    variant?: IconButtonVariant;
    /**
     * Optional boolean to disable the padding on the button
     */
    disableExtraPadding?: boolean;
    /**
     * Optional link to be passed down to the button
     */
    href?: string;
    /**
     * Optional testhook-id to be passed down to the button
     */
    testHook?: string;
    /**
     * Optional function to be passed down to the onClick action of the button
     * @param event SyntheticEvent of the executed function
     */
    onClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
}

export const IconButton: FC<PropsWithChildren<IconButtonProps>> = ({
    Icon,
    className,
    label,
    active,
    children,
    variant,
    testHook,
    disableExtraPadding = false,
    onClick,
    href,
    ...props
}) => {
    const classes = classnames(
        css.root,
        (label || !!children) && !disableExtraPadding && css.hasContent,
        variant && css[variant.toLowerCase() as keyof typeof css],
        active && css.active,
        className,
    );

    const LinkComponent = href ? LinkAnchor : LinkButton;

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        if (onClick) {
            onClick(event);
        }
    };

    return (
        <LinkComponent
            className={classes}
            onClick={handleClick}
            href={href}
            {...createTestHook(testHook)}
            {...props}
        >
            <Icon className={css.icon} size={24} />
            {label && (
                <LinkText className={css.text} level="secondary">
                    {label}
                </LinkText>
            )}
            {children}
        </LinkComponent>
    );
};
