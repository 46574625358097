import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import { Fragment, type ReactNode } from 'react';

export const highlightQuery = (label: string, query: string) => {
    const cleanQuery = query.replace(/[^\w\s]/g, '');
    const splitLabel = label.split(new RegExp(`(${cleanQuery})`));
    const convertedLabel = splitLabel.map(labelPart => {
        if (labelPart.toLowerCase() === cleanQuery.toLowerCase()) {
            return <Fragment key={labelPart}>{cleanQuery}</Fragment>;
        }

        const cleanLabelPart = labelPart.replace(' ', '');
        if (labelPart.startsWith(' ')) {
            return <strong key={labelPart}>&nbsp;{cleanLabelPart}</strong>;
        }

        if (labelPart.endsWith(' ')) {
            return <strong key={labelPart}>{cleanLabelPart}&nbsp;</strong>;
        }

        if (labelPart !== '') {
            return <strong key={labelPart}>{labelPart}</strong>;
        }

        return null;
    });

    return (
        <Typography>
            {convertedLabel.map((item, index) => {
                return (
                    <Fragment key={index.toString()}>
                        {item as ReactNode}
                    </Fragment>
                );
            })}
        </Typography>
    );
};
