import type { ApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useBasket } from '../../../graphql/basket/basket.hook';
import {
    shouldShowNotification,
    tooltipNotificationLocalStorage,
} from './tooltip-notification.helpers';

export interface TooltipNotificationHook {
    isVisible: boolean;
    handleHide: () => void;
}

export const useTooltipNotification = (apollo: {
    initialised: boolean;
    ssrClient?: ApolloClient<unknown>;
}): TooltipNotificationHook => {
    const { orderMode, totalItems } = useBasket(apollo.initialised);
    const [isVisible, setIsVisible] = useState(false);
    const { cleanup, hasBeenClosed, setHidden } =
        tooltipNotificationLocalStorage();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        cleanup();

        if (hasBeenClosed()) {
            return;
        }
        const showNotification = shouldShowNotification(
            totalItems,
            !!orderMode,
        );
        setIsVisible(showNotification);
    }, [totalItems, orderMode, cleanup, hasBeenClosed, setIsVisible]);

    const handleHide = () => {
        setHidden();
        setIsVisible(false);
    };

    return {
        isVisible,
        handleHide,
    };
};
