import {
    AH_UI_NAVIGATION_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import { ChevronDown16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-down-16';
import {
    LinkButton,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import classNames from 'clsx';
import keyCode from 'keycode';
import type React from 'react';
import {
    type FC,
    type PropsWithChildren,
    type ReactElement,
    useState,
} from 'react';
import { KeydownHandler } from '../search/elements/keydown-handler/keydown-handler';
import styles from './menu-dropdown.module.scss';

export interface CustomButtonProps {
    /**
     * Sets the active state on the button
     */
    active?: boolean;
}

export interface MenuDropdownProps {
    /**
     * Sets the text on the dropdown button
     */
    label: string;
    /**
     * Sets the aria label text
     */
    a11yLabel: string;
    /**
     * Returns the given button instead of the one from the MenuDropdown component
     * @param props from the given button
     * @constructor handled by the component
     */
    CustomButton?: (props: CustomButtonProps) => ReactElement;
    /**
     * Custom classname for styling
     */
    className?: string;
    isUser?: boolean;
}

export const MenuDropdown: FC<PropsWithChildren<MenuDropdownProps>> = ({
    a11yLabel,
    label,
    CustomButton,
    className,
    children,
    isUser = false,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
        if (keyCode(event as unknown as Event) === 'enter') {
            setIsOpen(state => !state);
        }
    };

    const open = () => {
        setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
    };

    const renderButton = () => {
        const buttonProps = {
            onKeyDown: handleKeyDown,
            tabIndex: 0,
            'aria-label': a11yLabel,
            'aria-expanded': isOpen,
        };

        if (CustomButton) {
            return <CustomButton {...buttonProps} active={isOpen} />;
        }

        return (
            <LinkButton
                className={classNames(
                    styles.menuDropdownButton,
                    isUser && styles.menuDropdownUser,
                )}
                {...buttonProps}
            >
                <LinkText
                    className={styles.menuDropdownButtonText}
                    level="secondary"
                >
                    {label}
                </LinkText>
                <ChevronDown16Icon
                    size={16}
                    className={classNames(
                        styles.menuDropdownIcon,
                        isOpen && styles.menuDropdownIconActive,
                    )}
                />
            </LinkButton>
        );
    };

    return (
        <li
            className={classNames(
                className && styles[className as keyof typeof styles],
                styles.menuDropdown,
                isOpen && styles.menuDropdownOpen,
            )}
            onMouseEnter={open}
            onMouseLeave={close}
            {...createTestHook(
                AH_UI_NAVIGATION_TESTHOOKS.HEADER.menuDropdownButton,
            )}
        >
            {renderButton()}
            <KeydownHandler handler={close} keyCodes={['esc']}>
                <div
                    className={styles.menuDropdownMountPoint}
                    aria-hidden={!isOpen}
                >
                    <menu className={styles.menuDropdownList}>{children}</menu>
                </div>
            </KeydownHandler>
        </li>
    );
};
