import type { TranslationFunction } from '../../i18n/translation';
import type {
    ProfileTranslationFile,
    ProfileTranslationKey,
} from '../i18n/translation';
import type {
    MenuItem as MenuItemType,
    ProfileMenuType,
} from '../interfaces/profile-menu';

export interface BreadcrumbItem {
    url: string;
    title: string;
    parent: string;
}

export const checkIfPageIsOutsideMenu = (
    menuConfig: ProfileMenuType,
    url: string,
): BreadcrumbItem | undefined => {
    let parent: BreadcrumbItem | undefined = undefined;
    menuConfig.breadcrumbs.forEach(item => {
        if (item.url === url) {
            parent = item;
        }
    });

    return parent;
};
export const breadcrumbBuilder = (
    t: TranslationFunction<ProfileTranslationFile>,
    menuConfig: ProfileMenuType,
    url: string,
    anchor: string,
): MenuItemType[] => {
    let result: MenuItemType[] = [];

    menuConfig.menu.forEach(item => {
        const location = anchor || url;
        if (item.url === location) {
            result = [item];
        }
        // Check if menu has sub items
        if (item.items) {
            const resp = searchInSub(t, item.items, [item], location);
            if (resp.found) {
                result = resp.path;
            }
        }
    });

    return result;
};

const searchInSub = (
    t: TranslationFunction<ProfileTranslationFile>,
    items: MenuItemType[],
    parent: MenuItemType[],
    location: string,
): { found: boolean; path: MenuItemType[] } => {
    let result: { found: boolean; path: MenuItemType[] } = {
        found: false,
        path: [],
    };

    items.forEach(item => {
        const title = t(item.title as ProfileTranslationKey);
        const titleAsAnchorTag = title.replace(/\s/g, '_');
        if (titleAsAnchorTag === location || item.url === location) {
            result = { found: true, path: [...parent, item] };
        } else if (item.items) {
            const resp = searchInSub(
                t,
                item.items,
                [...parent, item],
                location,
            );
            if (resp.found) {
                result = resp;
            }
        }
    });

    return result;
};
