'use client';

import { type Banner, bannerMapper } from '@royalaholddelhaize/ah-web-core';
import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import { createTranslate } from '../i18n/translation';
import { footerLinksHelper } from './helpers/footer-links.helper';
import { footerTranslations } from './i18n/translation';
import type { FooterSupportedLocales } from './interfaces/supported-locales';

import css from './footer-focused.module.scss';

export interface FooterFocusedProps {
    banner: Banner;
    locale: FooterSupportedLocales;
}

export const FooterFocused = ({ banner, locale }: FooterFocusedProps) => {
    const t = createTranslate(locale, footerTranslations);

    const { terms, privacy, cookies } = footerLinksHelper(banner);

    return (
        <div className={css.root}>
            <Typography className={css.text} variant="body-strong">
                {`© ${new Date().getFullYear()} ${bannerMapper[banner]}`}
            </Typography>

            <div className={css.links}>
                <LinkAnchor className={css.link} href={terms}>
                    <LinkText level="secondary">{t('legal.terms')}</LinkText>
                </LinkAnchor>
                <LinkAnchor className={css.link} href={privacy}>
                    <LinkText level="secondary">{t('legal.privacy')}</LinkText>
                </LinkAnchor>
                <LinkAnchor className={css.link} href={cookies}>
                    <LinkText level="secondary">{t('legal.cookies')}</LinkText>
                </LinkAnchor>
            </div>
        </div>
    );
};
