'use client';

import type { FC, PropsWithChildren } from 'react';
import type { FeatureExperimentsData } from '../types';
import { FeatureExperimentsContext } from './context';

interface FeatureExperimentsProviderProps extends PropsWithChildren {
    data: FeatureExperimentsData;
}

export const FeatureExperimentsProvider: FC<
    FeatureExperimentsProviderProps
> = ({ data, children }) => {
    return (
        <FeatureExperimentsContext.Provider value={data}>
            {children}
        </FeatureExperimentsContext.Provider>
    );
};
