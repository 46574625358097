'use client';

import { type FC, useEffect } from 'react';
import { isBrowser } from '../../utils/is-browser';

export const AHHiring: FC = () => {
    useEffect(() => {
        if (isBrowser() && !window.hasLoggedHiringMessage) {
            console.log(`
\x1b[34m
                            (((
                        ((((((((((
                    (((((((((((((((
               (((((((((((((((((((((
           (((((((((((((((((((((((((((
       (((((((((((((((((((((((((((((((
     (((((((((((((((((( ((((((((((((((((
     (((((        (((   (((        (((((((
    (((((    *(     (   (     (     (((((((
   ((((((   (((((          (((((    ((((((((
  (((((((   (((((((     ((((((((    ((((((((
  (((((((   (((((((     ((((((((    ((((((((
 ((((((((    ((((       ((((((((    ((((((((
(((((((((           (   ((((((((    (((((((
(((((((((((      .(((   ((((((((    ((((((
 (((((((((((((((((((((((((((((((((((((((((
         ((((((((((((((((((((((((((((((((
                 (((((((((((((((((((((((
                          (((((((((((((
                                 (((
\x1b[0m

We're Albert Heijn, food retail reinventors. 🚀
Apply now at https://werk.ah.nl/?utm_source=${window.location.host}&utm_medium=codelink&utm_campaign=easteregg
                \n\n`);
            window.hasLoggedHiringMessage = true;
        }
    }, []);

    return null;
};
