import { SearchAction } from '@royalaholddelhaize/ah-analytics';
import {
    POPULAR_TERM_PREF,
    PREVIOUS_ID_PREF,
} from '../elements/suggestions/constants';
import type { Suggestion } from '../elements/suggestions/elements/suggestion/suggestion.interface';

/**
 * Returns the search action based on the suggestion details.
 * @param suggestion
 */
export function getSearchAction(suggestion: Suggestion): SearchAction {
    if (suggestion?.id?.includes(POPULAR_TERM_PREF)) {
        return SearchAction.POPULAR;
    }
    if (suggestion?.id?.includes(PREVIOUS_ID_PREF)) {
        return SearchAction.PREVIOUS;
    }

    return SearchAction.SEARCH;
}
