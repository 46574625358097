import classNames from 'clsx';
import type React from 'react';
import type { FC, PropsWithChildren } from 'react';
import css from './switch.module.scss';

export interface NameValuePair {
    name: string;
    value: string;
}

export enum Checked {
    TRUE = 'TRUE',
    FALSE = 'FALSE',
}

export interface SwitchProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    className?: string;
    checked: boolean;
    onChange?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    onValueChanged?: (nameValuePair: NameValuePair) => void;
    tabIndex?: number;
    trackClassName?: string;
}

export const Switch: FC<PropsWithChildren<SwitchProps>> = ({
    name,
    onChange,
    checked = false,
    disabled,
    onValueChanged,
    tabIndex,
    children,
    className,
    trackClassName,
    ...restProps
}) => {
    const handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { checked } = event.target as HTMLInputElement;
        const changedValue = checked ? Checked.TRUE : Checked.FALSE;

        if (typeof onChange === 'function') {
            onChange(event);
        }

        if (typeof onValueChanged === 'function') {
            onValueChanged({ name, value: changedValue });
        }
    };

    return (
        <label className={classNames(css.root, className)} htmlFor={name}>
            <input
                id={name}
                name={name}
                disabled={disabled}
                checked={checked}
                type="checkbox"
                className={css.input}
                onChange={handleChange}
                tabIndex={tabIndex}
                data-testhook="input-switch"
                {...restProps}
            />
            <div className={classNames(css.track, trackClassName)}>
                <div className={css.thumb} />
            </div>
            {children}
        </label>
    );
};
