import { emitComponentEvent } from '@royalaholddelhaize/ah-analytics';
import { type Country, DEFAULT_COUNTRY } from '@royalaholddelhaize/ah-web-core';
import type React from 'react';
import { MemberLoginState } from '../../graphql/_generated-operation-types';
import { menuItemsConfig } from '../config/navigation-header-menu.config';
import { PATH } from '../constants/path';

import { MenuItemId } from '../enums/menu-item-id';
import type { MenuItem, MenuItems } from '../interfaces/menu';

export const getMenuItemById = (
    menuItems: MenuItems,
    id: MenuItemId,
): MenuItem => {
    return (
        Object.keys(menuItems)
            .reduce((acc, key) => {
                return [...acc, ...menuItems[key as keyof MenuItems]];
            }, [] as MenuItem[])
            .find(item => item.id === id) ?? ({} as MenuItem)
    );
};

export const isPublicMenuItem = (
    { href }: MenuItem,
    memberLoginState: MemberLoginState,
) => {
    if (!href) {
        return true;
    }

    const publicPaths = [PATH.deliveryBundle, PATH.premium];
    const returningPaths = [PATH.favorites];
    switch (memberLoginState) {
        case MemberLoginState.LOGGEDON:
            return true;
        case MemberLoginState.RETURNING:
            return [...publicPaths, ...returningPaths].includes(href);
        default:
            return publicPaths.includes(href);
    }
};

export const removePremiumLink = (menu: MenuItems): MenuItems => {
    return {
        ...menu,
        userMenu: menu.userMenu.filter(link => link.href !== PATH.premium),
    };
};

export const removeBusinessDeliveryBundle = (menu: MenuItems): MenuItems => {
    return {
        ...menu,
        userMenu: menu.userMenu.filter(
            link => link.href !== PATH.deliveryBundleBusiness,
        ),
    };
};

export const removeWinPromotions = (menu: MenuItems): MenuItems => {
    return {
        ...menu,
        topMenu: menu.topMenu.filter(
            link => link.id !== MenuItemId.SAVINGS_PROMOTIONS,
        ),
    };
};

export const removeDeliveryBundleLink = (menu: MenuItems): MenuItems => {
    return {
        ...menu,
        userMenu: menu.userMenu.filter(
            link => link.href !== PATH.deliveryBundle,
        ),
    };
};

export const goToLogin = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
    window.location.assign(
        `${PATH.login}?ref=${encodeURIComponent(
            window.location.pathname + window.location.search,
        )}`,
    );
};

const adjustMenuForBusiness = (items: MenuItems): MenuItems =>
    removeWinPromotions(removePremiumLink(removeDeliveryBundleLink(items)));

const adjustMenuForNonBusiness = (items: MenuItems): MenuItems =>
    removeBusinessDeliveryBundle(items);

export const selectMenu = (isBusiness: boolean, domain: Country) => {
    const menuItems =
        menuItemsConfig[domain] || menuItemsConfig[DEFAULT_COUNTRY];

    return isBusiness
        ? adjustMenuForBusiness(menuItems)
        : adjustMenuForNonBusiness(menuItems);
};

export const emitClickFavoriteIconCidEvent = () => {
    emitComponentEvent({
        componentAction: 'show favorite lists',
        component: {
            componentType: 'Navigation header',
            componentSubType: 'icon',
            componentInnerText: 'Favoriete lijstjes',
            componentSection: 'Lists & Orders - Navigation',
        },
    });
};
