import { LinkOrAnchor } from '@components/elements/link-or-anchor/link-or-anchor';
import type { NavigationLink } from '@interfaces/navigation';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import type { FC } from 'react';

import {
    type ComponentMeta,
    EventName,
    createAnalyticsHook,
} from '@royalaholddelhaize/ah-analytics';
import css from './navigation-menu-anchor.module.scss';

export interface NavigationMenuAnchorProps extends NavigationLink {
    onClick?: () => void;
}

export const NavigationMenuAnchor: FC<NavigationMenuAnchorProps> = ({
    href,
    isActive,
    label,
    onClick,
}) => {
    const cidComponent: ComponentMeta = {
        componentType: 'Navigation header',
        componentTitle: 'Allerhande navigation',
        componentSubType: 'link',
        componentInnerText: label,
        componentTargetUrl: href,
        componentSection: 'Allerhande',
    };

    return (
        <li key={href} className={css.anchorLiItem} role="presentation">
            <LinkOrAnchor
                className={classNames(css.anchor, isActive && css.activeAnchor)}
                aria-label={label}
                role="menuitem"
                title={label}
                href={href}
                onClick={onClick}
                {...createAnalyticsHook(
                    EventName.COMPONENT_INTERACTION,
                    cidComponent,
                )}
            >
                <Typography
                    className={css.anchorText}
                    variant="body-regular"
                    as="span"
                >
                    {label}
                </Typography>
            </LinkOrAnchor>
        </li>
    );
};
