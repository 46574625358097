import { ENTER_KEY, SPACE_KEY } from '@constants/keycode';
import { ChevronUpFilled16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-up-filled-16';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import {
    Children,
    type FC,
    type KeyboardEvent,
    type PropsWithChildren,
    type RefObject,
    cloneElement,
    isValidElement,
    useState,
} from 'react';

import { useOutsideClick } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-outside-click/use-outside-click';
import type { NavigationMenuAnchorProps } from '../navigation-menu-anchor/navigation-menu-anchor';
import css from './navigation-menu-mobile.module.scss';

export interface NavigationMenuMobileProps extends PropsWithChildren {
    label: string;
    ariaLabel: string;
    ref: RefObject<HTMLElement | null>;
}

export const NavigationMenuMobile: FC<NavigationMenuMobileProps> = ({
    label,
    ariaLabel,
    children,
    ref,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOnClick = () => setIsOpen(prev => !prev);

    const handleOnKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
        if (e.code === (ENTER_KEY || SPACE_KEY)) {
            setIsOpen(prev => !prev);
        }

        return true;
    };

    useOutsideClick({
        ref,
        callback: () => {
            if (isOpen) setIsOpen(false);
        },
    });

    return (
        <>
            <button
                onClick={handleOnClick}
                onKeyDown={handleOnKeyDown}
                className={css.overview}
                type="button"
                tabIndex={0}
                aria-label={ariaLabel}
            >
                <Typography
                    className={css.overviewText}
                    variant="body-regular"
                    as="span"
                >
                    {label}
                </Typography>

                <ChevronUpFilled16Icon
                    className={classNames(
                        css.icon,
                        !isOpen && css.iconCollapsed,
                    )}
                />
            </button>

            <menu
                ref={ref}
                className={classNames(
                    css.anchors,
                    !isOpen && css.anchorsCollapsed,
                )}
                role="menu"
            >
                {Children.toArray(children).map(
                    child =>
                        isValidElement<NavigationMenuAnchorProps>(child) &&
                        cloneElement(child, {
                            onClick: handleOnClick,
                        }),
                )}
            </menu>
        </>
    );
};
