import type { SvgIconProps } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/svg-icon.interfaces';
import classNames from 'clsx';
import type { FC } from 'react';
import { LoggedIn24Icon } from '../../../assets/logged-in';
import { LoggedOut24Icon } from '../../../assets/logged-out';
import { PremiumUser24Icon } from '../../../assets/premium-user';
import { UnknownUser24Icon } from '../../../assets/unknown-user';
import css from './user-icon.module.scss';

export enum UserIconType {
    LOADING,
    LOGGED_OUT,
    LOGGED_IN,
    PREMIUM,
}

export const UserIcon: (type: UserIconType) => FC<SvgIconProps> =
    type =>
    ({ className }) => {
        switch (type) {
            case UserIconType.LOGGED_OUT:
                return (
                    <LoggedOut24Icon
                        className={classNames(className, css.userIcon)}
                    />
                );
            case UserIconType.LOGGED_IN:
                return (
                    <LoggedIn24Icon
                        className={classNames(className, css.userIcon)}
                    />
                );
            case UserIconType.PREMIUM:
                return (
                    <PremiumUser24Icon
                        className={classNames(className, css.userIcon)}
                    />
                );
            case UserIconType.LOADING:
            default:
                return (
                    <UnknownUser24Icon
                        className={classNames(className, css.unknownIcon)}
                    />
                );
        }
    };
