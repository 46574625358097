import { RecentSearch24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/recent-search-24';
import { SearchSuggestionType } from '../../../../../../graphql/_generated-operation-types';
import { PATH } from '../../../../../constants/path';
import { SearchSection } from '../../../../../enums/search';
import type { Suggestion } from '../elements/suggestion/suggestion.interface';
import { sanitizeSuggestion } from './sanitize-suggestion';

type LocalSuggestion = [string, string[]];

export const LOCAL_SUGGESTIONS_KEY = 'localSuggestions';
export const MAX_LOCAL_SUGGESTIONS = 6;

export const getLocalSuggestions = (): LocalSuggestion[] => {
    try {
        const item = localStorage.getItem(LOCAL_SUGGESTIONS_KEY) || '';
        const suggestions = JSON.parse(item);

        if (!Array.isArray(suggestions)) {
            return [];
        }
        // Support old storage format.
        if (typeof suggestions[0] === 'string') {
            return suggestions.map(s => [s, []]);
        }

        return suggestions.map(([query, alternatives]) => [
            sanitizeSuggestion(query),
            alternatives.map((a: string) => sanitizeSuggestion(a)),
        ]);
    } catch {
        return [];
    }
};

export const addLocalSuggestion = (
    suggestion: string,
    alternatives: string[] = [],
): void => {
    try {
        if (!suggestion) {
            return;
        }

        const newSuggestions = [
            [suggestion, alternatives],
            ...getLocalSuggestions(),
        ];

        // Check for duplicates
        const suggestionMap = newSuggestions
            .reverse()
            .reduce((acc, [key, value]) => {
                if (acc.has(key)) {
                    acc.delete(key);
                }
                acc.set(key, value);
                return acc;
            }, new Map());

        localStorage.setItem(
            LOCAL_SUGGESTIONS_KEY,
            JSON.stringify(
                Array.from(suggestionMap)
                    .slice(-MAX_LOCAL_SUGGESTIONS)
                    .reverse(),
            ),
        );
    } catch (e) {
        console.warn(e);
    }
};

export const getPrevSearchSuggestions = (maxSuggestions = 3): Suggestion[] => {
    const localSuggestions = getLocalSuggestions();

    return [
        {
            id: 'suggestions-previous',
            label: 'suggestions.previous',
            value: 'suggestions.previous',
            href: PATH.search,
            type: SearchSuggestionType.SECTION,
            section: SearchSection.PRODUCTS,
            suggestions: localSuggestions
                .slice(0, maxSuggestions)
                .map(([query, alternatives]) => ({
                    id: `suggestions-previous-${query}-${JSON.stringify(
                        alternatives,
                    )}`,
                    label: query,
                    value: query,
                    href: `${PATH.search}?query=${encodeURIComponent(query)}`,
                    type: SearchSuggestionType.DEFAULT,
                    icon: RecentSearch24Icon,
                    section: SearchSection.PRODUCTS,
                    alternatives,
                })),
        },
    ];
};
