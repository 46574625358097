import { PATH } from '../../constants/path';
import type { HeaderTranslationKey } from '../../i18n/translation';

interface LinkItem {
    label: HeaderTranslationKey;
    href: string;
}

export const linksB2C: LinkItem[] = [
    {
        label: 'menu.promotions',
        href: PATH.campaigns,
    },
];

export const linksAllUsers: LinkItem[] = [
    {
        label: 'menu.shops',
        href: PATH.shops,
    },
    {
        label: 'menu.business',
        href: PATH.business,
    },
    {
        label: 'menu.customerService',
        href: PATH.customerService,
    },
];
