import { Banner } from '@royalaholddelhaize/ah-web-core';
import { Brand } from '@royalaholddelhaize/design-system-pantry-web/constants/brand.constants';

export const themeHelper = (company: Banner): Brand => {
    switch (company.toUpperCase()) {
        case Banner.ETOS:
            return Brand.ETOS;
        case Banner.GALL:
            return Brand.GALL;
        case Banner.AH:
        default:
            return Brand.AH;
    }
};
