import { Banner } from '@royalaholddelhaize/ah-web-core';

export interface FooterLinks {
    terms: string;
    privacy: string;
    cookies: string;
}

export const footerLinksHelper = (banner: Banner): FooterLinks => {
    switch (banner) {
        case Banner.ETOS:
            return {
                terms: 'https://www.etos.nl/algemene-voorwaarden.html',
                privacy: 'https://www.etos.nl/privacybeleid.html',
                cookies: 'https://www.etos.nl/cookie-page.html',
            };
        case Banner.GALL:
            return {
                terms: 'https://www.gall.nl/over/algemene-voorwaarden',
                privacy: 'https://www.gall.nl/over/privacy',
                cookies: 'https://www.gall.nl/over/cookiebeleid',
            };
        case Banner.AH:
        default:
            return {
                terms: 'https://www.ah.nl/algemene-voorwaarden',
                privacy: 'https://www.ah.nl/privacy',
                cookies: 'https://www.ah.nl/cookiebeleid',
            };
    }
};
