'use client';

import {
    Locale,
    localeMapper,
    setCookie,
} from '@royalaholddelhaize/ah-web-core';
import { type ChangeEvent, type FC, useEffect, useState } from 'react';

export const LocaleSelector: FC<{ currentLocale: Locale }> = ({
    currentLocale,
}) => {
    const [showSelector, setShowSelector] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setShowSelector(
                window.location.href.includes('tst.ah.nl') ||
                    window.location.href.includes('tst.ah.be') ||
                    window.location.href.includes('local'),
            );
        }
    }, []);

    if (!showSelector) {
        return null;
    }

    const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 10);

        setCookie({
            name: 'language-selection',
            value: e.target.value,
            expires,
            path: '/',
        });
        location.reload();
    };

    return (
        <select onChange={handleOnSelect} defaultValue={currentLocale}>
            {[Locale.nl_NL, Locale.nl_BE, Locale.en_US].map(locale => (
                <option key={locale} value={locale}>
                    {localeMapper[currentLocale][locale]}
                </option>
            ))}
        </select>
    );
};
