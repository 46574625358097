import { Country, type CountryMap } from '@royalaholddelhaize/ah-web-core';
import type { MegaMenuLinks } from '../../../../../../../graphql/content/mega-menu/mega-menu-links.hook';

export const channelMap: CountryMap<string> = {
    [Country.NLD]: 'allerhande-menu-nl',
    [Country.BEL]: 'allerhande-menu-be',
};

const defaultLinks: MegaMenuLinks[] = [
    {
        link: '',
        target: '',
        title: '',
    },
];

export const defaultLinkGroup = [
    {
        links: defaultLinks,
        subject: '',
    },
];
