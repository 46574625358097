'use client';

import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { ChevronRight16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-right-16';
import {
    LinkAnchor,
    LinkButton,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import classNames from 'clsx';
import type React from 'react';
import { createTranslate } from '../../../i18n/translation';
import { MenuItemId } from '../../enums/menu-item-id';
import {
    type HeaderTranslationKey,
    headerTranslations,
} from '../../i18n/translation';
import type { HeaderFeatureOptions } from '../../interfaces/feature-options';
import type { MenuItem } from '../../interfaces/menu';
import type { HeaderSupportedLocales } from '../../interfaces/supported-locales';
import css from './menu-item-component.module.scss';

export interface MainMenuItemProps {
    featureOptions: HeaderFeatureOptions;
    /**
     * Given item to be rendered
     */
    item: MenuItem;
    /**
     * Custom classname for styling
     */
    className: string;
    /**
     * Optional size for the icon of the item
     */
    iconSize?: string;
    /**
     * Sets greyed out styling on the component
     */
    greyedOut?: boolean;

    /**
     * Removes hover styling, clickability and cursor pointer
     * Also make the item bold
     */
    isMenuHeader?: boolean;

    /**
     * Function to be passed down on the onClick of the component
     * @param event SyntheticEvent param from the onClick function
     */
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    /**
     * Sets fold out styling
     */
    foldoutVariant?: boolean;
    /**
     * Custom id for styling
     */
    id?: string;
    locale: HeaderSupportedLocales;
}

export const MenuItemComponent = ({
    locale,
    item,
    className,
    greyedOut = false,
    isMenuHeader = false,
    onClick,
    foldoutVariant,
    id,
}: MainMenuItemProps) => {
    const t = createTranslate(locale, headerTranslations);

    const isAnchor = item.href && !item.hasSubNav;

    const LinkComponent = isAnchor ? LinkAnchor : LinkButton;

    return (
        <li
            className={classNames(
                css[className as keyof typeof css],
                greyedOut && css.menuItemGreyedOut,
                foldoutVariant && css.foldoutVariant,
                isMenuHeader && css.menuItemHeader,
                item.id === MenuItemId.RECIPES && css.secondaryMenuItemRecipes,
            )}
            id={id}
            {...createTestHook(item.testHook)}
        >
            <LinkComponent
                className={
                    css[`${className}Anchor` as keyof typeof css] as string
                }
                onClick={event => {
                    if (onClick) {
                        onClick(
                            event as unknown as React.MouseEvent<HTMLAnchorElement>,
                        );
                    }
                }}
                href={isAnchor ? item.href : undefined}
            >
                {item.Icon && (
                    <item.Icon size={24} className={css.menuItemIcon} />
                )}

                <LinkText
                    className={
                        css[
                            `${className}AnchorText` as keyof typeof css
                        ] as string
                    }
                    level={isMenuHeader ? 'primary' : 'secondary'}
                >
                    {t(item.label as HeaderTranslationKey)}
                </LinkText>

                {item.hasSubNav && (
                    <ChevronRight16Icon size={16} className={css.subNavIcon} />
                )}
            </LinkComponent>
        </li>
    );
};
