'use client';

import { useEffect, useState } from 'react';

export const MOBILE_QUERY_PARAM = 'mobile';

export const useMobileWebView = (): boolean => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (typeof window === 'undefined') return;

        const queryParams = new URLSearchParams(window.location.search);
        setIsMobile(queryParams.get(MOBILE_QUERY_PARAM) === 'true');
    }, []);

    return isMobile;
};
