import {
    SearchSection,
    emitClickComponent,
} from '@royalaholddelhaize/ah-analytics';
import { SearchSuggestionType } from '../../../../../../../../graphql/_generated-operation-types';
import { PATH } from '../../../../../../../constants/path';
import {
    POPULAR_CUSTOMER_CARE_TERM_PREF,
    PREVIOUS_ID_PREF,
} from '../../../constants';
import type { Suggestion } from '../suggestion.interface';

/**
 *  Helper function to emit click events on search suggestions inside the auto suggest panel
 */
export const emitSuggestionClickEvent = (
    suggestion: Suggestion,
    position: number,
    query: string,
    label: string,
    href: string,
) => {
    const isCustomerCarePage =
        typeof window !== 'undefined' &&
        window.location.pathname.startsWith(PATH.customerService);
    const isPrevious =
        isCustomerCarePage && suggestion.id.startsWith(PREVIOUS_ID_PREF);

    if (suggestion.section === SearchSection.CUSTOMER_CARE || isPrevious) {
        const isDefault =
            suggestion.type === SearchSuggestionType.DEFAULT &&
            suggestion.id.includes(POPULAR_CUSTOMER_CARE_TERM_PREF);
        const queryData = query.trim() ? ` - Query: ${query}` : '';

        let status = 'idle'; // Default status is idle (default suggestions in auto suggest panel)

        if (isPrevious) {
            status = 'previous query';
        } else if (!isDefault) {
            status = 'result';
        }

        emitClickComponent({
            componentType: 'Search field',
            componentSubType: `Autosuggest panel - ${status}`,
            componentInnerText: label,
            componentTargetUrl: href,
            componentSection: 'Self Service - CS Global Search Bar',
            componentAdditional: `Position: ${position}${queryData}`,
        });
    }
};
