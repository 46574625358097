'use client';

import type { FC, PropsWithChildren } from 'react';
import type { FeatureFlagsData } from '../types';
import { FeatureFlagsContext } from './context';

interface FeatureFlagsProviderProps extends PropsWithChildren {
    data: FeatureFlagsData;
}

export const FeatureFlagsProvider: FC<FeatureFlagsProviderProps> = ({
    data,
    children,
}) => {
    return (
        <FeatureFlagsContext.Provider value={data}>
            {children}
        </FeatureFlagsContext.Provider>
    );
};
