import {
    AH_UI_NAVIGATION_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import { ChevronRight16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-right-16';
import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import classNames from 'clsx';
import type { MouseEventHandler } from 'react';
import { SearchSuggestionType } from '../../../../../../../graphql/_generated-operation-types';
import { createTranslate } from '../../../../../../../i18n/translation';
import {
    type HeaderTranslationKey,
    headerTranslations,
} from '../../../../../../i18n/translation';
import type { HeaderSupportedLocales } from '../../../../../../interfaces/supported-locales';
import { addLocalSuggestion } from '../../helpers/local-suggestions.helper';
import { sanitizeSuggestion } from '../../helpers/sanitize-suggestion';
import { emitSuggestionClickEvent } from './helpers/emit-suggestion-click-event';
import { highlightQuery } from './helpers/highlight-query';
import type {
    RenderedSuggestion,
    Suggestion as SuggestionData,
} from './suggestion.interface';
import css from './suggestion.module.scss';

export interface SuggestionProps {
    /**
     * Sets highlighted styling
     */
    isHighlighted: boolean;
    /**
     * Given GraphQL data to display
     */
    suggestion: SuggestionData;
    /**
     * Given search input
     */
    suggestionsQuery: string;
    /**
     * Position of suggestion in suggestions list
     */
    position: number;

    /** Reports click on a particular auto-suggested item to the analytics */
    analyticsSearchHandler?: (suggestion: RenderedSuggestion) => void;
    locale: HeaderSupportedLocales;
}

export const Suggestion = ({
    locale,
    isHighlighted = false,
    suggestion,
    suggestionsQuery,
    position,
    analyticsSearchHandler,
}: SuggestionProps) => {
    const t = createTranslate(locale, headerTranslations);

    const { type, value, label, href, icon: Icon } = suggestion;
    const isMoreLink = suggestion.type === SearchSuggestionType.MORE_LINK;

    const labelText =
        suggestionsQuery === '' ? (
            <LinkText className={css.text} level="secondary">
                {t(label as HeaderTranslationKey)}
            </LinkText>
        ) : (
            highlightQuery(
                t(label as HeaderTranslationKey),
                suggestionsQuery.toLowerCase(),
            )
        );

    const replaced = isMoreLink ? (
        <LinkText className={css.text} level="secondary">
            {t('suggestions.link', {
                value: sanitizeSuggestion(t(value as HeaderTranslationKey)),
                label: t(label as HeaderTranslationKey),
            })}
        </LinkText>
    ) : (
        labelText
    );

    const onClick = () => {
        if (type !== SearchSuggestionType.DEFAULT) {
            return;
        }

        const renderedLabel = t(label as HeaderTranslationKey);

        addLocalSuggestion(renderedLabel);
        analyticsSearchHandler &&
            analyticsSearchHandler({ ...suggestion, renderedLabel });
    };

    const handleClick: MouseEventHandler<HTMLElement> = () => {
        emitSuggestionClickEvent(
            suggestion,
            position,
            suggestionsQuery,
            t(label as HeaderTranslationKey),
            t(href as HeaderTranslationKey),
        );

        onClick();
    };

    return (
        <li
            key={href}
            className={classNames(css.item, isHighlighted && css.highlighted)}
            {...createTestHook(AH_UI_NAVIGATION_TESTHOOKS.HEADER.suggestion)}
        >
            <LinkAnchor
                className={classNames(css.link, {
                    [css.moreLink]: isMoreLink,
                })}
                onClick={handleClick}
                href={t(href as HeaderTranslationKey)}
            >
                {Icon && <Icon size={24} className={css.icon} />}
                {replaced}
                <ChevronRight16Icon size={16} className={css.caret} />
            </LinkAnchor>
        </li>
    );
};
