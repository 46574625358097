import {
    AH_UI_NAVIGATION_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import {
    LinkButton,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type React from 'react';
import { type FC, useCallback, useState } from 'react';
import { createTranslate } from '../../../i18n/translation';
import { useMember } from '../../contexts/member';
import { goToLogin } from '../../helpers/menu.helper';
import { headerTranslations } from '../../i18n/translation';
import type { HeaderSupportedLocales } from '../../interfaces/supported-locales';
import { PanelBody } from './elements/panel/elements/panel-body/panel-body';
import { PanelHeader } from './elements/panel/elements/panel-header/panel-header';
import { Panel } from './elements/panel/panel';
import type { StackPaneOptions } from './elements/stack/interfaces/stack';
import { Stack, type StackProps } from './elements/stack/stack';
import css from './nav-panel.module.scss';

export const NavPanel: FC<
    StackProps & {
        locale: HeaderSupportedLocales;
    }
> = ({ featureOptions, initialPane, onCloseStack, locale }) => {
    const {
        member: { displayName, isLoggedIn },
    } = useMember();
    const [pane, setPane] = useState<StackPaneOptions>();

    const t = createTranslate(locale, headerTranslations);

    const handleOpenPane = useCallback(
        (
            _: React.SyntheticEvent,
            __: StackPaneOptions<void>[],
            newPane: StackPaneOptions<void>,
        ) => setPane(newPane),
        [],
    );

    const handleClosePane = useCallback(
        (_: React.SyntheticEvent, panes: StackPaneOptions<void>[]) => {
            setPane(panes.length > 1 ? panes[panes.length - 1] : undefined);
        },
        [],
    );

    const handleCloseStack = useCallback(
        (event: React.SyntheticEvent) => {
            setPane(undefined);
            if (onCloseStack) {
                onCloseStack(event as unknown as React.MouseEvent);
            }
        },
        [onCloseStack],
    );

    return (
        <Panel>
            <PanelHeader
                className={css.panelHeader}
                onBack={pane?.props.closePane}
                onClose={pane?.props.closeStack || handleCloseStack}
                equalizeNavBar
            >
                {!isLoggedIn && !pane && (
                    <LinkButton
                        onClick={goToLogin}
                        className={css.headerLink}
                        {...createTestHook(
                            AH_UI_NAVIGATION_TESTHOOKS.HEADER
                                .navPanelHeaderLink,
                        )}
                    >
                        <LinkText>{t('menu.login')}</LinkText>
                    </LinkButton>
                )}
                {isLoggedIn && !pane && (
                    <Typography
                        className={css.headerLink}
                        {...createTestHook(
                            AH_UI_NAVIGATION_TESTHOOKS.HEADER
                                .navPanelHeaderLink,
                        )}
                    >
                        {t('greeting', {
                            name: displayName.split(' ')[0],
                        })}
                    </Typography>
                )}
            </PanelHeader>
            <PanelBody className={css.panelBody}>
                <Stack
                    featureOptions={featureOptions}
                    initialPane={initialPane}
                    onCloseStack={handleCloseStack}
                    onOpenPane={handleOpenPane}
                    onClosePane={handleClosePane}
                />
            </PanelBody>
        </Panel>
    );
};
