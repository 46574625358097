'use client';

import type { FC } from 'react';
import { AccordionItem } from '../accordion-item/accordion-item';
import { AccordionWrapper } from '../accordion-wrapper/accordion-wrapper';

export type FooterLink = {
    link: string;
    title: string;
    target: string;
};

export type FooterLinkGroup = { subject: string; links: FooterLink[] };

interface MenuLinksProps {
    content: FooterLinkGroup[];
}

export const MenuLinks: FC<MenuLinksProps> = ({ content }) =>
    content.map(group => (
        <AccordionWrapper title={group.subject} key={group.subject}>
            {group.links.map(link => (
                <li key={link.title}>
                    <AccordionItem
                        href={link.link}
                        label={link.title}
                        target={link.target}
                    />
                </li>
            ))}
        </AccordionWrapper>
    ));
