import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { Close16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/close-16';
import { LinkButton } from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import type React from 'react';
import css from './dialog-close-button.module.scss';

export interface DialogCloseButtonProps {
    /**
     * Close Function for the dialog
     */
    onClose?: () => void;
}

export const DialogCloseButton: React.FC<DialogCloseButtonProps> = ({
    onClose,
}) => {
    return (
        <LinkButton
            onClick={onClose}
            className={css.root}
            {...createTestHook('dialog-content-close')}
        >
            <Close16Icon size={16} />
        </LinkButton>
    );
};
