import { SearchSection } from '../../enums/search';

export const ARROW_UP = 'ArrowUp';
export const ARROW_DOWN = 'ArrowDown';

type Section = {
    path: string;
    param: string;
};

export const SearchSections: Record<SearchSection, Section> = {
    [SearchSection.PRODUCTS]: {
        path: '/zoeken',
        param: 'query',
    },
    [SearchSection.RECIPES]: {
        path: '/allerhande/recepten-zoeken',
        param: 'query',
    },
    [SearchSection.CUSTOMER_CARE]: {
        path: '/klantenservice/zoeken',
        param: 'query',
    },
};
