'use client';

import type { ApolloError } from '@apollo/client';
import {
    AH_UI_NAVIGATION_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import {
    RegularButton,
    RegularButtonText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import type React from 'react';
import { type FC, useState } from 'react';
import { useAhMemberConsentsUpdateMutation } from '../../../graphql/_generated-hooks';
import { MemberLoginState } from '../../../graphql/_generated-operation-types';
import { OptInIds } from '../../../graphql/member/member.hook';
import { useMember } from '../../contexts/member';
import { Dialog } from './elements/dialog/dialog';
import { DialogActions } from './elements/dialog/elements/dialog-actions/dialog-actions';
import { DialogContent } from './elements/dialog/elements/dialog-content/dialog-content';
import { DialogHeader } from './elements/dialog/elements/dialog-header/dialog-header';
import { Checked, type NameValuePair, Switch } from './elements/switch/switch';
import {
    handleAcceptClick,
    handleRejectClick,
} from './joining-forces-opt-in.helper';
import {
    useInitialOptInData,
    useJoiningForcesOptInEvent,
    useOpenModalOnPageLoad,
} from './joining-forces-opt-in.hook';
import styles from './joining-forces-opt-in.module.scss';

interface JoiningForcesOptInProps {
    contentOptIn?: {
        title: string;
        introduction: string;
        closingText: string;
        optIns: { id: string; text: string }[];
    } | null;
    error?: ApolloError;
}

export const JoiningForcesOptIn: FC<JoiningForcesOptInProps> = ({
    contentOptIn,
    error,
}) => {
    const [optInsChecked, setOptInsChecked] = useState<{
        [key: string]: boolean;
    }>({
        [OptInIds.DataUsage]: false,
        [OptInIds.DataSharing]: false,
    });

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const {
        member: { consentsToShow, consents, memberLoginState },
    } = useMember();
    const isLoggedIn = memberLoginState === MemberLoginState.LOGGEDON;
    const [updateConsents] = useAhMemberConsentsUpdateMutation();

    const userHasSubmittedOptIn =
        !consentsToShow?.includes(OptInIds.DataUsage) &&
        !consentsToShow?.includes(OptInIds.DataSharing);

    const userHasSelectedOpts = Object.values(optInsChecked)?.includes(true);
    const shouldRenderModal = contentOptIn && !error && isLoggedIn;

    const openModal = () => setIsDialogOpen(true);

    const closeModal = () => setIsDialogOpen(false);

    const toggleModal = () => {
        setIsDialogOpen(prevIsDialogOpen => !prevIsDialogOpen);
    };

    useOpenModalOnPageLoad(
        userHasSubmittedOptIn,
        openModal,
        !!shouldRenderModal,
    );

    useJoiningForcesOptInEvent(toggleModal);

    useInitialOptInData(consents, setOptInsChecked);

    const onValueChanged = ({ name, value }: NameValuePair) => {
        setOptInsChecked({
            ...optInsChecked,
            [name]: value === Checked.TRUE,
        });

        // We care about the "data sharing" consent only if the "data usage" consent is already accepted
        if (name === OptInIds.DataUsage && value === Checked.FALSE) {
            setOptInsChecked({
                [OptInIds.DataUsage]: false,
                [OptInIds.DataSharing]: false,
            });
        }
    };

    const handleFormAcceptClick = () => {
        return handleAcceptClick(
            userHasSelectedOpts,
            optInsChecked,
            setOptInsChecked,
            closeModal,
            updateConsents,
        );
    };

    const handleFormRejectClick = () => {
        return handleRejectClick(
            optInsChecked,
            setOptInsChecked,
            closeModal,
            updateConsents,
        );
    };

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        handleFormAcceptClick();
    };

    if (!shouldRenderModal) {
        return null;
    }

    return (
        <form
            onSubmit={handleFormSubmit}
            {...createTestHook(AH_UI_NAVIGATION_TESTHOOKS.HEADER.jfForm)}
        >
            <Dialog
                id="data-usage-sharing-dialog"
                className={styles.dialog}
                isOpen={isDialogOpen}
            >
                <DialogHeader
                    className={styles.dialogHeader}
                    titleClassName={styles.dialogTitle}
                    title={contentOptIn.title}
                    showCloseButton={false}
                />
                <DialogContent>
                    <div
                        className={styles.dialogContentText}
                        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                        dangerouslySetInnerHTML={{
                            __html: contentOptIn.introduction,
                        }}
                    />
                    {contentOptIn.optIns?.map(optIn => {
                        return (
                            <div
                                key={optIn.id}
                                className={styles.checkBoxContainer}
                            >
                                <Switch
                                    name={optIn.id}
                                    checked={optInsChecked[optIn.id]}
                                    trackClassName="blue-switch"
                                    onValueChanged={onValueChanged}
                                    {...createTestHook(
                                        AH_UI_NAVIGATION_TESTHOOKS.HEADER
                                            .jfSwitch,
                                    )}
                                    disabled={
                                        optIn.id === OptInIds.DataSharing &&
                                        !optInsChecked[OptInIds.DataUsage]
                                    }
                                />
                                <div
                                    className={styles.dialogContentText}
                                    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                                    dangerouslySetInnerHTML={{
                                        __html: optIn.text,
                                    }}
                                />
                            </div>
                        );
                    })}
                    <div
                        className={styles.dialogContentText}
                        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                        dangerouslySetInnerHTML={{
                            __html: contentOptIn.closingText,
                        }}
                    />
                </DialogContent>

                <DialogActions className={styles.dialogActions}>
                    <RegularButton
                        type="submit"
                        className={styles.button}
                        {...createTestHook(
                            AH_UI_NAVIGATION_TESTHOOKS.HEADER.jfAcceptButton,
                        )}
                    >
                        <RegularButtonText>
                            {userHasSelectedOpts
                                ? 'Keuze opslaan'
                                : 'Alles accepteren'}
                        </RegularButtonText>
                    </RegularButton>
                    <RegularButton
                        level="secondary"
                        className={styles.button}
                        onClick={handleFormRejectClick}
                        {...createTestHook(
                            AH_UI_NAVIGATION_TESTHOOKS.HEADER.jfDeclineButton,
                        )}
                    >
                        <RegularButtonText>Weigeren</RegularButtonText>
                    </RegularButton>
                </DialogActions>
            </Dialog>
        </form>
    );
};
