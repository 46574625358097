import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import classNames from 'clsx';
import { createMenuAnchor } from '../../../../../helper/menu-anchor.helper';
import { createTranslate } from '../../../../../i18n/translation';
import {
    type ProfileTranslationKey,
    profileTranslations,
} from '../../../../i18n/translation';
import type { MenuItem as MenuItemType } from '../../../../interfaces/profile-menu';
import type { ProfileMenuProps } from '../../menu';
import css from './menu-item.module.scss';

interface MenuItemProps extends ProfileMenuProps {
    menuItem: MenuItemType;
    isSelected?: boolean;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const MenuItem = ({
    locale,
    menuItem,
    featureFlags,
    memberType,
    onClick,
    isSelected,
}: MenuItemProps) => {
    const t = createTranslate(locale, profileTranslations);
    const showFlag = menuItem.featureFlag
        ? !!featureFlags[`x-variant-${menuItem.featureFlag}`]
        : true;
    const showDomain = menuItem.domain
        ? menuItem.domain.includes(memberType)
        : true;
    const shouldShow = showFlag && showDomain;

    if (!shouldShow) {
        return null;
    }

    const Icon = menuItem.icon;
    const title = t(`menu.${menuItem.title}` as ProfileTranslationKey);

    return (
        <LinkAnchor
            className={classNames(css.root, {
                [css.selected]: isSelected,
            })}
            onClick={event => {
                if (onClick) {
                    onClick(event);
                }
            }}
            href={menuItem.items ? createMenuAnchor(title) : menuItem.url || ''}
            {...createTestHook(`menu-item-${title.toLowerCase()}`)}
        >
            {Icon && <Icon className={css.icon} />}
            <LinkText className={css.text}>{title}</LinkText>
        </LinkAnchor>
    );
};
