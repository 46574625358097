import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { ChevronDown16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-down-16';
import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import classNames from 'clsx';
import type React from 'react';
import type { ReactNode } from 'react';
import styles from './header-button.module.scss';

export interface HeaderButtonProps {
    title: string;
    href?: string;
    isOpen: boolean;
    testHook?: string;
    handleOnKeyDown?: (e: React.KeyboardEvent) => void;
    handleOnClick?: (e: React.SyntheticEvent) => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    children?: ReactNode;
}

export const HeaderButton = ({
    title,
    isOpen,
    href,
    testHook,
    handleOnClick,
    handleOnKeyDown,
    onMouseEnter,
    onMouseLeave,
    children,
}: HeaderButtonProps) => {
    return (
        <li
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onFocus={onMouseEnter}
            onBlur={onMouseLeave}
        >
            <LinkAnchor
                className={styles.mainMenuItemAnchor}
                onClick={event => {
                    if (handleOnClick) {
                        handleOnClick(event);
                    }
                }}
                href={href}
                onKeyDown={handleOnKeyDown}
                {...createTestHook(testHook)}
            >
                <LinkText
                    className={styles.mainMenuItemAnchorText}
                    level="secondary"
                >
                    {title}
                </LinkText>
                <ChevronDown16Icon
                    size={16}
                    className={classNames(
                        isOpen && styles.megaMenuChevronActive,
                    )}
                />
            </LinkAnchor>
            {children}
        </li>
    );
};
