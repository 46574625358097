import keycode from 'keycode';
import { type FC, type PropsWithChildren, useEffect } from 'react';

export interface KeydownHandlerProps {
    handler: (event: KeyboardEvent) => void;
    keyCodes: string[];
}

export const KeydownHandler: FC<PropsWithChildren<KeydownHandlerProps>> = ({
    children,
    handler,
    keyCodes,
}) => {
    useEffect(() => {
        const handleKeydown = (event: KeyboardEvent) => {
            const code = keycode(event);

            if (keyCodes.includes(code)) {
                handler(event);
                return;
            }
        };

        document.addEventListener('keydown', handleKeydown);

        return () => document.removeEventListener('keydown', handleKeydown);
    }, [handler, keyCodes]);

    return <>{children}</>;
};
