import { AH_UI_NAVIGATION_TESTHOOKS } from '@royalaholddelhaize/ah-test-hooks';
import { Country } from '@royalaholddelhaize/ah-web-core';
import {
    B2B24Icon,
    BonusOutlined24Icon,
    Chat24Icon,
    DeliveryPackage24Icon,
    DiscoverOutlined24Icon,
    FavoriteOutlined24Icon,
    Gift24Icon,
    OrderOutlined24Icon,
    Premium24Icon,
    PreviouslyBought24Icon,
    ProductsAltOutlined24Icon,
    Profile24Icon,
    Recipes24Icon,
    SignIn24Icon,
    SignOut24Icon,
    Store24Icon,
    Ticket24Icon,
} from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import { PATH } from '../constants/path';
import { MenuItemId } from '../enums/menu-item-id';
import type { Menu } from '../interfaces/menu';

export const menuItemsConfig: Menu = {
    [Country.NLD]: {
        mainMenu: [
            {
                id: MenuItemId.PRODUCTS,
                label: 'menu.products',
                href: PATH.products,
                testHook: AH_UI_NAVIGATION_TESTHOOKS.HEADER.products,
                Icon: ProductsAltOutlined24Icon,
            },
            {
                id: MenuItemId.BONUS,
                label: 'menu.bonus',
                href: PATH.bonus,
                Icon: BonusOutlined24Icon,
            },
            {
                id: MenuItemId.RECIPES,
                label: 'menu.recipes',
                href: PATH.recipes,
                testHook: AH_UI_NAVIGATION_TESTHOOKS.HEADER.recipes,
                Icon: Recipes24Icon,
            },
        ],
        secondaryMenu: [
            {
                id: MenuItemId.RECIPES,
                label: 'menu.recipes',
                href: PATH.recipes,
                Icon: Recipes24Icon,
            },
            {
                id: MenuItemId.TICKET_SHOP,
                label: 'menu.ticketShop',
                href: 'https://voordeelshop.ah.nl?ah_medium=www.ah.nl&ah_source=header&ah_campaign=na',
                target: '_blank',
                rel: 'noopener noreferrer',
                Icon: Ticket24Icon,
            },
            {
                id: MenuItemId.ABOUT,
                label: 'menu.about',
                href: PATH.about,
                Icon: DiscoverOutlined24Icon,
            },
        ],
        topMenu: [
            {
                id: MenuItemId.SAVINGS_PROMOTIONS,
                label: 'menu.promotions',
                href: PATH.promotions,
                Icon: Gift24Icon,
            },
            {
                id: MenuItemId.SHOPS,
                label: 'menu.shops',
                href: PATH.shops,
                Icon: Store24Icon,
            },
            {
                id: MenuItemId.B2B,
                label: 'menu.business',
                href: PATH.business,
                Icon: B2B24Icon,
            },
            {
                id: MenuItemId.CUSTOMER_SERVICE,
                label: 'menu.customerService',
                href: PATH.customerService,
                Icon: Chat24Icon,
            },
        ],
        userMenu: [
            {
                id: MenuItemId.PROFILE,
                label: 'menu.profile',
                href: PATH.profileDashboard,
                Icon: Profile24Icon,
            },
            {
                id: MenuItemId.FAVORITES,
                label: 'menu.favorites',
                href: PATH.favorites,
                Icon: FavoriteOutlined24Icon,
            },
            {
                id: MenuItemId.PREVIOUSLY_BOUGHT,
                label: 'menu.previouslyBought',
                href: PATH.previouslyBought,
                Icon: PreviouslyBought24Icon,
            },
            {
                id: MenuItemId.ORDERS,
                label: 'menu.orders',
                href: PATH.orders,
                Icon: DeliveryPackage24Icon,
            },
            {
                id: MenuItemId.PREMIUM,
                label: 'menu.premium',
                href: PATH.premium,
                Icon: Premium24Icon,
            },
            {
                id: MenuItemId.DELIVERY_BUNDLE,
                label: 'menu.deliveryBundle',
                href: PATH.deliveryBundle,
                Icon: OrderOutlined24Icon,
            },
            {
                id: MenuItemId.DELIVERY_BUNDLE_BUSINESS,
                label: 'menu.deliveryBundleBusiness',
                href: PATH.deliveryBundleBusiness,
                Icon: OrderOutlined24Icon,
            },
        ],
        currentOrderLink: [
            {
                id: MenuItemId.ORDER_ONLINE,
                label: 'menu.orderOnline',
                href: PATH.orderOnline,
                testHook: AH_UI_NAVIGATION_TESTHOOKS.HEADER.currentOrder,
            },
        ],
        general: [
            {
                id: MenuItemId.LOGIN,
                label: 'menu.login',
                href: PATH.login,
                Icon: SignIn24Icon,
                testHook: AH_UI_NAVIGATION_TESTHOOKS.HEADER.login,
            },
            {
                id: MenuItemId.LOGOUT,
                label: 'menu.logout',
                href: PATH.logout,
                Icon: SignOut24Icon,
                testHook: AH_UI_NAVIGATION_TESTHOOKS.HEADER.logout,
            },
            {
                id: MenuItemId.FAVORITES,
                label: 'menu.favorites',
                href: PATH.favorites,
                Icon: FavoriteOutlined24Icon,
            },
        ],
    },
    [Country.BEL]: {
        mainMenu: [
            {
                id: MenuItemId.PRODUCTS,
                label: 'menu.products',
                href: PATH.products,
                testHook: AH_UI_NAVIGATION_TESTHOOKS.HEADER.products,
                Icon: ProductsAltOutlined24Icon,
            },
            {
                id: MenuItemId.BONUS,
                label: 'menu.bonus',
                href: PATH.bonus,
                Icon: BonusOutlined24Icon,
            },
            {
                id: MenuItemId.FOLDER,
                label: 'menu.folder',
                href: PATH.folder,
                Icon: Gift24Icon,
            },
            {
                id: MenuItemId.RECIPES,
                label: 'menu.recipes',
                href: PATH.recipes,
                Icon: Recipes24Icon,
            },
        ],
        secondaryMenu: [
            {
                id: MenuItemId.RECIPES,
                label: 'menu.recipes',
                href: PATH.recipes,
                Icon: Recipes24Icon,
            },
            {
                id: MenuItemId.JOBS,
                label: 'menu.jobs',
                href: 'https://jobs.albertheijn.be',
                Icon: DiscoverOutlined24Icon,
            },
            {
                id: MenuItemId.ABOUT,
                label: 'menu.about',
                href: PATH.about,
                Icon: DiscoverOutlined24Icon,
            },
        ],
        topMenu: [
            {
                id: MenuItemId.SAVINGS_PROMOTIONS,
                label: 'menu.promotions',
                href: PATH.promotions,
                Icon: Gift24Icon,
            },
            {
                id: MenuItemId.SHOPS,
                label: 'menu.shops',
                href: PATH.shops,
                Icon: Store24Icon,
            },
            {
                id: MenuItemId.B2B,
                label: 'menu.business',
                href: PATH.business,
                Icon: B2B24Icon,
            },
            {
                id: MenuItemId.CUSTOMER_SERVICE,
                label: 'menu.customerService',
                href: PATH.customerService,
                Icon: Chat24Icon,
            },
        ],
        userMenu: [
            {
                id: MenuItemId.PREVIOUSLY_BOUGHT,
                label: 'menu.previouslyBought',
                href: PATH.previouslyBought,
                Icon: PreviouslyBought24Icon,
            },
            {
                id: MenuItemId.FAVORITES,
                label: 'menu.favorites',
                href: PATH.favorites,
                Icon: FavoriteOutlined24Icon,
            },
            {
                id: MenuItemId.ORDERS,
                label: 'menu.orders',
                href: PATH.ordersBE,
                Icon: DeliveryPackage24Icon,
            },
            {
                id: MenuItemId.PROFILE,
                label: 'menu.profile',
                href: PATH.profileDashboard,
                Icon: Profile24Icon,
            },
            {
                id: MenuItemId.DELIVERY_BUNDLE,
                label: 'menu.deliveryBundle',
                href: PATH.deliveryBundle,
                Icon: OrderOutlined24Icon,
            },
        ],
        currentOrderLink: [
            {
                id: MenuItemId.ORDER_ONLINE,
                label: 'menu.orderOnline',
                href: PATH.orderOnline,
                testHook: AH_UI_NAVIGATION_TESTHOOKS.HEADER.currentOrder,
            },
        ],
        general: [
            {
                id: MenuItemId.LOGIN,
                label: 'menu.login',
                href: PATH.login,
                Icon: SignIn24Icon,
                testHook: AH_UI_NAVIGATION_TESTHOOKS.HEADER.login,
            },
            {
                id: MenuItemId.LOGOUT,
                label: 'menu.logout',
                href: PATH.logout,
                Icon: SignOut24Icon,
                testHook: AH_UI_NAVIGATION_TESTHOOKS.HEADER.logout,
            },
            {
                id: MenuItemId.FAVORITES,
                label: 'menu.favorites',
                href: PATH.favorites,
                Icon: FavoriteOutlined24Icon,
            },
        ],
    },
};
