import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import { useEffect, useState } from 'react';
import { SearchSuggestionType } from '../../../../../graphql/_generated-operation-types';
import { createTranslate } from '../../../../../i18n/translation';
import {
    type HeaderTranslationKey,
    headerTranslations,
} from '../../../../i18n/translation';
import type { HeaderSupportedLocales } from '../../../../interfaces/supported-locales';
import { ARROW_DOWN, ARROW_UP } from '../../constants';
import { Suggestion as SuggestionComponent } from './elements/suggestion/suggestion';
import type {
    RenderedSuggestion,
    Suggestion,
} from './elements/suggestion/suggestion.interface';
import { useKeyPress } from './helpers/use-key-press';
import css from './suggestions.module.scss';

export interface SuggestionsProps {
    /**
     * Given isOpen state of the component
     */
    isOpen: boolean;
    /**
     * Given GraphQL data to display
     */
    suggestions: Suggestion[];
    /**
     * Given search input
     */
    query?: string;
    /**
     * Function that is called when using arrow keys
     * @param Suggestion that is selected
     */
    onSelectionWithArrowKeys: (suggestion: Suggestion) => void;

    /** Reports click on a particular auto-suggested item to the analytics */
    analyticsSearchHandler?: (suggestion: RenderedSuggestion) => void;
    locale: HeaderSupportedLocales;
}

export const Suggestions = ({
    locale,
    isOpen,
    suggestions,
    query = '',
    onSelectionWithArrowKeys,
    analyticsSearchHandler,
}: SuggestionsProps) => {
    const [cursor, setCursor] = useState(-1);
    const downPress = useKeyPress(ARROW_DOWN);
    const upPress = useKeyPress(ARROW_UP);
    const options = suggestions.flatMap(suggestion => suggestion.suggestions);

    const t = createTranslate(locale, headerTranslations);

    const suggestionComponent = (suggestion: Suggestion, index: number) => (
        <SuggestionComponent
            locale={locale}
            analyticsSearchHandler={analyticsSearchHandler}
            key={suggestion.id}
            suggestion={suggestion}
            suggestionsQuery={query}
            isHighlighted={options[cursor]?.id === suggestion.id}
            position={index + 1}
        />
    );

    const suggestionSection = suggestions.map((suggestion, idx) => {
        if (suggestion.suggestions?.length) {
            if (suggestion.type === SearchSuggestionType.SECTION) {
                return (
                    <div
                        key={`${t(
                            suggestion.value as HeaderTranslationKey,
                        )}-${idx}`}
                    >
                        <li className={css.suggestionSection}>
                            <Typography
                                variant="subtext-regular"
                                className={css.suggestionSectionText}
                            >
                                {t(suggestion.value as HeaderTranslationKey)}
                            </Typography>
                        </li>
                        {suggestion.suggestions?.map(suggestionComponent, idx)}
                    </div>
                );
            }

            return suggestionComponent(suggestion, idx);
        }
    });

    // Move down through the options by keyboard and return to the top after the last option.
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!isOpen) {
            return;
        }

        if (options.length && downPress) {
            setCursor(prevState => {
                if (prevState < options.length - 1) {
                    return prevState + 1;
                }

                if (prevState === options.length - 1) {
                    return 0;
                }

                return prevState;
            });
        }
    }, [downPress, isOpen]);

    // Move up through the options by keyboard and go to the bottom after the first option.
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!isOpen) {
            return;
        }

        if (options.length && upPress) {
            setCursor(prevState => {
                if (prevState > 0) {
                    return prevState - 1;
                }

                if (prevState === 0) {
                    return options.length - 1;
                }

                return prevState;
            });
        }
    }, [upPress, isOpen]);

    // Set the selected option and change the query in the input.
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const selection = options[cursor];
        if (selection) {
            onSelectionWithArrowKeys(selection);
        }
    }, [cursor]);

    return (
        <div className={classNames(css.suggestionsContainer, css.compact)}>
            <div className={css.wrapper}>
                {isOpen && (
                    <menu className={css.suggestions}>{suggestionSection}</menu>
                )}
            </div>
        </div>
    );
};
