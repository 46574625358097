import classNames from 'clsx';
import { useBasket } from '../../../graphql/basket/basket.hook';
import { OrderMode } from '../../enums/order-mode';
import type { HeaderApolloOptions } from '../../interfaces/apollo-options';
import type { HeaderSupportedLocales } from '../../interfaces/supported-locales';
import { NavBasket } from '../nav-basket/nav-basket';
import styles from './navigation-basket.module.scss';

type NavigationBasketProps = {
    apollo: HeaderApolloOptions;
    locale: HeaderSupportedLocales;
};

export const NavigationBasket = ({ apollo, locale }: NavigationBasketProps) => {
    const { orderMode = OrderMode.NONE } = useBasket(apollo.initialised);

    return (
        <div
            className={classNames(
                styles.navigationBasket,
                styles[
                    `navigationBasket${
                        orderMode || OrderMode.NONE
                    }` as keyof typeof styles
                ],
            )}
        >
            <NavBasket apollo={apollo} locale={locale} />
        </div>
    );
};
