import {
    AH_UI_NAVIGATION_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import { Close16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/close-16';
import { LinkButton } from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import { useEffect, useState } from 'react';
import { createTranslate } from '../../../i18n/translation';
import { useHeaderInteraction } from '../../hooks/use-header-interaction';
import { headerTranslations } from '../../i18n/translation';
import type { HeaderApolloOptions } from '../../interfaces/apollo-options';
import type { HeaderSupportedLocales } from '../../interfaces/supported-locales';
import { useTooltipNotification } from './tooltip-notification.hook';
import css from './tooltip-notification.module.scss';

type TooltipNotificationProps = {
    apollo: HeaderApolloOptions;
    locale: HeaderSupportedLocales;
};

export const TooltipNotification = ({
    locale,
    apollo,
}: TooltipNotificationProps) => {
    const [hide, setHide] = useState<boolean>(false);
    const { isVisible: isTooltipVisible, handleHide } =
        useTooltipNotification(apollo);
    const { isVisible: isHeaderVisible } = useHeaderInteraction();

    const t = createTranslate(locale, headerTranslations);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (hide) {
            handleHide();
        }
    }, [hide]);

    return isHeaderVisible && isTooltipVisible ? (
        <div
            className={classNames(css.root, { [css.exit]: hide })}
            {...createTestHook(
                AH_UI_NAVIGATION_TESTHOOKS.HEADER.notificationToolTip,
            )}
        >
            <Typography className={css.text}>
                {t('tooltip-notification.text')}
                <LinkButton
                    onClick={event => {
                        event.preventDefault();
                        setHide(true);
                    }}
                    className={css.button}
                    aria-label={t('search.close')}
                    {...createTestHook(
                        AH_UI_NAVIGATION_TESTHOOKS.HEADER
                            .notificationTooltipClose,
                    )}
                >
                    <Close16Icon className={css.close} size={16} />
                </LinkButton>
            </Typography>
        </div>
    ) : null;
};
