'use client';

import type { IncomingHttpHeaders } from 'node:http';
import { Country } from '@royalaholddelhaize/ah-web-core';
import pantry from '@royalaholddelhaize/design-system-pantry-web/design-tokens.module.css';
import classNames from 'clsx';
import { type PropsWithChildren, useEffect, useState } from 'react';
import { createTranslate } from '../i18n/translation';
import { Breadcrumb } from './components/breadcrumb/breadcrumb';
import { Menu } from './components/menu/menu';
import { SubMenu } from './components/sub-menu/sub-menu';
import { MenuBEL } from './config/layout/bel';
import { MenuNLD } from './config/layout/nld';
import { findActiveMenuItem } from './helpers/find-active-menu-item';
import { findMenuItemByAnchor } from './helpers/find-menu-item-by-anchor';
import { getDomain } from './helpers/get-domain';
import { getMemberType } from './helpers/get-member-type';
import { getXVariantHeaders } from './helpers/get-x-variant-headers';
import { profileTranslations } from './i18n/translation';
import type { MenuItem as MenuItemType } from './interfaces/profile-menu';
import type { ProfileSupportedLocales } from './interfaces/supported-locales';
import css from './profile-side-menu.module.scss';

interface ProfileSideMenuBaseProps {
    headers: IncomingHttpHeaders;
    currentURL: string;
    className?: string;
    locale: ProfileSupportedLocales;
}

interface ProfileSideMenuProps
    extends ProfileSideMenuBaseProps,
        PropsWithChildren {
    displayName: string;
    isSmallViewport?: boolean;
}

export const ProfileSideMenu = ({
    headers,
    displayName,
    currentURL,
    isSmallViewport = false,
    children,
    className,
    locale,
}: ProfileSideMenuProps) => {
    const t = createTranslate(locale, profileTranslations);
    const [subMenu, setSubMenu] = useState<MenuItemType | null>(null);
    const [anchor, setAnchor] = useState<string>('');
    const [currentMenuItem, setCurrentMenuItem] = useState<MenuItemType | null>(
        null,
    );
    const featureFlags = getXVariantHeaders(headers);
    const memberType = getMemberType(headers);
    const menuConfig = getDomain(headers) === Country.BEL ? MenuBEL : MenuNLD;
    const [isLoading, setLoading] = useState<boolean>(true);
    const urlObject = new URL(currentURL);

    //Fallback so page never blocks
    setTimeout(() => {
        setLoading(false);
    }, 500);

    useEffect(() => {
        addEventListener('hashchange', ({ newURL }) => {
            setAnchor(newURL.split('#')[1]);
        });
        if (typeof window !== 'undefined') {
            setAnchor(window.location.hash.replace('#', ''));
        }
    });

    useEffect(() => {
        setSubMenu(findMenuItemByAnchor(t, menuConfig.menu, anchor));
        if (anchor) {
            setCurrentMenuItem(findActiveMenuItem(t, menuConfig.menu, anchor));
        }
        setLoading(false);
    }, [t, anchor, menuConfig]);

    useEffect(() => {
        setCurrentMenuItem(
            findActiveMenuItem(t, menuConfig.menu, urlObject.pathname) ||
                menuConfig.menu[0] ||
                null,
        );
    }, [t, urlObject, menuConfig]);

    if (isLoading) {
        return;
    }

    // For Mobile only render the selected submenu (not the whole menu)
    const renderMenu =
        isSmallViewport && subMenu?.items ? subMenu.items : menuConfig.menu;

    return (
        <div
            className={classNames(pantry.designTokens, css.menuWrapper)}
            data-brand="ah"
        >
            {isSmallViewport ? null : (
                <Breadcrumb
                    locale={locale}
                    path={urlObject.pathname}
                    config={menuConfig}
                    anchor={anchor}
                    displayName={displayName}
                />
            )}
            <div className={classNames(css.root, className)}>
                {(!children || !isSmallViewport) && (
                    <div className={css.wrapper}>
                        <Menu
                            locale={locale}
                            menuConfig={renderMenu}
                            memberType={memberType}
                            featureFlags={featureFlags}
                            setSubMenu={setSubMenu}
                            currentMenuItem={currentMenuItem}
                            setCurrentMenuItem={setCurrentMenuItem}
                        />
                    </div>
                )}
                {subMenu && !isSmallViewport ? (
                    <div className={css.content}>
                        <SubMenu
                            locale={locale}
                            subMenu={subMenu}
                            featureFlags={featureFlags}
                        />
                    </div>
                ) : (
                    children
                )}
            </div>
        </div>
    );
};
