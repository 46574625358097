import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import type React from 'react';
import css from './accordion-item.module.scss';

export interface AccordionItemProps {
    /**
     * Given link to be given as a prop to the anchor element within the component.
     */
    href: string;
    /**
     * Given string to be displayed as label of the component.
     */
    label: string;
    /**
     * Optional target value to be passed as a prop to the anchor element.
     */
    target?: string;
    /**
     * Optional testhook-id to be passed down to the anchor
     */
    testHook?: string;
    /**
     * Optional onclick event handler
     */
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const AccordionItem = ({
    href,
    label,
    target,
    testHook,
    onClick,
}: AccordionItemProps) => {
    return (
        <LinkAnchor
            className={css.accordionItem}
            onClick={event => {
                if (onClick) {
                    onClick(
                        event as unknown as React.MouseEvent<HTMLAnchorElement>,
                    );
                }
            }}
            href={href}
            target={target}
            {...createTestHook(testHook)}
        >
            <LinkText level="secondary">{label}</LinkText>
        </LinkAnchor>
    );
};
