import { Locale } from '@royalaholddelhaize/ah-web-core';
import type { NestedKeyOf } from '../../i18n/translation';
import type { FooterSupportedLocales } from '../interfaces/supported-locales';
import enUS from './en-US/translation.json';
import nlBE from './nl-BE/translation.json';
import nlNL from './nl-NL/translation.json';

export type FooterTranslationFile = typeof nlNL;
export type FooterTranslationKey = NestedKeyOf<FooterTranslationFile>;

export const footerTranslations: Record<
    FooterSupportedLocales,
    FooterTranslationFile
> = {
    [Locale.nl_NL]: nlNL,
    [Locale.nl_BE]: nlBE,
    [Locale.en_US]: enUS,
};
