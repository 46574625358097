import { ChevronLeft16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-left-16';
import { Close16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/close-16';
import { LinkButton } from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import classNames from 'clsx';
import type React from 'react';
import type { ReactNode } from 'react';
import css from './panel-header.module.scss';

export interface PanelHeaderProps {
    children?: ReactNode;
    className?: string;
    onClose?(event: React.MouseEvent<HTMLButtonElement>): void;
    onBack?(event: React.MouseEvent<HTMLButtonElement>): void;
    equalizeNavBar?: boolean;
}

export const PanelHeader: React.FC<PanelHeaderProps> = ({
    className,
    children,
    onClose,
    onBack,
    equalizeNavBar,
}) => (
    <div
        className={classNames(
            css.panelHeader,
            equalizeNavBar && css.equalizeNavbar,
            className,
        )}
    >
        {onBack && (
            <LinkButton
                className={css.backButton}
                onClick={onBack}
                aria-label="terug"
            >
                <ChevronLeft16Icon size={16} />
            </LinkButton>
        )}
        <div className={css.content}>{children}</div>
        {onClose && (
            <LinkButton
                className={css.closeButton}
                onClick={onClose}
                aria-label="Sluiten"
                tabIndex={0}
            >
                <Close16Icon size={16} />
            </LinkButton>
        )}
    </div>
);
