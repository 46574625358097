import type { Country } from '@royalaholddelhaize/ah-web-core';
import type { MegaMenuLinks } from '../../graphql/content/mega-menu/mega-menu-links.hook';
import { useMember } from '../contexts/member';
import { MenuItemId } from '../enums/menu-item-id';
import { getMenuItemById, selectMenu } from '../helpers/menu.helper';
import type { MenuItem, MenuItems } from '../interfaces/menu';

export interface MenuItemWithLinks extends MenuItem {
    links?: MegaMenuLinks[];
}

interface Links {
    login: MenuItem;
    logout: MenuItem;
    favorites: MenuItem;
    currentOrder: MenuItem;
    profile: MenuItem;
    recipes: MenuItemWithLinks;
}

export interface NavigationHeaderHook {
    menuItems: MenuItems;
    links: Links;
}

export const useNavigationHeader = (domain: Country): NavigationHeaderHook => {
    const {
        member: { isB2B },
    } = useMember();
    const menuItems = selectMenu(isB2B, domain);

    const links = {
        login: getMenuItemById(menuItems, MenuItemId.LOGIN),
        logout: getMenuItemById(menuItems, MenuItemId.LOGOUT),
        favorites: getMenuItemById(menuItems, MenuItemId.FAVORITES),
        currentOrder: getMenuItemById(menuItems, MenuItemId.ORDER_ONLINE),
        profile: getMenuItemById(menuItems, MenuItemId.PROFILE),
        recipes: getMenuItemById(menuItems, MenuItemId.RECIPES),
    };

    return {
        menuItems,
        links,
    };
};
