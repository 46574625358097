const throttle = (callback: () => void, cooldown: number) => {
    let called = false;

    return () => {
        if (called) {
            return;
        }

        called = true;

        window.setTimeout(() => {
            callback();
            called = false;
        }, cooldown);
    };
};

export default throttle;
