import type { ComponentProps, FC } from 'react';

export interface ProfileMenuType {
    menu: MenuItem[];
    breadcrumbs: Breadcrumb[];
}

export enum MemberType {
    B2B = 'B2B',
    B2C = 'B2C',
}

export interface Breadcrumb {
    url: string;
    title: string;
    parent: string;
}

export interface MenuItem {
    title: string;
    subTitle?: string;
    icon?: FC<ComponentProps<'svg'>>;
    domain?: string[];
    url?: string;
    featureFlag?: string;
    items?: MenuItem[];
}
