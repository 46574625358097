import { useCallback, useEffect, useRef, useState } from 'react';
import throttle from '../helpers/throttle';

export enum ScrollDirection {
    INIT = 'INIT',
    UP = 'UP',
    DOWN = 'DOWN',
}

type Scrolling = {
    yOffset: number;
    direction: ScrollDirection;
    velocity: number;
};

export function useScroll(): Scrolling {
    const [yOffset, setYOffset] = useState(-1);
    const [scrolling, setScrolling] = useState({
        direction: ScrollDirection.INIT,
        velocity: 0,
    });

    // Intermediate value/ref during animation
    const prevYOffset = useRef(yOffset);

    const getYOffset = () =>
        Math.round(window.pageYOffset || document.documentElement.scrollTop);

    // Request Animation Frame makes sure that the animation is paced
    // equally over time.
    const animate = useCallback(() => {
        requestAnimationFrame(onScroll);
    }, []);

    const onScroll = useCallback(
        throttle(() => {
            const nextYOffset = getYOffset();
            const prev = prevYOffset.current;

            if (prev !== nextYOffset) {
                // When animating, don't trigger more animations
                window.removeEventListener('scroll', animate);

                const direction =
                    prev < nextYOffset
                        ? ScrollDirection.DOWN
                        : ScrollDirection.UP;

                setScrolling({
                    direction,
                    velocity: Math.abs(nextYOffset - prev),
                });

                prevYOffset.current = nextYOffset;
                setYOffset(nextYOffset);

                // Go to next animation frame
                animate();
            } else {
                setYOffset(nextYOffset);
                // After the animation is done, bind a new animation
                window.addEventListener('scroll', animate);
            }
        }, 100),
        [],
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        animate();
        return () => window.removeEventListener('scroll', animate);
    }, []);

    return { ...scrolling, yOffset };
}
