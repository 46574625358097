'use client';

import { type Banner, DEFAULT_COUNTRY } from '@royalaholddelhaize/ah-web-core';
import {
    ChevronDown16Icon,
    FavoriteOutlined24Icon,
} from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import classNames from 'clsx';
import { Suspense } from 'react';
import { ContentMegaMenuLinksDocument } from '../graphql/_generated-hooks';
import type {
    ContentMegaMenuLinksQuery,
    ContentMegaMenuLinksQueryVariables,
} from '../graphql/_generated-operation-types';
import { useBasket } from '../graphql/basket/basket.hook';
import { createTranslate } from '../i18n/translation';
import { ClientSideApollo, ServerSideApollo } from '../utils/apollo-switcher';
import { IconButton } from './components/icon-button/icon-button';
import {
    UserIcon,
    UserIconType,
} from './components/icon-button/user-icon/user-icon';
import { MegaMenuAllerhandeContent } from './components/mega-menu/elements/mega-menu-content/contents/allerhande/mega-menu-allerhande-content';
import {
    channelMap,
    defaultLinkGroup,
} from './components/mega-menu/elements/mega-menu-content/contents/allerhande/mega-menu-allerhande-links.constant';
import { MegaMenu } from './components/mega-menu/mega-menu';
import {
    type CustomButtonProps,
    MenuDropdown,
} from './components/menu-dropdown/menu-dropdown';
import { MenuItemComponent } from './components/menu-item-component/menu-item-component';
import { NavLogo } from './components/nav-logo/nav-logo';
import { NavigationBasket } from './components/navigation-basket/navigation-basket';
import { Search } from './components/search/search';
import { TooltipNotification } from './components/tooltip-notification/tooltip-notification';
import { useMember } from './contexts/member';
import { MenuItemId } from './enums/menu-item-id';
import { handleLogout } from './helpers/logout';
import {
    emitClickFavoriteIconCidEvent,
    goToLogin,
} from './helpers/menu.helper';
import { useNavigationHeader } from './hooks/navigation-header.hook';
import { headerTranslations } from './i18n/translation';
import type { HeaderApolloOptions } from './interfaces/apollo-options';
import type { HeaderFeatureOptions } from './interfaces/feature-options';
import type { HeaderSupportedDomains } from './interfaces/supported-domains';
import type { HeaderSupportedLocales } from './interfaces/supported-locales';

import { HeaderFeatureOptionsName } from './enums/feature-options';
import css from './header-default.module.scss';

type HeaderDefaultProps = {
    apollo: HeaderApolloOptions;
    featureOptions: HeaderFeatureOptions;
    withDeliveryNotification: boolean;
    banner: Banner;
    domain: HeaderSupportedDomains;
    locale: HeaderSupportedLocales;
};

export const HeaderDefault = ({
    apollo,
    featureOptions,
    withDeliveryNotification,
    banner,
    domain,
    locale,
}: HeaderDefaultProps) => {
    const { menuItems, links } = useNavigationHeader(domain);
    const {
        isLoading,
        member: { isLoggedIn, isPremium },
    } = useMember();
    const { orderMode } = useBasket(apollo.initialised);

    const t = createTranslate(locale, headerTranslations);

    const ApolloWrapper = apollo.ssrClient
        ? ServerSideApollo
        : ClientSideApollo;

    return (
        <nav className={css.container}>
            <NavLogo banner={banner} locale={locale} />
            <menu className={css.mainMenu}>
                {menuItems.mainMenu.map(item =>
                    item.id === MenuItemId.RECIPES &&
                    !featureOptions[
                        HeaderFeatureOptionsName.AllerhandeMegaMenu
                    ] ? (
                        <MegaMenu
                            withDeliveryNotification={withDeliveryNotification}
                            key={item.id}
                            item={item}
                            locale={locale}
                        >
                            <Suspense fallback={null}>
                                <ApolloWrapper<
                                    ContentMegaMenuLinksQuery,
                                    ContentMegaMenuLinksQueryVariables
                                >
                                    apollo={{
                                        initialised: apollo.initialised,
                                        ssrClient: apollo.ssrClient,
                                        query: ContentMegaMenuLinksDocument,
                                        variables: {
                                            options: {
                                                locale: locale?.replace(
                                                    '-',
                                                    '_',
                                                ),
                                                channel: 'allerhande',
                                            },
                                            documentId:
                                                channelMap[domain] ||
                                                (channelMap[
                                                    DEFAULT_COUNTRY
                                                ] as string),
                                        },
                                        headers: apollo.headers,
                                    }}
                                >
                                    {({ data }) => (
                                        <MegaMenuAllerhandeContent
                                            content={
                                                data?.contentMegaMenuLinks || {
                                                    linkGroups:
                                                        defaultLinkGroup,
                                                }
                                            }
                                        />
                                    )}
                                </ApolloWrapper>
                            </Suspense>
                        </MegaMenu>
                    ) : (
                        <MenuItemComponent
                            featureOptions={featureOptions}
                            key={item.id}
                            item={{ ...item, Icon: undefined }}
                            className="mainMenuItem"
                            locale={locale}
                        />
                    ),
                )}
                <MenuDropdown
                    label={t('navigation-header.more')}
                    a11yLabel={t('navigation-header.other-items')}
                >
                    {menuItems.secondaryMenu.map(item => (
                        <MenuItemComponent
                            featureOptions={featureOptions}
                            key={item.id}
                            item={{ ...item, Icon: undefined }}
                            className="secondaryMenuItem"
                            locale={locale}
                        />
                    ))}
                </MenuDropdown>
            </menu>
            <div className={css.searchContainer}>
                <Search
                    apollo={apollo}
                    featureOptions={featureOptions}
                    locale={locale}
                />
            </div>
            <div className={css.personalNav}>
                <menu className={css.personalNavMenu}>
                    {!orderMode && (
                        <MenuItemComponent
                            featureOptions={featureOptions}
                            item={links.currentOrder}
                            className="orderLink"
                            locale={locale}
                        />
                    )}
                    <MenuDropdown
                        label={t(links.login.label)}
                        a11yLabel={t('navigation-header.my-ah')}
                        className="personalNavMenu"
                        CustomButton={(props: CustomButtonProps) => (
                            <IconButton
                                Icon={UserIcon(
                                    isLoading
                                        ? UserIconType.LOADING
                                        : isLoggedIn
                                          ? isPremium
                                              ? UserIconType.PREMIUM
                                              : UserIconType.LOGGED_IN
                                          : UserIconType.LOGGED_OUT,
                                )}
                                label={
                                    !isLoggedIn && !isLoading
                                        ? t(links.login.label)
                                        : undefined
                                }
                                {...props}
                            >
                                <ChevronDown16Icon
                                    size={16}
                                    className={classNames(
                                        css.caret,
                                        props.active && css.open,
                                    )}
                                />
                            </IconButton>
                        )}
                        isUser
                    >
                        {menuItems.userMenu.map(item => (
                            <MenuItemComponent
                                featureOptions={featureOptions}
                                key={item.id}
                                item={item}
                                className="secondaryMenuItem"
                                iconSize="medium"
                                locale={locale}
                            />
                        ))}
                        {isLoggedIn ? (
                            <MenuItemComponent
                                featureOptions={featureOptions}
                                item={{ ...links.logout, href: undefined }}
                                className="secondaryMenuItem"
                                onClick={handleLogout}
                                locale={locale}
                            />
                        ) : (
                            <MenuItemComponent
                                featureOptions={featureOptions}
                                item={{ ...links.login, href: undefined }}
                                className="secondaryMenuItem"
                                onClick={goToLogin}
                                locale={locale}
                            />
                        )}
                    </MenuDropdown>
                </menu>
                <IconButton
                    Icon={FavoriteOutlined24Icon}
                    href={links.favorites.href}
                    aria-label={t('navigation-header.favorites')}
                    onClick={emitClickFavoriteIconCidEvent}
                />
                <NavigationBasket apollo={apollo} locale={locale} />
                <TooltipNotification apollo={apollo} locale={locale} />
            </div>
        </nav>
    );
};
