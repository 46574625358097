'use client';

import {
    type GlobalNotificationFragment,
    useHeaderNotifications,
} from '@royalaholddelhaize/ah-safety-notifications';
import {
    AH_UI_NAVIGATION_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import {
    ChevronRight16Icon,
    Close16Icon,
} from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import {
    NotificationActionIcon,
    NotificationDismissButton,
    NotificationLink,
    NotificationPriorityIcon,
    NotificationStripContent,
    NotificationStripWrapper,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/notification/elements';
import {
    usePriorityIcon,
    usePriorityVariant,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/notification/hooks';
import { NotificationPriority } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/notification/types';
import { ClientOnly } from '@royalaholddelhaize/design-system-pantry-web/components/utilities/client-only/client-only';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import type React from 'react';
import type { FC } from 'react';
import { NotificationPriorityType } from '../../../graphql/_generated-operation-types';
import { createTranslate } from '../../../i18n/translation';
import { headerTranslations } from '../../i18n/translation';
import type { HeaderSupportedLocales } from '../../interfaces/supported-locales';

import css from './notifications.module.scss';

export const NOTIFICATION_STORAGE = 'notification';

type NotificationsProps = {
    priorityNotifications: GlobalNotificationFragment[];
    locale: HeaderSupportedLocales;
};

const NotificationPriorityTypeMap: Record<
    NotificationPriorityType,
    NotificationPriority
> = {
    [NotificationPriorityType.HIGH]: NotificationPriority.HIGH,
    [NotificationPriorityType.MEDIUM]: NotificationPriority.MEDIUM,
};

export const Notifications: FC<NotificationsProps> = ({
    priorityNotifications,
    locale,
}) => {
    const { notification, showNotification, dismissNotification } =
        useHeaderNotifications(
            (priorityNotifications || []) as GlobalNotificationFragment[],
        );

    const priority = notification?.priority
        ? NotificationPriorityTypeMap[notification.priority]
        : undefined;

    const icon = usePriorityIcon(priority);
    const variant = usePriorityVariant(priority);

    const t = createTranslate(locale, headerTranslations);

    if (!notification || !notification.link.title) {
        return null;
    }

    const handleDismiss = (event: React.SyntheticEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        dismissNotification(notification.id);
    };

    return (
        <ClientOnly>
            <div
                className={classNames(css.root, {
                    [css.show]: showNotification,
                })}
                {...createTestHook(
                    AH_UI_NAVIGATION_TESTHOOKS.HEADER.notificationPriority,
                )}
            >
                <div className={css.wrapper}>
                    <NotificationLink
                        href={notification.link.href}
                        className={css.notification}
                        onClick={() => {
                            dismissNotification(notification.id);
                        }}
                    >
                        <NotificationStripWrapper>
                            <NotificationPriorityIcon variant={variant}>
                                {icon}
                            </NotificationPriorityIcon>
                            <NotificationStripContent>
                                {notification.priorityLabel && (
                                    <Typography variant="body-strong">
                                        {`${notification.priorityLabel}:`}
                                    </Typography>
                                )}
                                <Typography variant="body-regular">
                                    {notification.link.title}
                                </Typography>
                            </NotificationStripContent>
                            <ChevronRight16Icon
                                className={css.chevron}
                                size={16}
                            />
                            <NotificationActionIcon>
                                <NotificationDismissButton
                                    aria-label={t('search.close')}
                                    onClick={handleDismiss}
                                    {...createTestHook(
                                        AH_UI_NAVIGATION_TESTHOOKS.HEADER
                                            .notificationPriorityClose,
                                    )}
                                >
                                    <Close16Icon />
                                </NotificationDismissButton>
                            </NotificationActionIcon>
                        </NotificationStripWrapper>
                    </NotificationLink>
                </div>
            </div>
        </ClientOnly>
    );
};
