import classNames from 'clsx';
import type React from 'react';
import type { PropsWithChildren } from 'react';
import styles from './mega-menu-overlay.module.scss';

export interface MegaMenuOverlayProps extends PropsWithChildren {
    isOpen: boolean;
    handleMouseEnter?: (e: React.KeyboardEvent) => void;
    onClose?: () => void;
    withDeliveryNotification: boolean;
}

export const MegaMenuOverlay = ({
    isOpen,
    onClose,
    withDeliveryNotification,
    children,
}: MegaMenuOverlayProps) => {
    let timer: NodeJS.Timeout;

    const handleMouseEnter = () => {
        timer = setTimeout(() => {
            onClose && onClose();
        }, 500);
    };

    const handleMouseLeave = () => {
        timer && clearTimeout(timer);
    };

    return (
        <div
            className={classNames(
                styles.megaMenuOverlay,
                withDeliveryNotification &&
                    styles.megaMenuOverlayWithDeliveryNotification,
                isOpen && styles.megaMenuOverlayOpen,
            )}
        >
            <div
                className={classNames(
                    styles.megaMenuContentWrapper,
                    isOpen && styles.megaMenuContentWrapperOpen,
                )}
            >
                {children}
            </div>

            <div
                role="presentation"
                className={classNames(
                    styles.megaMenuBackDrop,
                    isOpen && styles.megaMenuBackDropOpen,
                )}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={onClose}
                onKeyDown={onClose}
            />
        </div>
    );
};
