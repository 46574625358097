'use client';

import { type Banner, DEFAULT_COUNTRY } from '@royalaholddelhaize/ah-web-core';
import { FavoriteOutlined24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/favorite-outlined-24';
import { LinkButton } from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import { FocusTrap } from 'focus-trap-react';
import { Suspense, useEffect, useState } from 'react';
import { ContentMegaMenuLinksDocument } from '../graphql/_generated-hooks';
import type {
    ContentMegaMenuLinksQuery,
    ContentMegaMenuLinksQueryVariables,
} from '../graphql/_generated-operation-types';
import { createTranslate } from '../i18n/translation';
import { ClientSideApollo, ServerSideApollo } from '../utils/apollo-switcher';
import { IconButton } from './components/icon-button/icon-button';
import {
    UserIcon,
    UserIconType,
} from './components/icon-button/user-icon/user-icon';
import {
    channelMap,
    defaultLinkGroup,
} from './components/mega-menu/elements/mega-menu-content/contents/allerhande/mega-menu-allerhande-links.constant';
import { NavBarSlot } from './components/nav-bar-slot/nav-bar-slot';
import { NavLogo } from './components/nav-logo/nav-logo';
import { NavPanel } from './components/nav-panel/nav-panel';
import { NavPanelProfile } from './components/nav-panel/panes/profile/nav-panel-profile';
import { NavPanelRoot } from './components/nav-panel/panes/root/nav-panel-root';
import { NavigationBasket } from './components/navigation-basket/navigation-basket';
import { SearchBar } from './components/search/elements/search-bar/search-bar';
import { emitSearchBarClickEvent } from './components/search/helpers/emit-search-bar-click-event';
import { Search } from './components/search/search';
import { TooltipNotification } from './components/tooltip-notification/tooltip-notification';
import { useMember } from './contexts/member';
import { emitClickFavoriteIconCidEvent } from './helpers/menu.helper';
import { useNavigationHeader } from './hooks/navigation-header.hook';
import { headerTranslations } from './i18n/translation';
import type { HeaderApolloOptions } from './interfaces/apollo-options';
import type { HeaderFeatureOptions } from './interfaces/feature-options';
import type { HeaderSupportedDomains } from './interfaces/supported-domains';
import type { HeaderSupportedLocales } from './interfaces/supported-locales';

import css from './header-compact.module.scss';

type HeaderCompactProps = {
    apollo: HeaderApolloOptions;
    featureOptions: HeaderFeatureOptions;
    banner: Banner;
    domain: HeaderSupportedDomains;
    locale: HeaderSupportedLocales;
};

export const HeaderCompact = ({
    apollo,
    featureOptions,
    banner,
    domain,
    locale,
}: HeaderCompactProps) => {
    const [isPersonalMenuOpen, setIsPersonalMenuOpen] = useState(false);
    const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
    const { links } = useNavigationHeader(domain);
    const {
        isLoading,
        member: { isPremium, isLoggedIn },
    } = useMember();

    const t = createTranslate(locale, headerTranslations);

    const handleAccountClick = () => {
        setIsPersonalMenuOpen(prevState => !prevState);
    };

    const handleMenuClick = () => {
        setIsMainMenuOpen(prevState => !prevState);
    };

    const isNavigationOpen = isMainMenuOpen || isPersonalMenuOpen;

    // emit search bar click event when the search bar is opened
    useEffect(() => {
        if (isSearchOpen) {
            emitSearchBarClickEvent(t('search.placeholder'));
        }
    }, [isSearchOpen, t]);

    const ApolloWrapper = apollo.ssrClient
        ? ServerSideApollo
        : ClientSideApollo;

    return (
        <>
            <nav className={css.navBar}>
                <NavBarSlot>
                    <LinkButton
                        className={classNames(
                            css.toggle,
                            isNavigationOpen && css.isOpen,
                        )}
                        aria-label={t(
                            isNavigationOpen
                                ? 'navigation-header-compact.close'
                                : 'navigation-header-compact.open',
                        )}
                        onClick={handleMenuClick}
                    >
                        <div className={css.toggleBars}>
                            <span className={css.toggleBar1} />
                            <span className={css.toggleBar2} />
                            <span className={css.toggleBar3} />
                            <span className={css.toggleBar4} />
                            <Typography
                                variant="subtext-regular"
                                className={css.toggleText}
                            >
                                {t('navigation-header-compact.menu')}
                            </Typography>
                        </div>
                    </LinkButton>
                    {isMainMenuOpen && (
                        <FocusTrap>
                            <div
                                className={classNames(
                                    css.foldOutMenu,
                                    css.foldOutMenuIsOpen,
                                )}
                            >
                                <Suspense fallback={null}>
                                    <ApolloWrapper<
                                        ContentMegaMenuLinksQuery,
                                        ContentMegaMenuLinksQueryVariables
                                    >
                                        apollo={{
                                            initialised: apollo.initialised,
                                            ssrClient: apollo.ssrClient,
                                            query: ContentMegaMenuLinksDocument,
                                            variables: {
                                                options: {
                                                    locale: locale?.replace(
                                                        '-',
                                                        '_',
                                                    ),
                                                    channel: 'allerhande',
                                                },
                                                documentId:
                                                    channelMap[domain] ||
                                                    (channelMap[
                                                        DEFAULT_COUNTRY
                                                    ] as string),
                                            },
                                            headers: apollo.headers,
                                        }}
                                    >
                                        {({ data }) => (
                                            <NavPanel
                                                featureOptions={featureOptions}
                                                initialPane={{
                                                    component: NavPanelRoot,
                                                    props: {
                                                        featureOptions,
                                                        apollo,
                                                        megaMenuContent:
                                                            data?.contentMegaMenuLinks || {
                                                                linkGroups:
                                                                    defaultLinkGroup,
                                                            },
                                                        locale,
                                                        domain,
                                                        banner,
                                                    },
                                                }}
                                                onCloseStack={handleMenuClick}
                                                locale={locale}
                                            />
                                        )}
                                    </ApolloWrapper>
                                </Suspense>
                            </div>
                        </FocusTrap>
                    )}
                    {isPersonalMenuOpen && (
                        <FocusTrap>
                            <div
                                className={classNames(
                                    css.foldOutMenu,
                                    css.foldOutMenuIsOpen,
                                )}
                            >
                                <NavPanel
                                    featureOptions={featureOptions}
                                    initialPane={{
                                        component: NavPanelProfile,
                                        props: {
                                            featureOptions,
                                            locale,
                                            domain,
                                        },
                                    }}
                                    onCloseStack={handleAccountClick}
                                    locale={locale}
                                />
                            </div>
                        </FocusTrap>
                    )}
                </NavBarSlot>
                <NavBarSlot>
                    <NavLogo
                        banner={banner}
                        className={css.logo}
                        locale={locale}
                    />
                </NavBarSlot>
                <NavBarSlot align="Right">
                    <IconButton
                        aria-label={t(
                            isLoggedIn
                                ? links.profile.label
                                : links.login.label,
                        )}
                        onClick={handleAccountClick}
                        Icon={UserIcon(
                            isLoading
                                ? UserIconType.LOADING
                                : isLoggedIn
                                  ? isPremium
                                      ? UserIconType.PREMIUM
                                      : UserIconType.LOGGED_IN
                                  : UserIconType.LOGGED_OUT,
                        )}
                    />
                    <IconButton
                        href={links.favorites.href}
                        aria-label={t(links.favorites.label)}
                        Icon={FavoriteOutlined24Icon}
                        onClick={emitClickFavoriteIconCidEvent}
                    />
                    <NavigationBasket apollo={apollo} locale={locale} />
                    {isNavigationOpen ? null : (
                        <TooltipNotification locale={locale} apollo={apollo} />
                    )}
                </NavBarSlot>
            </nav>
            {isNavigationOpen ? null : isSearchOpen ? (
                <Search
                    apollo={apollo}
                    locale={locale}
                    onClose={() => setIsSearchOpen(false)}
                    featureOptions={featureOptions}
                />
            ) : (
                <SearchBar
                    locale={locale}
                    onOpen={() => setIsSearchOpen(true)}
                />
            )}
        </>
    );
};
